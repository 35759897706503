import { industry, sector, subIndustry } from '@/utils/store'
import axios from '@/libs/axios'
import { API, PORTFOLIO_DATA_URL, RATINGS_URL, STOCKS_URL } from '@/constants/app'
import { PORTFOLIO_TYPES } from '@/constants/portfolios'
import useApollo from '@core/hooks/apollo'
import gql from 'graphql-tag'

const actions = {
  ...industry.ACTIONS,
  ...subIndustry.ACTIONS,
  ...sector.ACTIONS
}

actions.loadTickers = async (_, params) => {
  const { data } = await axios.get(`${ API }/${ params.join('/') }`)

  data.sort((a, b) => Number(a.marketcap) < Number(b.marketcap) ? 1 : -1)
  return data
}
actions.loadCompanyData = async ({ state, commit }, ticker) => {
  const code = ticker.toLowerCase()
  if (code && !state.companiesData[code]) {
    try {
      commit('setCompanies', { data: { loading: true }, code })
      const { data } = await axios.get(`${ RATINGS_URL }${ code }`)
      commit('setCompanies', { data, code })
    }
    catch (e) {
      commit('setCompanies', { data: {}, code })
    }
  }
}
actions.loadPortfolioData = async ({ state, commit }, id) => {
  if (id && !state.portfolios[id]) {
    commit('setLoading', true)
    try {
      const { data } = await axios.get(`${ PORTFOLIO_DATA_URL.replace(':id', id) }?portfolioType=${ PORTFOLIO_TYPES.PORTFOLIO }`)
      commit('setPortfolio', { data, id })
    }
    catch (e) {
      commit('setPortfolio', { data: {}, id })
    }
    finally {
      commit('setLoading', false)
    }
  }
}
actions.loadSeries = (_, { ticker, series }) => {
  return new Promise(resolve => {
    axios.get(`${ STOCKS_URL }/${ ticker }/data/${ series }`)
      .then(({ data }) => {
        resolve({ ticker, series, data })
      })
      .catch(function (error) {
        resolve(error)
      })
  })
}
actions.loadSecurities = async ({ commit, getters: { getSecurityByTicker } }, tickers) => {
  tickers = tickers.filter(ticker => !getSecurityByTicker(ticker))
  if (tickers.length) {
    const { ainstein } = useApollo()
    const { data } = await ainstein.load(gql`query fetchSecurities {
      ${tickers.map(ticker => `${ticker}: securities(ticker: "${ticker}") { ISIN id ticker }`).join(`\n`)}
    }`)
    commit('updateSecurities', data)
  }
}
actions.loadSeriesGQL = async ({ dispatch, getters }, { tickers, series }) => {

  await dispatch('loadSecurities', tickers)

  const { getSecurityByTicker } = getters

  const datasets = tickers.map(ticker => {
    const { id } = getSecurityByTicker(ticker)
    return {
      datasetId: `ainstein_prices.code_${ id.toLowerCase() }`
    }
  })

  const options = {
    datasets,
    series,
    sorting: { series: 'date', order: 'DESC' }
  }

  const { eumonics } = useApollo()

  const { data: { datatables: { multidata } } } = await eumonics.reload('Eumonics/DatatablesMultidata', options)

  return datasets.reduce((result, { datasetId }, i) => {
    result[tickers[i]] = multidata[datasetId].values
    return result
  }, {})
}

export default actions
