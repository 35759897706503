import { stages } from '@/constants'

export const STATE = () => ({
  mode: null,
  stage: null,
  editable: null,
  removable: null
})

export const GETTERS = {
  isAdd: ({ mode }) => mode === stages.ADD,
  isEdit: ({ mode }) => mode === stages.EDIT,
  isRemove: ({ mode }) => mode === stages.REMOVE,

  loading: ({ stage }) => stage === stages.LOADING,
  creating: ({ stage }) => stage === stages.CREATING,
  updating: ({ stage }) => stage === stages.UPDATING,
  destroying: ({ stage }) => stage === stages.DESTROYING,

  saving: (state, { creating, updating }) => creating || updating
}

export const MUTATIONS = {
  setMode: (state, mode) => {
    state.mode = mode
  },
  resetMode: state => {
    state.deletable = null
    state.editable = null
    state.mode = null
  },

  setStage: (state, stage) => {
    state.stage = stage
  },
  resetStage: state => {
    state.stage = null
  },

  /**
   * The name 'new' here is more consistent, but it's not possible to use in components.
   * @param state
   */
  add: state => {
    MUTATIONS.setMode(state, stages.ADD)
  },

  edit: (state, editable) => {
    state.editable = editable
    MUTATIONS.setMode(state, stages.EDIT)
  },

  remove: (state, removable) => {
    state.removable = removable
    MUTATIONS.setMode(state, stages.REMOVE)
  }
}

