import { CONTEXTS, DEFAULT_SEARCH_CONTEXT, SEARCH_DATA } from '@/constants/search'

/**
 * It can be a function that returns an object,
 * if you think that this module can be instantiated.
 */
export default {
  ...Object.keys(CONTEXTS).reduce((state, key) => {
    state[CONTEXTS[key].storeKey] = CONTEXTS[key].defaultValue
    return state
  }, {}),
  context: DEFAULT_SEARCH_CONTEXT,
  searchDataSource: SEARCH_DATA,
  remoteLoading: false
}
