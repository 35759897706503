export default {
  'ALBKY': { pe: 7.0 },
  'AVAL': { pe: 4.4 },
  'BDORY': { pe: 4.86 },
  'BSMX': { pe: 6.54 },
  'SVNLY': { pe: 9.08 },
  'ASB': { pe: 9.9 },
  'AVBH': { pe: 6.86 },
  'BMRC': { pe: 11.45 },
  'CARE': { pe: 9.43 },
  'CBFV': { pe: 11.86 },
  'CCBG': { pe: 14.14 },
  'CSTR': { pe: 11.33 },
  'FOA': { pe: 7.32 },
  'FULT': { pe: 10.77 },
  'HOMB': { pe: 13.49 },
  'HOPE': { pe: 8.41 },
  'MFNC': { pe: null },
  'MOFG': { pe: 8.86 },
  'MPB': { pe: 9.59 },
  'MSBI': { pe: 7.27 },
  'AKBTY': { pe: 2.01 },
  'BACHY': { pe: null },
  'BAP': { pe: 9.07 },
  'BKHYY': { pe: 9.35 },
  'CICHY': { pe: 3.16 },
  'JPM': { pe: 10.91 },
  'PNC': { pe: 11.95 },
  'SCGLY': { pe: 4.21 },
  'ACBI': { pe: null },
  'AMTB': { pe: 12.95 },
  'BCML': { pe: 9.16 },
  'BSBK': { pe: 21.76 },
  'BWB': { pe: 10.51 },
  'BY': { pe: 10.2 },
  'CADE': { pe: 10.36 },
  'CATC': { pe: 10.5 },
  'CBTX': { pe: 16.34 },
  'CPKF': { pe: 12.29 },
  'CVBF': { pe: 16.86 },
  'EVBN': { pe: 9.5 },
  'FBNC': { pe: 10.13 },
  'FLIC': { pe: 9.61 },
  'FMBI': { pe: null },
  'FNWB': { pe: 10.73 },
  'FRST': { pe: 14.72 },
  'FSRL': { pe: 14.06 },
  'GNTY': { pe: 10.69 },
  'HWC': { pe: 8.95 },
  'ISTR': { pe: 8.75 },
  'LBC': { pe: 8.65 },
  'LEVL': { pe: null },
  'MCBI': { pe: null },
  'NBHC': { pe: 15.52 },
  'NBTB': { pe: 11.96 },
  'OBNK': { pe: 15.47 },
  'ORRF': { pe: 8.7 },
  'PFBC': { pe: 9.36 },
  'PFSI': { pe: 7.5 },
  'SBCF': { pe: 15.48 },
  'SBFG': { pe: 11.45 },
  'SLCT': { pe: null },
  'WASH': { pe: 13.69 },
  'MYFW': { pe: 11.69 },
  'OFG': { pe: 8.96 },
  'PRK': { pe: 15.7 },
  'RBNC': { pe: null },
  'TBBK': { pe: 11.21 },
  'THFF': { pe: 9.24 },
  'TSC': { pe: null },
  'TWIN': { pe: 30.61 },
  'WSBC': { pe: 12.31 },
  'FITB': { pe: 10.55 },
  'HBAN': { pe: 10.09 },
  'STL': { pe: null },
  'BCBP': { pe: 7.39 },
  'BRKL': { pe: 10.5 },
  'CCB': { pe: 16.02 },
  'CFB': { pe: 10.81 },
  'COOP': { pe: 16.34 },
  'DCOM': { pe: 9.37 },
  'KRNY': { pe: 14.0 },
  'PBIP': { pe: null },
  'ACIW': { pe: 17.32 },
  'BCS': { pe: 6.05 },
  'SC': { pe: null },
  'SHG': { pe: 3.85 },
  'TKGBY': { pe: 3.27 },
  'BK': { pe: 10.82 },
  'C': { pe: 7.45 },
  'NTRS': { pe: 13.7 },
  'ABTX': { pe: 14.75 },
  'BFST': { pe: 9.87 },
  'BOH': { pe: 15.03 },
  'BWFG': { pe: 8.0 },
  'CNFR': { pe: -1.67 },
  'COLB': { pe: 11.34 },
  'ESQ': { pe: 12.93 },
  'FFWM': { pe: 9.52 },
  'FMBH': { pe: 10.55 },
  'FSBC': { pe: 10.21 },
  'FVCB': { pe: 11.27 },
  'FWBI': { pe: -0.08 },
  'GABC': { pe: 14.13 },
  'AOZOY': { pe: null },
  'CRARY': { pe: 6.85 },
  'EBKDY': { pe: 5.33 },
  'GGAL': { pe: 4.05 },
  'HDB': { pe: 22.99 },
  'LYG': { pe: 6.26 },
  'MFG': { pe: 8.0 },
  'SBRCY': { pe: null },
  'SKLKY': { pe: null },
  'ABCB': { pe: 10.1 },
  'AMBC': { pe: -16.58 },
  'BHB': { pe: 10.45 },
  'BHLB': { pe: 14.85 },
  'BMTC': { pe: null },
  'BPRN': { pe: 7.54 },
  'BSRR': { pe: 9.13 },
  'BSVN': { pe: 8.2 },
  'CAC': { pe: 11.02 },
  'CBU': { pe: 19.67 },
  'CCNE': { pe: 7.62 },
  'CIVB': { pe: 9.3 },
  'CMA': { pe: 10.17 },
  'CFFN': { pe: 16.18 },
  'CZWI': { pe: 8.76 },
  'HOME': { pe: null },
  'MCBS': { pe: 7.94 },
  'AL': { pe: -37.95 },
  'BNL': { pe: 29.93 },
  'BTBT': { pe: 12.36 },
  'CATM': { pe: null },
  'CZFS': { pe: 10.47 },
  'DLO': { pe: 70.24 },
  'EEFT': { pe: 15.27 },
  'EVOP': { pe: 29.91 },
  'FOUR': { pe: 38.0 },
  'HTH': { pe: 19.22 },
  'IMXI': { pe: 14.49 },
  'MGI': { pe: 20.74 },
  'NAVI': { pe: 4.98 },
  'OCN': { pe: 3.11 },
  'PAY': { pe: 280.75 },
  'SPGI': { pe: 33.66 },
  'HFWA': { pe: 12.7 },
  'LBAI': { pe: 10.21 },
  'LMST': { pe: 9.46 },
  'MBCN': { pe: 10.97 },
  'MNSB': { pe: 8.23 },
  'PACW': { pe: 7.0 },
  'PCSB': { pe: 18.39 },
  'RELI': { pe: -1.35 },
  'RRBI': { pe: 11.62 },
  'SHBI': { pe: 12.93 },
  'SMBC': { pe: 10.54 },
  'SMBK': { pe: 10.36 },
  'TBK': { pe: 21.06 },
  'TBNK': { pe: 12.14 },
  'TMP': { pe: 13.29 },
  'UBAB': { pe: 7.32 },
  'UMPQ': { pe: 12.56 },
  'BPOP': { pe: 7.51 },
  'CFG': { pe: 9.61 },
  'FCNCA': { pe: 11.56 },
  'CPF': { pe: 9.68 },
  'CUBI': { pe: 5.31 },
  'CWBC': { pe: 9.79 },
  'CZNC': { pe: null },
  'EBC': { pe: 15.87 },
  'EGBN': { pe: 11.12 },
  'EVGN': { pe: -5.47 },
  'FCBP': { pe: null },
  'FGBI': { pe: 9.07 },
  'FRBA': { pe: 8.97 },
  'FUNC': { pe: null },
  'HBNC': { pe: 8.68 },
  'HONE': { pe: 15.98 },
  'HTBK': { pe: 11.56 },
  'INBK': { pe: 8.35 },
  'LCNB': { pe: null },
  'MCB': { pe: 9.34 },
  'NCBS': { pe: 10.97 },
  'NTB': { pe: 8.23 },
  'PCB': { pe: 7.56 },
  'PDLB': { pe: -48.38 },
  'SQFT': { pe: -4.95 },
  'SUNL': { pe: 15.1 },
  'TRIN': { pe: 7.45 },
  'TRU': { pe: 22.05 },
  'AMRK': { pe: 7.28 },
  'CBOE': { pe: 19.22 },
  'COWN': { pe: 10.81 },
  'MKTX': { pe: 41.24 },
  'SNEX': { pe: 10.51 },
  'APAM': { pe: 11.67 },
  'BSIG': { pe: 11.26 },
  'GCMG': { pe: 16.04 },
  'NEU': { pe: null },
  'STEP': { pe: 21.5 },
  'STT': { pe: 10.36 },
  'STWD': { pe: 10.35 },
  'ACA': { pe: 27.59 },
  'ALL': { pe: 24.5 },
  'ARZGY': { pe: 7.47 },
  'CI': { pe: 12.62 },
  'AOMR': { pe: 4.64 },
  'CACC': { pe: 13.14 },
  'CURO': { pe: 42.72 },
  'ECPG': { pe: 4.39 },
  'FMCC': { pe: null },
  'FRHC': { pe: null },
  'MCO': { pe: 33.85 },
  'MDWT': { pe: 3.48 },
  'MRLN': { pe: null },
  'NNI': { pe: 13.06 },
  'OMF': { pe: 4.99 },
  'QTWO': { pe: 147.16 },
  'RM': { pe: 5.96 },
  'RPAY': { pe: 11.94 },
  'SQ': { pe: 91.28 },
  'SYF': { pe: 5.99 },
  'TREE': { pe: -10.0 },
  'WEX': { pe: 12.32 },
  'GS': { pe: 10.17 },
  'JEF': { pe: 11.94 },
  'TW': { pe: 40.19 },
  'GBCI': { pe: 19.71 },
  'PB': { pe: 13.47 },
  'SBNY': { pe: 9.2 },
  'ZION': { pe: 9.93 },
  'CBNK': { pe: 9.85 },
  'ESSA': { pe: 9.72 },
  'FBP': { pe: 10.06 },
  'HBCP': { pe: 9.65 },
  'MLVF': { pe: 19.63 },
  'NWBI': { pe: 14.17 },
  'NYCB': { pe: 8.98 },
  'WSBF': { pe: 15.1 },
  'CPSS': { pe: null },
  'ESNT': { pe: 5.71 },
  'MA': { pe: 33.52 },
  'NLY': { pe: 6.3 },
  'PAYS': { pe: null },
  'PFC': { pe: 9.85 },
  'RKT': { pe: 51.76 },
  'SLM': { pe: 5.95 },
  'TRTX': { pe: 8.77 },
  'PFS': { pe: 11.17 },
  'QCRH': { pe: 9.18 },
  'SBSI': { pe: 12.55 },
  'SFST': { pe: 11.63 },
  'WAFD': { pe: 10.49 },
  'WMPN': { pe: 34.39 },
  'WTFC': { pe: 11.25 },
  'EWBC': { pe: 9.8 },
  'FFIN': { pe: 28.96 },
  'FHN': { pe: 15.56 },
  'FRC': { pe: 19.48 },
  'PNFP': { pe: 11.89 },
  'SSB': { pe: 13.02 },
  'WBS': { pe: 9.13 },
  'EBMT': { pe: 10.38 },
  'FFIC': { pe: 9.69 },
  'PROV': { pe: 12.22 },
  'RVSB': { pe: 8.67 },
  'TFSL': { pe: 57.15 },
  'AGM': { pe: 10.53 },
  'EIG': { pe: 17.87 },
  'EVH': { pe: 90.71 },
  'FAF': { pe: 11.2 },
  'INNV': { pe: -136.0 },
  'KMPR': { pe: -24.58 },
  'OSCR': { pe: -3.07 },
  'OTRK': { pe: -0.33 },
  'PFG': { pe: 12.13 },
  'RDN': { pe: 5.73 },
  'RE': { pe: 8.32 },
  'RGA': { pe: 9.59 },
  'RLI': { pe: 23.68 },
  'UNM': { pe: 6.45 },
  'AFCG': { pe: 6.91 },
  'CDEVY': { pe: null },
  'COLD': { pe: -211.2 },
  'DEA': { pe: 53.47 },
  'NSA': { pe: 46.46 },
  'PEAK': { pe: 61.15 },
  'AGNC': { pe: 4.75 },
  'UPST': { pe: 53.24 },
  'JMP': { pe: null },
  'LPLA': { pe: 22.66 },
  'OTCM': { pe: 18.53 },
  'AMK': { pe: 12.87 },
  'PAX': { pe: 14.29 },
  'SWKH': { pe: 11.84 },
  'ACGL': { pe: 10.18 },
  'AEG': { pe: 12.28 },
  'AGESY': { pe: 8.31 },
  'AGO': { pe: 15.49 },
  'AIG': { pe: 11.45 },
  'AMSF': { pe: 17.8 },
  'CMAX': { pe: -19.85 },
  'CNVY': { pe: 42.04 },
  'EQH': { pe: 5.88 },
  'GLRE': { pe: null },
  'GSHD': { pe: 116.58 },
  'MKL': { pe: 18.6 },
  'ORI': { pe: 9.37 },
  'UVE': { pe: 9.66 },
  'AXP': { pe: 16.62 },
  'BR': { pe: 27.33 },
  'DFS': { pe: 7.06 },
  'DNB': { pe: 14.44 },
  'FLY': { pe: null },
  'INFO': { pe: null },
  'MQ': { pe: -19.97 },
  'NRZ': { pe: 8.31 },
  'OLB': { pe: -4.12 },
  'OPRT': { pe: 4.15 },
  'PRU': { pe: 10.62 },
  'ENV': { pe: 30.17 },
  'IBKR': { pe: 17.47 },
  'NMR': { pe: 10.91 },
  'RJF': { pe: 14.71 },
  'ARI': { pe: 9.08 },
  'BPFH': { pe: null },
  'FOCS': { pe: 9.8 },
  'KKR': { pe: 14.5 },
  'PMT': { pe: -119.77 },
  'APLE': { pe: 53.59 },
  'APTS': { pe: null },
  'CBRE': { pe: 13.54 },
  'CMO': { pe: null },
  'DX': { pe: 10.51 },
  'EGP': { pe: 49.7 },
  'GTY': { pe: 19.06 },
  'KW': { pe: 69.69 },
  'MAA': { pe: 38.67 },
  'MAC': { pe: -53.38 },
  'MDRR': { pe: null },
  'PCT': { pe: -17.73 },
  'REG': { pe: 33.29 },
  'SLG': { pe: -129.11 },
  'WPC': { pe: null },
  'AMTX': { pe: -8.2 },
  'DQ': { pe: 2.27 },
  'FSLR': { pe: -2326.0 },
  'FTCI': { pe: -8.24 },
  'GCEH': { pe: -3.52 },
  'AEIS': { pe: 17.0 },
  'AMG': { pe: 7.54 },
  'AMP': { pe: 12.0 },
  'ATAX': { pe: 7.27 },
  'BLK': { pe: 22.09 },
  'IVZ': { pe: 9.7 },
  'MN': { pe: null },
  'SCU': { pe: 4.27 },
  'AFG': { pe: 11.75 },
  'ALHC': { pe: -18.72 },
  'CHNG': { pe: 17.77 },
  'CNA': { pe: 10.98 },
  'DCT': { pe: 149.78 },
  'HALL': { pe: -0.56 },
  'HUM': { pe: 19.87 },
  'LMND': { pe: -6.02 },
  'LNC': { pe: 5.87 },
  'MBI': { pe: -3.28 },
  'TREX': { pe: 30.28 },
  'CLPR': { pe: -36.0 },
  'FTHM': { pe: -8.72 },
  'BRX': { pe: 25.01 },
  'COR': { pe: null },
  'CUBE': { pe: 53.98 },
  'FOR': { pe: 4.51 },
  'IIPR': { pe: 18.86 },
  'LTC': { pe: 16.7 },
  'MAXC': { pe: -21.67 },
  'NREF': { pe: 8.03 },
  'O': { pe: 51.82 },
  'AIV': { pe: null },
  'BRT': { pe: 10.76 },
  'CDR': { pe: null },
  'CSGP': { pe: 66.82 },
  'HHC': { pe: 27.0 },
  'NNN': { pe: 26.38 },
  'NTST': { pe: 140.53 },
  'PDM': { pe: 18.66 },
  'PGRE': { pe: 255.0 },
  'SPG': { pe: 19.29 },
  'WRI': { pe: null },
  'QS': { pe: -14.09 },
  'VOYA': { pe: 10.02 },
  'WETF': { pe: 17.9 },
  'ANTM': { pe: 16.0 },
  'AON': { pe: 22.77 },
  'AXS': { pe: 8.63 },
  'BHF': { pe: 4.96 },
  'CLOV': { pe: -3.83 },
  'CSLT': { pe: null },
  'DGICA': { pe: 14.51 },
  'FNF': { pe: 6.47 },
  'GOCO': { pe: -1.24 },
  'IFS': { pe: 6.93 },
  'KNSL': { pe: 39.64 },
  'MMC': { pe: 25.57 },
  'PROS': { pe: null },
  'PRVA': { pe: -199.56 },
  'SLQT': { pe: -4.68 },
  'SSREY': { pe: 17.21 },
  'THG': { pe: 13.38 },
  'TIG': { pe: 8.77 },
  'XL': { pe: null },
  'CREE': { pe: null },
  'FORM': { pe: 19.56 },
  'LSCC': { pe: 36.84 },
  'MPWR': { pe: 41.41 },
  'PDFS': { pe: 71.74 },
  'POWI': { pe: 21.43 },
  'TOELY': { pe: 14.18 },
  'AMADY': { pe: 37.05 },
  'ANSS': { pe: 36.13 },
  'BV': { pe: 10.19 },
  'CTXS': { pe: 20.42 },
  'CVLT': { pe: 22.12 },
  'DT': { pe: 56.59 },
  'EBIX': { pe: 7.74 },
  'ESTC': { pe: -179.11 },
  'EVLV': { pe: -6.29 },
  'GTYH': { pe: null },
  'GVP': { pe: null },
  'IIIV': { pe: 16.41 },
  'IMMR': { pe: 10.16 },
  'BIP': { pe: 81.02 },
  'CIGI': { pe: 21.97 },
  'CXP': { pe: null },
  'DOC': { pe: 58.35 },
  'EPRT': { pe: 24.72 },
  'ESRT': { pe: null },
  'JBGS': { pe: 37.49 },
  'MITEY': { pe: 12.39 },
  'UBA': { pe: null },
  'UE': { pe: 46.08 },
  'UOLGY': { pe: 17.37 },
  'ARR': { pe: 6.65 },
  'CTO': { pe: -733.33 },
  'CTRE': { pe: 90.38 },
  'CWK': { pe: 6.64 },
  'DEI': { pe: 38.47 },
  'DLR': { pe: 121.29 },
  'GBCS': { pe: null },
  'GMRE': { pe: 81.0 },
  'KIM': { pe: 48.73 },
  'KRG': { pe: -99.95 },
  'LFT': { pe: 10.48 },
  'LXP': { pe: null },
  'MPW': { pe: 8.33 },
  'NMRK': { pe: 6.04 },
  'OLP': { pe: 17.26 },
  'ROIC': { pe: 50.42 },
  'CSIQ': { pe: 18.25 },
  'CTEK': { pe: -3.7 },
  'HASI': { pe: 19.92 },
  'LOOP': { pe: -6.48 },
  'OESX': { pe: -7.8 },
  'VOLT': { pe: null },
  'VWSYF': { pe: -18.61 },
  'WATT': { pe: -4.0 },
  'AMBA': { pe: 72.45 },
  'AMD': { pe: 22.44 },
  'AMKR': { pe: 7.57 },
  'CRUS': { pe: 13.43 },
  'KLIC': { pe: 8.0 },
  'MRVL': { pe: 22.6 },
  'BMA': { pe: 3.58 },
  'CIB': { pe: 5.07 },
  'CRZBY': { pe: 7.76 },
  'DBSDY': { pe: 10.66 },
  'BAC': { pe: 11.38 },
  'BNPQY': { pe: 6.43 },
  'RY': { pe: 14.6 },
  'ALRS': { pe: 10.16 },
  'ALTA': { pe: null },
  'AMNB': { pe: 11.29 },
  'CATY': { pe: 9.56 },
  'CBAN': { pe: 9.85 },
  'CHCO': { pe: 14.56 },
  'EQBK': { pe: 9.64 },
  'ESXB': { pe: null },
  'FBMS': { pe: 9.79 },
  'FCCO': { pe: 10.03 },
  'FCFS': { pe: 16.14 },
  'FDVA': { pe: null },
  'FNB': { pe: 9.81 },
  'FRBK': { pe: 9.26 },
  'ASX': { pe: 6.76 },
  'CAMT': { pe: 16.09 },
  'CEVA': { pe: 42.38 },
  'ENTG': { pe: 24.5 },
  'HIMX': { pe: 4.61 },
  'INDI': { pe: -17.45 },
  'INTC': { pe: 15.76 },
  'MXL': { pe: 9.33 },
  'NPTN': { pe: 35.58 },
  'NXPI': { pe: 12.75 },
  'OIIM': { pe: 44.63 },
  'RNECY': { pe: 7.85 },
  'SMTC': { pe: 15.54 },
  'VECO': { pe: 14.14 },
  'AAPL': { pe: 28.66 },
  'AGYS': { pe: 62.52 },
  'ASUR': { pe: 110.6 },
  'AVID': { pe: 18.37 },
  'CDK': { pe: null },
  'CLSK': { pe: -6.21 },
  'HMST': { pe: 8.95 },
  'IBCP': { pe: 9.06 },
  'MBIN': { pe: 6.57 },
  'MRBK': { pe: 8.7 },
  'MVBF': { pe: 23.0 },
  'PMBC': { pe: null },
  'PNBK': { pe: null },
  'PVBC': { pe: 12.04 },
  'RNDB': { pe: 24.87 },
  'SCSG': { pe: null },
  'SFBS': { pe: 20.21 },
  'SRCE': { pe: 11.32 },
  'UMBF': { pe: 11.91 },
  'UNTY': { pe: 8.13 },
  'WNEB': { pe: 8.74 },
  'KEY': { pe: 8.76 },
  'RF': { pe: 9.89 },
  'BFIN': { pe: 12.11 },
  'EBSB': { pe: null },
  'FSFG': { pe: 8.73 },
  'GRRB': { pe: 11.72 },
  'LADR': { pe: 10.37 },
  'LPRO': { pe: 15.2 },
  'QTS': { pe: null },
  'ACRE': { pe: 9.09 },
  'AVB': { pe: 39.61 },
  'BDN': { pe: 18.04 },
  'CHCT': { pe: 44.4 },
  'CPT': { pe: 24.22 },
  'EARN': { pe: 8.18 },
  'GL': { pe: 12.85 },
  'GOOD': { pe: 0.0 },
  'IVR': { pe: 3.95 },
  'PEI': { pe: null },
  'PLYM': { pe: -32.53 },
  'PSN': { pe: 22.78 },
  'RMAX': { pe: 10.99 },
  'SBRA': { pe: 26.7 },
  'VER': { pe: null },
  'WMC': { pe: 0.69 },
  'WPTIF': { pe: null },
  'AMSC': { pe: -7.44 },
  'BKNIY': { pe: 8.35 },
  'BKRKY': { pe: 13.53 },
  'BSBR': { pe: 7.13 },
  'CMGGF': { pe: 5.46 },
  'KB': { pe: 4.0 },
  'SWDBY': { pe: 7.78 },
  'UOVEY': { pe: 10.19 },
  'DB': { pe: 5.32 },
  'TFC': { pe: 11.18 },
  'USB': { pe: 11.3 },
  'ALLG': { pe: -46.8 },
  'AROW': { pe: 11.87 },
  'BANF': { pe: 22.21 },
  'CLBK': { pe: 25.93 },
  'FCBC': { pe: null },
  'FCF': { pe: 10.94 },
  'FISI': { pe: 7.38 },
  'FMAO': { pe: 12.28 },
  'GWB': { pe: null },
  'HAFC': { pe: 8.99 },
  'HTBI': { pe: 12.5 },
  'ISBA': { pe: 8.35 },
  'SASR': { pe: 10.16 },
  'UBCP': { pe: null },
  'ALKT': { pe: -58.31 },
  'BKI': { pe: 26.05 },
  'ELVT': { pe: -3.57 },
  'ENVA': { pe: 5.76 },
  'EZPW': { pe: 13.11 },
  'FPAY': { pe: 4.74 },
  'GDOT': { pe: 9.31 },
  'HLI': { pe: 17.22 },
  'IMH': { pe: null },
  'LDI': { pe: -1.93 },
  'WD': { pe: 13.02 },
  'EVR': { pe: 10.14 },
  'GHL': { pe: -16.11 },
  'ICE': { pe: 20.92 },
  'LAB': { pe: null },
  'LAZ': { pe: 10.41 },
  'MS': { pe: 13.61 },
  'ARRY': { pe: 69.35 },
  'AVAV': { pe: 106.73 },
  'AY': { pe: 71.98 },
  'GEVO': { pe: -11.55 },
  'NEP': { pe: 14.99 },
  'SHLS': { pe: 82.23 },
  'SPWR': { pe: 101.56 },
  'SUNW': { pe: -5.35 },
  'ACLS': { pe: 14.73 },
  'AMAT': { pe: 13.84 },
  'API': { pe: -5.23 },
  'ATOM': { pe: -19.61 },
  'AVGO': { pe: 13.91 },
  'DSPG': { pe: null },
  'GSIT': { pe: null },
  'ICHR': { pe: 9.53 },
  'IMOS': { pe: 5.45 },
  'IVAC': { pe: -6.9 },
  'LASR': { pe: -59.0 },
  'LRCX': { pe: 14.23 },
  'HBMD': { pe: null },
  'HTLF': { pe: 9.86 },
  'IBTX': { pe: 14.2 },
  'ICBK': { pe: null },
  'LKFN': { pe: 20.0 },
  'LOB': { pe: 20.04 },
  'MTOR': { pe: null },
  'NKSH': { pe: 9.36 },
  'OCFC': { pe: 9.11 },
  'ONB': { pe: 9.88 },
  'OPBK': { pe: 5.75 },
  'OSBC': { pe: 10.49 },
  'PEBO': { pe: 8.97 },
  'PRSP': { pe: null },
  'RBCAA': { pe: 11.06 },
  'TCBI': { pe: 18.97 },
  'TCFC': { pe: 8.19 },
  'TRMK': { pe: 14.78 },
  'UBSI': { pe: 14.95 },
  'UVSP': { pe: 10.47 },
  'WABC': { pe: 16.42 },
  'SCHW': { pe: 19.3 },
  'VIRT': { pe: 7.35 },
  'VCTR': { pe: 6.46 },
  'AGL': { pe: -131.88 },
  'ATH': { pe: null },
  'CB': { pe: 12.6 },
  'CNC': { pe: 16.55 },
  'GBLI': { pe: null },
  'HCI': { pe: -236.4 },
  'HIG': { pe: 9.47 },
  'HRTG': { pe: -3.25 },
  'ING': { pe: 8.86 },
  'LFG': { pe: -105.26 },
  'MET': { pe: 9.08 },
  'MFC': { pe: 9.76 },
  'MIGI': { pe: null },
  'MPLN': { pe: 22.53 },
  'MSADY': { pe: 8.67 },
  'OSH': { pe: -10.91 },
  'PRE': { pe: -1.38 },
  'PRI': { pe: 11.94 },
  'BCH': { pe: 8.28 },
  'BLX': { pe: null },
  'ISNPY': { pe: 9.56 },
  'SAN': { pe: 4.89 },
  'WF': { pe: 2.64 },
  'BANC': { pe: 8.49 },
  'CHMG': { pe: 8.0 },
  'CNOB': { pe: 9.06 },
  'CVCY': { pe: 8.01 },
  'EFSC': { pe: 9.83 },
  'FMNB': { pe: 8.96 },
  'INDB': { pe: 16.2 },
  'OZK': { pe: 9.64 },
  'PFHD': { pe: 15.4 },
  'RBB': { pe: 7.02 },
  'SAL': { pe: 7.98 },
  'SBT': { pe: 40.07 },
  'SFNC': { pe: 10.89 },
  'SI': { pe: 19.39 },
  'SMMF': { pe: 7.42 },
  'SGH': { pe: 5.99 },
  'SOTK': { pe: null },
  'UCTT': { pe: 7.43 },
  'AVCT': { pe: null },
  'AVLR': { pe: 0.0 },
  'CHKP': { pe: 16.99 },
  'CLDR': { pe: null },
  'COUP': { pe: 260.96 },
  'CSOD': { pe: null },
  'DMRC': { pe: -5.73 },
  'DOCU': { pe: 40.94 },
  'GOOGL': { pe: 23.3 },
  'III': { pe: 16.97 },
  'INTU': { pe: 38.17 },
  'MAPS': { pe: -23.07 },
  'MCHX': { pe: -56.25 },
  'MODN': { pe: 45.41 },
  'MSP': { pe: 61.72 },
  'NATI': { pe: 20.7 },
  'NEWR': { pe: -149.07 },
  'WTBA': { pe: 9.17 },
  'CFR': { pe: 17.23 },
  'MTB': { pe: 15.08 },
  'SIVB': { pe: 15.13 },
  'VLY': { pe: 9.85 },
  'BKU': { pe: 10.99 },
  'NBN': { pe: 8.49 },
  'NECB': { pe: 10.14 },
  'AFRM': { pe: -19.47 },
  'ASPS': { pe: -9.12 },
  'BRMK': { pe: 10.7 },
  'CHMI': { pe: 5.78 },
  'CIT': { pe: null },
  'EQOS': { pe: null },
  'GHLD': { pe: 8.08 },
  'GPMT': { pe: 13.39 },
  'GSKY': { pe: null },
  'HMPT': { pe: -4.22 },
  'IX': { pe: 7.91 },
  'LMFA': { pe: -4.55 },
  'MNXBY': { pe: 48.47 },
  'RNR': { pe: 8.75 },
  'SGFY': { pe: -11.75 },
  'AAIC': { pe: 13.2 },
  'AHH': { pe: 22.45 },
  'BRMSY': { pe: 15.2 },
  'BXMT': { pe: 11.44 },
  'CCS': { pe: 2.71 },
  'EPR': { pe: 24.84 },
  'JLL': { pe: 9.54 },
  'STAG': { pe: 43.6 },
  'AAT': { pe: 44.54 },
  'DRE': { pe: 48.06 },
  'EXR': { pe: 34.08 },
  'HIW': { pe: 22.87 },
  'HPP': { pe: -99.4 },
  'NYMT': { pe: -10.17 },
  'OFC': { pe: 19.88 },
  'PSB': { pe: null },
  'RLJ': { pe: 93.86 },
  'RPT': { pe: 269.5 },
  'SPFI': { pe: 9.2 },
  'STXB': { pe: null },
  'TCBK': { pe: 12.83 },
  'TOWN': { pe: 10.54 },
  'TRST': { pe: 10.14 },
  'UCBI': { pe: 13.49 },
  'ALLY': { pe: 4.9 },
  'CBSH': { pe: 18.31 },
  'ALPN': { pe: -8.06 },
  'BANR': { pe: 11.5 },
  'FBC': { pe: 8.93 },
  'FFBC': { pe: 10.84 },
  'GSBC': { pe: 10.9 },
  'ATLC': { pe: 5.59 },
  'ECNCF': { pe: 19.13 },
  'EFX': { pe: 28.01 },
  'FIS': { pe: 14.35 },
  'HBT': { pe: 9.85 },
  'LOAN': { pe: 11.19 },
  'PAYA': { pe: 18.05 },
  'EGFEY': { pe: 3.78 },
  'IBN': { pe: 21.26 },
  'IDCBY': { pe: null },
  'PPERY': { pe: 10.5 },
  'BMO': { pe: 13.32 },
  'TD': { pe: 13.69 },
  'UBS': { pe: 7.77 },
  'WFC': { pe: 11.56 },
  'AMRB': { pe: null },
  'AUB': { pe: 12.13 },
  'BOCH': { pe: null },
  'BUSE': { pe: 10.45 },
  'CALB': { pe: 9.95 },
  'CASH': { pe: 8.51 },
  'CCCS': { pe: 33.59 },
  'CTBI': { pe: 9.98 },
  'FBIZ': { pe: 8.26 },
  'FBK': { pe: 14.39 },
  'FFNW': { pe: 11.96 },
  'FHB': { pe: 13.59 },
  'FIBK': { pe: 19.79 },
  'MSCI': { pe: 44.83 },
  'PFMT': { pe: -27.25 },
  'PLUS': { pe: 12.24 },
  'PRAA': { pe: 13.4 },
  'TRTN': { pe: 5.84 },
  'WSFS': { pe: 11.31 },
  'JKHY': { pe: 39.15 },
  'AB': { pe: 15.16 },
  'BEVFF': { pe: 14.77 },
  'CNS': { pe: 20.13 },
  'EFC': { pe: 9.3 },
  'SAMG': { pe: 11.85 },
  'BRK.A': { pe: 21.36 },
  'BRK.B': { pe: 21.32 },
  'CNO': { pe: 8.68 },
  'ERIE': { pe: 37.32 },
  'FSR': { pe: -5.69 },
  'GNW': { pe: 4.69 },
  'HQY': { pe: 47.14 },
  'JRVR': { pe: 13.11 },
  'SVC': { pe: -7.88 },
  'TRNO': { pe: 31.09 },
  'UDR': { pe: 193.46 },
  'WRE': { pe: null },
  'BEEM': { pe: -17.54 },
  'IPWR': { pe: -11.41 },
  'SOL': { pe: 39.63 },
  'SPIR': { pe: -3.44 },
  'ULBI': { pe: 22.0 },
  'A': { pe: 27.47 },
  'ALTR': { pe: 68.29 },
  'ASML': { pe: 37.76 },
  'BESIY': { pe: 16.52 },
  'DIOD': { pe: 11.23 },
  'INTT': { pe: 10.24 },
  'KOPN': { pe: -7.55 },
  'MKSI': { pe: 10.34 },
  'QUIK': { pe: -128.33 },
  'SKYT': { pe: -17.62 },
  'AMZN': { pe: 2842.0 },
  'PFIS': { pe: 10.04 },
  'PMTS': { pe: null },
  'RC': { pe: 7.46 },
  'SACH': { pe: 8.53 },
  'SSNC': { pe: 12.7 },
  'V': { pe: 28.04 },
  'CME': { pe: 25.9 },
  'MC': { pe: 16.69 },
  'NDAQ': { pe: 24.41 },
  'BEN': { pe: 8.66 },
  'BUR': { pe: 743.24 },
  'CIM': { pe: 6.93 },
  'JHG': { pe: 10.93 },
  'ORC': { pe: 5.94 },
  'PZN': { pe: null },
  'SFE': { pe: null },
  'TROW': { pe: 15.6 },
  'VRTS': { pe: 7.74 },
  'AFL': { pe: 11.58 },
  'AIZ': { pe: 13.89 },
  'ARGO': { pe: 8.91 },
  'FRME': { pe: 11.43 },
  'FSBW': { pe: 8.99 },
  'HLAN': { pe: 11.17 },
  'MBWM': { pe: 10.18 },
  'MCBC': { pe: 12.01 },
  'NRIM': { pe: 8.37 },
  'OCBI': { pe: 9.83 },
  'PGC': { pe: 8.52 },
  'PPBI': { pe: 11.84 },
  'RNST': { pe: 12.37 },
  'STBA': { pe: 10.6 },
  'SYBT': { pe: 23.52 },
  'VBTX': { pe: 12.71 },
  'BOKF': { pe: 13.93 },
  'PBCT': { pe: null },
  'SNV': { pe: 8.99 },
  'WAL': { pe: 8.6 },
  'FCCY': { pe: null },
  'GFED': { pe: null },
  'ISBC': { pe: null },
  'NFBK': { pe: 11.74 },
  'NTCT': { pe: 19.35 },
  'NTWK': { pe: null },
  'NVT': { pe: 16.48 },
  'ONDS': { pe: -5.97 },
  'PD': { pe: -150.83 },
  'PFSW': { pe: null },
  'QADA': { pe: null },
  'RNWK': { pe: null },
  'SMWB': { pe: -7.82 },
  'TUFN': { pe: -17.37 },
  'VERX': { pe: 50.44 },
  'WDAY': { pe: 51.73 },
  'XM': { pe: -271.0 },
  'DAIO': { pe: -7.9 },
  'PSTG': { pe: 32.09 },
  'BOX': { pe: 27.34 },
  'LPSN': { pe: -29.29 },
  'MELI': { pe: 127.45 },
  'QUOT': { pe: -3.97 },
  'SPSC': { pe: 61.13 },
  'KINS': { pe: -4.7 },
  'L': { pe: null },
  'LPG': { pe: 7.84 },
  'MAX': { pe: -12.52 },
  'MTG': { pe: 6.29 },
  'PGR': { pe: 26.53 },
  'PL': { pe: -13.21 },
  'PLMR': { pe: 25.96 },
  'SIGI': { pe: 15.25 },
  'UFCS': { pe: 12.68 },
  'VRSK': { pe: 35.22 },
  'FCPT': { pe: 23.72 },
  'LLESY': { pe: 18.31 },
  'MGP': { pe: null },
  'SITC': { pe: 37.42 },
  'ABR': { pe: 7.89 },
  'CORR': { pe: null },
  'FRT': { pe: 43.84 },
  'KRC': { pe: 31.55 },
  'MFA': { pe: -10.37 },
  'MITT': { pe: 14.51 },
  'CINF': { pe: 22.27 },
  'DGICB': { pe: 14.19 },
  'GTS': { pe: null },
  'IGIC': { pe: 5.81 },
  'PNGAY': { pe: 4.9 },
  'TRV': { pe: 12.95 },
  'UIHC': { pe: -0.44 },
  'WRB': { pe: 16.13 },
  'WTM': { pe: null },
  'Y': { pe: 10.7 },
  'AINC': { pe: 3.18 },
  'ALX': { pe: null },
  'GEO': { pe: 6.4 },
  'LMRK': { pe: null },
  'NXRT': { pe: 31.7 },
  'TWO': { pe: 6.43 },
  'ADC': { pe: 43.68 },
  'AKR': { pe: 45.13 },
  'APT': { pe: null },
  'CDOR': { pe: null },
  'CIO': { pe: 24.63 },
  'COF': { pe: 5.71 },
  'COIN': { pe: -7.54 },
  'FDS': { pe: 32.84 },
  'FICO': { pe: 28.92 },
  'FLT': { pe: 14.52 },
  'FLYW': { pe: -82.27 },
  'KREF': { pe: 10.47 },
  'MDLY': { pe: null },
  'NCNO': { pe: -117.69 },
  'ROOT': { pe: -0.04 },
  'USIO': { pe: -7.18 },
  'WRLD': { pe: 29.22 },
  'WU': { pe: 8.8 },
  'BGCP': { pe: 6.33 },
  'PIPR': { pe: 11.16 },
  'SF': { pe: 9.71 },
  'APO': { pe: 11.26 },
  'ARES': { pe: 23.75 },
  'ATASY': { pe: null },
  'BX': { pe: 20.64 },
  'CG': { pe: 8.45 },
  'NHI': { pe: 31.63 },
  'OHI': { pe: 16.94 },
  'PLD': { pe: 30.46 },
  'PSA': { pe: 31.38 },
  'PW': { pe: 12.61 },
  'SRC': { pe: 24.87 },
  'UBP': { pe: null },
  'VNO': { pe: 44.09 },
  'AMRS': { pe: -4.04 },
  'AZRE': { pe: null },
  'BE': { pe: -59.07 },
  'BEP': { pe: -105.14 },
  'BLDP': { pe: -13.44 },
  'ENPH': { pe: 71.85 },
  'EOSE': { pe: -0.62 },
  'KNDI': { pe: -34.43 },
  'ADI': { pe: 17.25 },
  'AOSL': { pe: 8.86 },
  'EMKR': { pe: -6.31 },
  'MCHP': { pe: 12.76 },
  'CMCT': { pe: -15.67 },
  'EQR': { pe: 53.71 },
  'ESS': { pe: 59.51 },
  'EXPI': { pe: 39.93 },
  'ILPT': { pe: null },
  'ORGN': { pe: -25.56 },
  'PINE': { pe: 16.64 },
  'RDIB': { pe: -38.04 },
  'RPAI': { pe: null },
  'RWT': { pe: -52.13 },
  'UMH': { pe: -38.98 },
  'WSR': { pe: 27.9 },
  'FLUX': { pe: -3.24 },
  'MAG': { pe: 26.8 },
  'PLUG': { pe: -29.66 },
  'SCTY': { pe: null },
  'SEDG': { pe: 64.58 },
  'TPIC': { pe: -10.96 },
  'AEHR': { pe: 35.15 },
  'ALGM': { pe: 26.73 },
  'CNNE': { pe: -4.08 },
  'HLNE': { pe: 23.87 },
  'SEIC': { pe: 15.65 },
  'AEL': { pe: 10.55 },
  'AJG': { pe: 24.53 },
  'BRO': { pe: 29.28 },
  'FNHC': { pe: null },
  'HMN': { pe: 16.97 },
  'LFC': { pe: 6.73 },
  'MCY': { pe: -28.02 },
  'NMIH': { pe: 6.94 },
  'SAFT': { pe: null },
  'SAXPY': { pe: 15.47 },
  'STFC': { pe: null },
  'UNH': { pe: 24.89 },
  'GNL': { pe: -1460.0 },
  'REXR': { pe: 70.09 },
  'VICI': { pe: 24.48 },
  'WPG': { pe: null },
  'ACC': { pe: null },
  'AHT': { pe: -3.13 },
  'AUDC': { pe: 15.96 },
  'DOMO': { pe: -23.49 },
  'ECOM': { pe: 18.73 },
  'EPAY': { pe: null },
  'GOOG': { pe: 23.45 },
  'INOV': { pe: null },
  'ISDR': { pe: 21.43 },
  'LAZR': { pe: -14.11 },
  'MANH': { pe: 66.1 },
  'MDB': { pe: -1903.58 },
  'OTEX': { pe: 12.6 },
  'PHR': { pe: -6.04 },
  'RMNI': { pe: 12.98 },
  'SDGR': { pe: -12.71 },
  'TCYSF': { pe: 87.82 },
  'VRNS': { pe: 157.26 },
  'UIS': { pe: 5.75 },
  'FARO': { pe: 159.78 },
  'BCOR': { pe: 12.85 },
  'ASYS': { pe: 10.17 },
  'AXTI': { pe: 21.95 },
  'AZTA': { pe: 134.45 },
  'BHE': { pe: 14.65 },
  'IPGP': { pe: 20.93 },
  'JBL': { pe: 8.23 },
  'LSI': { pe: 34.16 },
  'MTSI': { pe: 20.57 },
  'NMTC': { pe: -2.61 },
  'NVDA': { pe: 43.35 },
  'ON': { pe: 13.31 },
  'SQNS': { pe: -14.59 },
  'TSM': { pe: 14.39 },
  'TTMI': { pe: 10.5 },
  'TXN': { pe: 18.72 },
  'ALYA': { pe: -24.83 },
  'APPF': { pe: -51.83 },
  'AVNT': { pe: 13.74 },
  'BLIN': { pe: -35.25 },
  'BWAY': { pe: -20.68 },
  'CRM': { pe: 39.57 },
  'DTST': { pe: -12.68 },
  'EXTN': { pe: -1.97 },
  'FROG': { pe: 2419.0 },
  'INGR': { pe: 13.04 },
  'IQV': { pe: 23.78 },
  'ONTF': { pe: -17.7 },
  'PATH': { pe: -975.5 },
  'PEGA': { pe: 96.67 },
  'PLAN': { pe: -374.88 },
  'RXT': { pe: 9.96 },
  'SOFO': { pe: -2.86 },
  'SPNS': { pe: 18.49 },
  'THRY': { pe: 8.26 },
  'TTGT': { pe: 27.37 },
  'VIEW': { pe: -1.88 },
  'VRNT': { pe: 19.35 },
  'ZIXI': { pe: null },
  'STX': { pe: 12.25 },
  'VUZI': { pe: -15.06 },
  'DHX': { pe: 77.14 },
  'GLBE': { pe: -29.82 },
  'LQDT': { pe: 24.28 },
  'OPEN': { pe: -8.2 },
  'OPRX': { pe: 47.69 },
  'POSH': { pe: -13.2 },
  'CMBM': { pe: 31.25 },
  'CSCO': { pe: 13.76 },
  'LTRX': { pe: 18.5 },
  'STAR': { pe: 2.25 },
  'CMTL': { pe: -45.74 },
  'FCUV': { pe: null },
  'SCKT': { pe: 15.42 },
  'TESS': { pe: -19.25 },
  'UNIT': { pe: 12.99 },
  'AVNW': { pe: 11.77 },
  'CABO': { pe: 20.94 },
  'LILAK': { pe: -7.49 },
  'NXTP': { pe: -0.62 },
  'RNG': { pe: 24.47 },
  'ALSK': { pe: null },
  'BZQIY': { pe: 13.77 },
  'TLK': { pe: 17.06 },
  'DTGI': { pe: null },
  'GTT': { pe: null },
  'SWCH': { pe: 226.67 },
  'BOOM': { pe: 31.01 },
  'CVU': { pe: null },
  'ERJ': { pe: 15.45 },
  'TDG': { pe: 36.49 },
  'FINMY': { pe: 7.82 },
  'GTEC': { pe: 6.9 },
  'LDOS': { pe: 15.88 },
  'RTX': { pe: 20.17 },
  'SINT': { pe: -1.17 },
  'TT': { pe: 23.41 },
  'TXT': { pe: 17.0 },
  'AROC': { pe: 20.03 },
  'EEX': { pe: null },
  'HPK': { pe: 6.54 },
  'NEXT': { pe: -23.19 },
  'NLST': { pe: -30.23 },
  'PXLW': { pe: -8.54 },
  'QRVO': { pe: 10.85 },
  'RMBS': { pe: 19.91 },
  'ST': { pe: 13.15 },
  'SWKS': { pe: 9.76 },
  'ADBE': { pe: 32.36 },
  'ADSK': { pe: 35.0 },
  'AGIL': { pe: -16.86 },
  'BKYI': { pe: -4.1 },
  'BL': { pe: 354.05 },
  'BLKB': { pe: 22.21 },
  'CDNS': { pe: 46.36 },
  'CGNT': { pe: -5.87 },
  'FIVN': { pe: 80.3 },
  'GLOB': { pe: 46.06 },
  'INSG': { pe: -7.86 },
  'ITI': { pe: -19.47 },
  'MARK': { pe: null },
  'ARE': { pe: 59.08 },
  'BRG': { pe: null },
  'BXP': { pe: 23.04 },
  'CUZ': { pe: 36.91 },
  'DHC': { pe: -1.83 },
  'FR': { pe: 33.49 },
  'FSP': { pe: -29.27 },
  'HR': { pe: 49.57 },
  'IRT': { pe: 77.0 },
  'OPI': { pe: -32.58 },
  'SNR': { pe: null },
  'TRMT': { pe: null },
  'BEPC': { pe: -128.06 },
  'BIOX': { pe: 33.88 },
  'BLNK': { pe: -14.11 },
  'FCEL': { pe: -17.88 },
  'GCTAY': { pe: -24.2 },
  'MAXN': { pe: -4.0 },
  'NOVA': { pe: -20.46 },
  'REGI': { pe: null },
  'MU': { pe: 8.57 },
  'PLAB': { pe: 11.49 },
  'QCOM': { pe: 11.54 },
  'RELL': { pe: 21.41 },
  'SANM': { pe: 10.19 },
  'SIMO': { pe: 10.32 },
  'SITM': { pe: 31.0 },
  'STM': { pe: 9.82 },
  'TER': { pe: 25.24 },
  'VSH': { pe: 7.13 },
  'XPER': { pe: 7.65 },
  'AI': { pe: -26.41 },
  'AMSWA': { pe: 48.28 },
  'AYX': { pe: -109.26 },
  'BCOV': { pe: 23.86 },
  'BSY': { pe: 47.16 },
  'IVDA': { pe: -16.38 },
  'NUAN': { pe: null },
  'PCOM': { pe: 51.45 },
  'PING': { pe: -47.54 },
  'OAS': { pe: 3.22 },
  'VKIN': { pe: null },
  'CVX': { pe: 8.37 },
  'IMO': { pe: 6.74 },
  'OXY': { pe: 5.91 },
  'CLB': { pe: 29.16 },
  'ET': { pe: 7.75 },
  'NJR': { pe: 18.24 },
  'NS': { pe: 19.68 },
  'NWN': { pe: 20.68 },
  'ICD': { pe: -3.14 },
  'RES': { pe: 10.46 },
  'SUBCY': { pe: 294.33 },
  'INT': { pe: 14.26 },
  'PBF': { pe: 1.87 },
  'ATRO': { pe: -18.53 },
  'AVTX': { pe: -0.08 },
  'FN': { pe: 17.65 },
  'IIN': { pe: null },
  'LYTS': { pe: 11.5 },
  'MIME': { pe: null },
  'PRO': { pe: -41.65 },
  'SEMR': { pe: -55.08 },
  'SNOW': { pe: 948.76 },
  'SPLK': { pe: 1369.75 },
  'TASK': { pe: 11.75 },
  'TRHC': { pe: -5.74 },
  'TTEC': { pe: 15.07 },
  'ZBRA': { pe: 18.44 },
  'ZUO': { pe: -60.56 },
  'DELL': { pe: 6.81 },
  'FJTSY': { pe: 16.31 },
  'DAKT': { pe: 15.85 },
  'WDC': { pe: 10.62 },
  'AEYE': { pe: -26.04 },
  'FLNT': { pe: 14.25 },
  'GDDY': { pe: 38.27 },
  'LOV': { pe: -1.02 },
  'OSTK': { pe: 36.48 },
  'BDC': { pe: 11.44 },
  'MVIS': { pe: -15.03 },
  'NICE': { pe: 31.05 },
  'RNWEF': { pe: -31.0 },
  'SCI': { pe: 19.66 },
  'AMPG': { pe: -36.57 },
  'GE': { pe: 28.54 },
  'ATKR': { pe: 4.64 },
  'BW': { pe: 48.31 },
  'CR': { pe: 14.02 },
  'DE': { pe: 14.57 },
  'ERII': { pe: 60.51 },
  'FSTR': { pe: 66.36 },
  'IEX': { pe: 27.23 },
  'LECO': { pe: 18.08 },
  'LNN': { pe: 26.78 },
  'RYI': { pe: 2.53 },
  'SKFRY': { pe: 9.76 },
  'URI': { pe: 10.38 },
  'VMI': { pe: 20.6 },
  'COMS': { pe: -1.15 },
  'CRNT': { pe: -245.0 },
  'PANW': { pe: 62.06 },
  'ADTN': { pe: 26.36 },
  'ALLT': { pe: -7.22 },
  'EGHT': { pe: 21.61 },
  'EXFO': { pe: null },
  'GRMN': { pe: 19.46 },
  'INFN': { pe: -187.33 },
  'VNT': { pe: 7.32 },
  'CXDO': { pe: 38.13 },
  'TDS': { pe: 35.63 },
  'TIXT': { pe: 33.02 },
  'VG': { pe: 210.0 },
  'CCOI': { pe: 82.08 },
  'ROK': { pe: 25.98 },
  'AE': { pe: 15.33 },
  'AR': { pe: 6.28 },
  'BRY': { pe: 4.02 },
  'PHX': { pe: 4.96 },
  'TCEHY': { pe: 26.67 },
  'VRSN': { pe: 33.46 },
  'FFIV': { pe: 17.83 },
  'JNPR': { pe: 15.25 },
  'NN': { pe: -17.94 },
  'APH': { pe: 27.27 },
  'CALX': { pe: 64.86 },
  'FKWL': { pe: null },
  'GIC': { pe: 14.31 },
  'GNSS': { pe: -35.78 },
  'SYTA': { pe: -1.04 },
  'BAND': { pe: 183.5 },
  'TIGO': { pe: 6.59 },
  'TMUS': { pe: 68.01 },
  'CBB': { pe: null },
  'KKPNY': { pe: 17.89 },
  'PHI': { pe: 13.67 },
  'TEF': { pe: 9.86 },
  'CONE': { pe: null },
  'ALLE': { pe: 20.07 },
  'BA': { pe: -90.38 },
  'RUN': { pe: -43.96 },
  'ACMR': { pe: 23.82 },
  'COHU': { pe: 10.62 },
  'KLAC': { pe: 15.88 },
  'MRAM': { pe: 48.8 },
  'MX': { pe: 22.03 },
  'MXIM': { pe: null },
  'NVMI': { pe: 22.05 },
  'ONTO': { pe: 15.11 },
  'PRTH': { pe: -15.16 },
  'UMC': { pe: 6.13 },
  'VICR': { pe: 93.16 },
  'XLNX': { pe: null },
  'AKAM': { pe: 18.2 },
  'ASAN': { pe: -19.42 },
  'DPSI': { pe: 22.67 },
  'GWRE': { pe: -156.82 },
  'JAMF': { pe: 201.62 },
  'MNDY': { pe: -51.39 },
  'NH': { pe: null },
  'WSC': { pe: 31.53 },
  'WTS': { pe: 22.98 },
  'AKZOY': { pe: 16.86 },
  'CBT': { pe: 11.99 },
  'HUN': { pe: 7.12 },
  'KOP': { pe: 6.34 },
  'LYB': { pe: 5.23 },
  'MOS': { pe: 4.04 },
  'TROX': { pe: 4.87 },
  'ATC': { pe: 18.31 },
  'BWXT': { pe: 17.03 },
  'KWR': { pe: 32.85 },
  'UNVR': { pe: 7.83 },
  'PCH': { pe: 9.43 },
  'POST': { pe: 37.97 },
  'SON': { pe: 10.65 },
  'WRK': { pe: 7.91 },
  'AVTR': { pe: 18.37 },
  'TMHC': { pe: 2.81 },
  'TPH': { pe: 3.48 },
  'CYBR': { pe: -218.88 },
  'DDOG': { pe: 139.27 },
  'EGAN': { pe: 119.75 },
  'INTZ': { pe: -5.41 },
  'META': { pe: 17.84 },
  'MSTR': { pe: -3.08 },
  'NOW': { pe: 67.05 },
  'NTNX': { pe: -22.35 },
  'SLP': { pe: 97.72 },
  'SUMO': { pe: -14.49 },
  'TUYA': { pe: -5.5 },
  'U': { pe: -127.07 },
  'VERI': { pe: -37.38 },
  'HPQ': { pe: 8.04 },
  'BRTHY': { pe: 13.89 },
  'SYNA': { pe: 9.72 },
  'ANGI': { pe: -24.45 },
  'RDFN': { pe: -4.16 },
  'SOHU': { pe: null },
  'STRM': { pe: -7.48 },
  'MGIC': { pe: 56.63 },
  'MSFT': { pe: 31.06 },
  'ORCL': { pe: 15.79 },
  'QADB': { pe: null },
  'QLYS': { pe: 44.99 },
  'SAP': { pe: 18.7 },
  'SMSI': { pe: -7.15 },
  'SRAX': { pe: 9.59 },
  'VEEV': { pe: 54.12 },
  'NTAP': { pe: 13.9 },
  'BMBL': { pe: 185.94 },
  'EB': { pe: -11.25 },
  'EBAY': { pe: 12.05 },
  'LLNW': { pe: 123.0 },
  'MTCH': { pe: 40.82 },
  'PINS': { pe: 40.16 },
  'SE': { pe: -18.88 },
  'WISH': { pe: -2.89 },
  'ZM': { pe: 26.8 },
  'EXTR': { pe: 17.27 },
  'BFLY': { pe: -7.12 },
  'BNFT': { pe: 107.29 },
  'DIBS': { pe: -10.43 },
  'GRPN': { pe: -11.89 },
  'IAC': { pe: -5.6 },
  'IZEA': { pe: -16.43 },
  'SHOP': { pe: -373.0 },
  'TCX': { pe: null },
  'ZEN': { pe: 102.12 },
  'ZG': { pe: 24.68 },
  'CTV': { pe: -44.38 },
  'DGII': { pe: 80.57 },
  'GILT': { pe: 64.27 },
  'HLIT': { pe: 23.29 },
  'KVHI': { pe: -26.61 },
  'PCTI': { pe: 17.68 },
  'VSAT': { pe: 45.81 },
  'WTT': { pe: -23.83 },
  'KLR': { pe: 21.22 },
  'MMI': { pe: 9.9 },
  'REI': { pe: 2.8 },
  'TELL': { pe: -25.87 },
  'TRIL': { pe: null },
  'VET': { pe: 3.41 },
  'XEC': { pe: null },
  'IO': { pe: null },
  'NEX': { pe: 6.64 },
  'NGS': { pe: -1025.0 },
  'NOV': { pe: 31.34 },
  'PFIE': { pe: 29.0 },
  'PUMP': { pe: 30.86 },
  'TTI': { pe: 17.95 },
  'TUSK': { pe: null },
  'USAC': { pe: -86.62 },
  'WTTR': { pe: 14.1 },
  'CPG': { pe: 2.56 },
  'EPD': { pe: 10.7 },
  'ETRN': { pe: 16.43 },
  'GLP': { pe: 4.08 },
  'MMP': { pe: 10.78 },
  'OKE': { pe: 16.7 },
  'HEI': { pe: 60.27 },
  'TFX': { pe: 18.49 },
  'GDYN': { pe: 46.49 },
  'HII': { pe: 15.5 },
  'NOC': { pe: 19.88 },
  'APA': { pe: 3.6 },
  'CDEV': { pe: 3.99 },
  'KOS': { pe: 5.29 },
  'PDCE': { pe: 3.65 },
  'PED': { pe: 8.85 },
  'PRT': { pe: null },
  'WTI': { pe: 2.5 },
  'ASH': { pe: 18.18 },
  'E': { pe: 2.87 },
  'MRO': { pe: 4.75 },
  'OMVKY': { pe: 2.73 },
  'PTR': { pe: 4.18 },
  'FTSI': { pe: null },
  'HLX': { pe: -6.91 },
  'MIND': { pe: -1.43 },
  'SLB': { pe: 17.52 },
  'SOI': { pe: 15.26 },
  'GEL': { pe: 365.0 },
  'OMP': { pe: null },
  'PAGP': { pe: 8.77 },
  'TGS': { pe: 21.86 },
  'GPRE': { pe: -238.31 },
  'PSX': { pe: 5.7 },
  'REX': { pe: 13.68 },
  'TA': { pe: 6.58 },
  'VLO': { pe: 4.7 },
  'APELY': { pe: 8.09 },
  'BELFA': { pe: 9.42 },
  'BRKR': { pe: 26.25 },
  'DLB': { pe: 37.75 },
  'LFUS': { pe: 14.86 },
  'VPG': { pe: 15.58 },
  'IEC': { pe: null },
  'ALTG': { pe: 34.11 },
  'AME': { pe: 23.49 },
  'CSL': { pe: 15.57 },
  'SPH': { pe: 5.9 },
  'PTEN': { pe: 33.28 },
  'DK': { pe: 3.12 },
  'SUN': { pe: 6.99 },
  'VTNR': { pe: 12.07 },
  'BRC': { pe: 14.66 },
  'RESN': { pe: null },
  'SLAB': { pe: 31.99 },
  'SYPR': { pe: null },
  'VRT': { pe: 20.48 },
  'ALG': { pe: 16.39 },
  'AOS': { pe: 18.35 },
  'CIR': { pe: 11.3 },
  'DCI': { pe: 19.6 },
  'FLOW': { pe: null },
  'OEC': { pe: 7.76 },
  'PPSI': { pe: -7.46 },
  'TITN': { pe: 10.18 },
  'TRS': { pe: 13.03 },
  'LUNA': { pe: 36.47 },
  'AVYA': { pe: 1.71 },
  'CIEN': { pe: 26.54 },
  'CLFD': { pe: 38.06 },
  'DY': { pe: 33.3 },
  'IDCC': { pe: 22.69 },
  'SAMOF': { pe: null },
  'VISL': { pe: -4.21 },
  'ATI': { pe: 16.34 },
  'CLPT': { pe: -19.51 },
  'ERIC': { pe: 11.56 },
  'GSAT': { pe: -57.67 },
  'IRDM': { pe: 506.89 },
  'LILA': { pe: -8.17 },
  'LUMN': { pe: 6.83 },
  'WIFI': { pe: null },
  'WYY': { pe: -1.63 },
  'CNSL': { pe: -80.0 },
  'DOCN': { pe: 60.11 },
  'ARLO': { pe: -35.95 },
  'ASLE': { pe: 15.23 },
  'DHR': { pe: 28.42 },
  'ETN': { pe: 20.1 },
  'GRC': { pe: 20.39 },
  'HDSN': { pe: 5.57 },
  'HOLI': { pe: null },
  'HSC': { pe: -123.4 },
  'IIIN': { pe: 4.54 },
  'JBT': { pe: 24.23 },
  'KAI': { pe: 21.47 },
  'ROLL': { pe: 40.1 },
  'ROP': { pe: 29.69 },
  'THM': { pe: null },
  'TRNS': { pe: 49.72 },
  'PKI': { pe: 19.25 },
  'PLL': { pe: -31.54 },
  'FMC': { pe: 15.17 },
  'KRO': { pe: 9.35 },
  'TINLY': { pe: null },
  'VHI': { pe: 7.01 },
  'LXFR': { pe: 11.7 },
  'MTD': { pe: 34.78 },
  'NDEKY': { pe: 12.01 },
  'TRMB': { pe: 25.68 },
  'AVD': { pe: 17.64 },
  'LIN': { pe: 25.96 },
  'NL': { pe: null },
  'OLN': { pe: 6.09 },
  'CLZNY': { pe: 27.35 },
  'GRA': { pe: null },
  'GLT': { pe: 70.75 },
  'VRS': { pe: null },
  'DSGR': { pe: 29.39 },
  'GPK': { pe: 10.71 },
  'PALT': { pe: -5.65 },
  'PTVE': { pe: 11.78 },
  'SLGN': { pe: 11.76 },
  'CVEO': { pe: 117.4 },
  'DFH': { pe: 4.83 },
  'KBH': { pe: 3.16 },
  'LEGH': { pe: 8.15 },
  'KAMN': { pe: 19.68 },
  'MANT': { pe: 29.4 },
  'VTSI': { pe: 17.25 },
  'AMPY': { pe: null },
  'ARCH': { pe: 2.31 },
  'AXU': { pe: -5.4 },
  'BATL': { pe: null },
  'DINO': { pe: 4.18 },
  'DVN': { pe: 6.9 },
  'EGY': { pe: 2.36 },
  'GTE': { pe: 2.46 },
  'HES': { pe: 12.74 },
  'MCF': { pe: null },
  'MGY': { pe: 5.05 },
  'SD': { pe: null },
  'SM': { pe: 4.83 },
  'BP': { pe: 3.7 },
  'HAL': { pe: 13.96 },
  'NCSM': { pe: null },
  'AM': { pe: 14.54 },
  'DKL': { pe: 13.48 },
  'NGVT': { pe: 11.89 },
  'POLY': { pe: 15.56 },
  'KLBAY': { pe: 6.05 },
  'RYN': { pe: 64.03 },
  'AMCR': { pe: 16.06 },
  'KRT': { pe: 12.43 },
  'CVCO': { pe: 11.53 },
  'GRBK': { pe: 4.97 },
  'MTH': { pe: 3.27 },
  'PHM': { pe: 3.86 },
  'SGBX': { pe: null },
  'CWST': { pe: 80.81 },
  'MEG': { pe: -34.53 },
  'ITT': { pe: 18.55 },
  'POWL': { pe: 26.93 },
  'SBFFY': { pe: null },
  'TTEK': { pe: 33.51 },
  'MG': { pe: 16.64 },
  'AHCHY': { pe: 5.03 },
  'STRL': { pe: 8.66 },
  'ENB': { pe: 24.43 },
  'MPLX': { pe: 9.86 },
  'SMLP': { pe: -1.56 },
  'PDS': { pe: -40.29 },
  'RIG': { pe: -5.4 },
  'TDW': { pe: -25.15 },
  'CVI': { pe: 7.69 },
  'MPC': { pe: 4.7 },
  'PARR': { pe: 4.6 },
  'CTS': { pe: 17.23 },
  'EMAN': { pe: null },
  'FELE': { pe: 23.81 },
  'OSIS': { pe: 16.89 },
  'WCC': { pe: 8.86 },
  'PHG': { pe: 13.07 },
  'ADRZY': { pe: 12.1 },
  'AIN': { pe: 26.05 },
  'ALFVY': { pe: 23.5 },
  'AMOT': { pe: 28.07 },
  'AZZ': { pe: 10.41 },
  'ADES': { pe: null },
  'ECOL': { pe: null },
  'SMED': { pe: 174.8 },
  'WCN': { pe: 38.18 },
  'PRIM': { pe: 9.47 },
  'AOUT': { pe: 8.34 },
  'IDN': { pe: -10.74 },
  'SSTI': { pe: 170.57 },
  'VVNT': { pe: -8.82 },
  'WRAP': { pe: -4.34 },
  'HDELY': { pe: 6.29 },
  'AGX': { pe: 13.19 },
  'MTRX': { pe: -2.86 },
  'AMWD': { pe: 9.37 },
  'AYI': { pe: 14.51 },
  'BXC': { pe: 2.47 },
  'CSR': { pe: -80.1 },
  'MAS': { pe: 13.56 },
  'CMC': { pe: 5.16 },
  'MITSY': { pe: 5.34 },
  'TWOU': { pe: -49.94 },
  'TWTR': { pe: 41.9 },
  'DZSI': { pe: 28.04 },
  'NTGR': { pe: -282.33 },
  'UI': { pe: 60.38 },
  'AIRO': { pe: null },
  'AWRE': { pe: -9.04 },
  'GLW': { pe: 16.39 },
  'MAXR': { pe: 91.03 },
  'RADI': { pe: -22.21 },
  'RFIL': { pe: null },
  'TLSNY': { pe: 23.45 },
  'USM': { pe: 29.45 },
  'KDDIY': { pe: 13.36 },
  'TEO': { pe: null },
  'VZ': { pe: 8.75 },
  'APLD': { pe: -11.78 },
  'CW': { pe: 18.13 },
  'HWM': { pe: 27.27 },
  'SPR': { pe: -22.15 },
  'ARBE': { pe: -11.21 },
  'ASPN': { pe: -6.04 },
  'AWI': { pe: 18.25 },
  'AZEK': { pe: 22.95 },
  'LPX': { pe: 4.53 },
  'NX': { pe: 10.74 },
  'WIRE': { pe: 4.74 },
  'ZEUS': { pe: 3.29 },
  'AA': { pe: 6.84 },
  'MSNFY': { pe: null },
  'RIO': { pe: 5.44 },
  'EXK': { pe: 32.0 },
  'GPL': { pe: -0.23 },
  'HL': { pe: 47.22 },
  'PZG': { pe: -3.01 },
  'SAND': { pe: 39.2 },
  'ADOOY': { pe: 2.65 },
  'HNRG': { pe: null },
  'D': { pe: 20.73 },
  'ETR': { pe: 18.43 },
  'B': { pe: 16.92 },
  'CLIR': { pe: -5.92 },
  'DAN': { pe: 22.78 },
  'EBCOY': { pe: null },
  'EPAC': { pe: 27.99 },
  'GHM': { pe: -159.0 },
  'LDL': { pe: null },
  'MTW': { pe: 19.49 },
  'MWA': { pe: 19.53 },
  'NNBR': { pe: -63.5 },
  'OMRNY': { pe: 27.25 },
  'RBA': { pe: 32.3 },
  'SWK': { pe: 18.58 },
  'WBT': { pe: null },
  'BIO': { pe: 35.53 },
  'FUWAY': { pe: 9.07 },
  'SII': { pe: 32.09 },
  'TMO': { pe: 25.93 },
  'TRYIY': { pe: 14.99 },
  'TSE': { pe: 7.22 },
  'EVRG': { pe: 19.87 },
  'LNT': { pe: 23.12 },
  'WTRG': { pe: 29.0 },
  'NWE': { pe: 17.1 },
  'FTS': { pe: 22.1 },
  'HNP': { pe: 20.68 },
  'GWRS': { pe: 57.16 },
  'XYL': { pe: 39.05 },
  'YORW': { pe: 33.61 },
  'BMY': { pe: 9.94 },
  'CNST': { pe: null },
  'CTLT': { pe: 27.65 },
  'GSK': { pe: 12.83 },
  'IGXT': { pe: null },
  'MRK': { pe: 12.3 },
  'TNXP': { pe: -0.26 },
  'AERI': { pe: -5.72 },
  'AGLE': { pe: -0.43 },
  'ALRN': { pe: -0.65 },
  'AMAM': { pe: null },
  'OKTA': { pe: -90.82 },
  'PFPT': { pe: null },
  'PINC': { pe: 15.32 },
  'RIOT': { pe: -75.91 },
  'SNCR': { pe: 9.38 },
  'SPT': { pe: -556.64 },
  'SWI': { pe: 11.62 },
  'UPLD': { pe: 6.14 },
  'VMW': { pe: 18.76 },
  'WK': { pe: -135.96 },
  'CRSR': { pe: 46.86 },
  'HPE': { pe: 7.32 },
  'OLED': { pe: 29.53 },
  'PAR': { pe: -27.52 },
  'EHTH': { pe: -2.84 },
  'LC': { pe: 5.49 },
  'PYPL': { pe: 25.17 },
  'SNAP': { pe: -14.74 },
  'WB': { pe: 7.87 },
  'YELP': { pe: 47.28 },
  'AQMS': { pe: -5.32 },
  'QRHC': { pe: 15.12 },
  'EXPO': { pe: 55.46 },
  'JEXYY': { pe: null },
  'LMB': { pe: 11.03 },
  'TISI': { pe: null },
  'ADT': { pe: 17.82 },
  'CYRN': { pe: null },
  'DGLY': { pe: -2.12 },
  'IRBT': { pe: 31.22 },
  'KTOS': { pe: 47.78 },
  'REZI': { pe: 9.26 },
  'SPCB': { pe: -2.75 },
  'CX': { pe: 7.21 },
  'MLM': { pe: 28.4 },
  'GLDD': { pe: 21.87 },
  'KMTUY': { pe: 9.98 },
  'MTZ': { pe: 25.01 },
  'AAON': { pe: 33.65 },
  'FAST': { pe: 29.79 },
  'ANAB': { pe: -5.78 },
  'APLT': { pe: -0.48 },
  'APVO': { pe: 4.07 },
  'ARMP': { pe: -4.01 },
  'ASTR': { pe: -2.56 },
  'BCAB': { pe: -3.59 },
  'BDSX': { pe: -1.46 },
  'BOLT': { pe: -0.83 },
  'BTX': { pe: -1.05 },
  'BYSI': { pe: null },
  'CABA': { pe: -0.73 },
  'CELC': { pe: -5.41 },
  'CERE': { pe: -15.1 },
  'CFRX': { pe: -0.21 },
  'CNTA': { pe: -1.81 },
  'CRNX': { pe: -6.97 },
  'CRSP': { pe: -7.73 },
  'CTMX': { pe: -1.24 },
  'CVM': { pe: -6.18 },
  'CWBR': { pe: -1.03 },
  'UAN': { pe: null },
  'WLK': { pe: 4.83 },
  'ALB': { pe: 13.59 },
  'IOSP': { pe: 17.09 },
  'LEU': { pe: 9.41 },
  'CLW': { pe: 12.81 },
  'MERC': { pe: 4.25 },
  'OI': { pe: 6.79 },
  'DWAHY': { pe: 8.7 },
  'MDC': { pe: 3.67 },
  'TH': { pe: 9.95 },
  'TOL': { pe: 4.52 },
  'XIN': { pe: null },
  'CECE': { pe: 16.75 },
  'KBR': { pe: 19.9 },
  'PUODY': { pe: 12.14 },
  'STN': { pe: 27.89 },
  'THR': { pe: 16.72 },
  'CETX': { pe: null },
  'EVBG': { pe: 107.58 },
  'NET': { pe: 2483.67 },
  'NSSC': { pe: 55.83 },
  'OSPN': { pe: -43.93 },
  'PLTR': { pe: 157.17 },
  'PWFL': { pe: -334.0 },
  'VRME': { pe: -0.89 },
  'CPAC': { pe: 18.39 },
  'AXTA': { pe: 15.98 },
  'BLDR': { pe: 4.36 },
  'EFOI': { pe: -0.6 },
  'EXP': { pe: 11.53 },
  'LII': { pe: 18.76 },
  'RPM': { pe: 22.78 },
  'SHW': { pe: 29.04 },
  'STLD': { pe: 3.84 },
  'USNZY': { pe: 3.02 },
  'UUUU': { pe: -41.27 },
  'VALE': { pe: 3.49 },
  'AG': { pe: -768.0 },
  'TS': { pe: 6.64 },
  'USAP': { pe: -122.29 },
  'CENX': { pe: 19.55 },
  'KALU': { pe: 68.3 },
  'CMP': { pe: 69.48 },
  'LODE': { pe: -1.53 },
  'MMX': { pe: 33.73 },
  'MP': { pe: 23.61 },
  'SMTS': { pe: -51.62 },
  'URG': { pe: -17.83 },
  'AAU': { pe: -5.78 },
  'AEM': { pe: 17.82 },
  'CDE': { pe: -20.07 },
  'ELYGF': { pe: null },
  'FNV': { pe: 34.22 },
  'GORO': { pe: 36.0 },
  'NAK': { pe: -7.83 },
  'NEM': { pe: 17.34 },
  'AREC': { pe: -18.23 },
  'CTRA': { pe: 5.97 },
  'KGC': { pe: 11.69 },
  'MTA': { pe: -23.16 },
  'MUX': { pe: -0.34 },
  'NG': { pe: -37.54 },
  'TRX': { pe: null },
  'USAU': { pe: null },
  'BTU': { pe: 2.96 },
  'ATO': { pe: 19.98 },
  'UGI': { pe: 14.26 },
  'XEL': { pe: 24.27 },
  'MGEE': { pe: 28.26 },
  'SJI': { pe: 19.4 },
  'SR': { pe: 19.68 },
  'UTL': { pe: 22.5 },
  'GASNY': { pe: 20.96 },
  'RWEOY': { pe: 22.18 },
  'GRFS': { pe: 66.62 },
  'ORMP': { pe: -8.28 },
  'SNY': { pe: 10.26 },
  'ABCM': { pe: 42.33 },
  'HCC': { pe: 2.45 },
  'PVG': { pe: null },
  'ED': { pe: 22.15 },
  'SWX': { pe: 21.4 },
  'AVA': { pe: 23.26 },
  'MNTK': { pe: 46.31 },
  'OGS': { pe: 20.42 },
  'CTPTY': { pe: 18.79 },
  'AQUA': { pe: 42.57 },
  'CWCO': { pe: 22.37 },
  'ABT': { pe: 21.85 },
  'ATNM': { pe: -9.59 },
  'CPIX': { pe: null },
  'HLUYY': { pe: 22.0 },
  'PRFX': { pe: -1.3 },
  'RDSMY': { pe: 47.92 },
  'TARO': { pe: 11.26 },
  'VRTX': { pe: 21.04 },
  'ABCL': { pe: 24.09 },
  'ACAD': { pe: -13.02 },
  'Z': { pe: 24.56 },
  'ACCD': { pe: -1.72 },
  'RDCM': { pe: 241.2 },
  'RDWR': { pe: 27.25 },
  'CASA': { pe: -9.2 },
  'PIII': { pe: null },
  'RBBN': { pe: 15.04 },
  'VCRA': { pe: null },
  'ATNI': { pe: -187.81 },
  'COMM': { pe: 6.44 },
  'NOK': { pe: 12.05 },
  'TKC': { pe: 4.88 },
  'TWLO': { pe: -125.22 },
  'AVIFY': { pe: 21.88 },
  'BCE': { pe: 24.73 },
  'SKM': { pe: 11.65 },
  'T': { pe: 7.08 },
  'RAMP': { pe: 64.44 },
  'AJRD': { pe: 26.31 },
  'MD': { pe: 10.57 },
  'ADAG': { pe: null },
  'ADPT': { pe: -7.09 },
  'ADTX': { pe: 1.02 },
  'AGEN': { pe: -3.82 },
  'AGIO': { pe: -3.86 },
  'ALBO': { pe: -2.23 },
  'ALGS': { pe: -0.56 },
  'AMWL': { pe: -4.7 },
  'ANIK': { pe: -32.2 },
  'ANNX': { pe: -1.58 },
  'AQB': { pe: -3.94 },
  'ARNA': { pe: null },
  'ATRA': { pe: -2.26 },
  'ATRS': { pe: null },
  'AVRO': { pe: -0.46 },
  'AXSM': { pe: -10.34 },
  'BIOAF': { pe: null },
  'BPMC': { pe: -6.57 },
  'CDMO': { pe: 99.53 },
  'CLDX': { pe: -15.83 },
  'PRGS': { pe: 12.54 },
  'RCAT': { pe: -10.15 },
  'SMAR': { pe: -58.71 },
  'SNPS': { pe: 44.41 },
  'SOS': { pe: null },
  'SSNT': { pe: null },
  'WKME': { pe: -17.76 },
  'IBM': { pe: 14.74 },
  'SMCI': { pe: 8.63 },
  'ANET': { pe: 33.19 },
  'BIGC': { pe: -28.25 },
  'EVER': { pe: -8.44 },
  'WORK': { pe: null },
  'FTNT': { pe: 49.46 },
  'SILC': { pe: 15.51 },
  'VIAV': { pe: 16.32 },
  'MICT': { pe: null },
  'UCL': { pe: -4.59 },
  'UTSI': { pe: null },
  'CCI': { pe: 46.53 },
  'LCI': { pe: null },
  'CNTG': { pe: -1.07 },
  'CRTX': { pe: -1.24 },
  'CTIC': { pe: -6.94 },
  'DBTX': { pe: -2.0 },
  'DTIL': { pe: -1.2 },
  'ETON': { pe: -5.28 },
  'EXAS': { pe: -9.4 },
  'FBIO': { pe: -1.27 },
  'GBIO': { pe: -2.52 },
  'GOVX': { pe: -2.43 },
  'GRTX': { pe: -0.7 },
  'HOWL': { pe: -2.54 },
  'INO': { pe: -1.82 },
  'IVA': { pe: null },
  'KALA': { pe: -0.43 },
  'KMDA': { pe: null },
  'KTRA': { pe: null },
  'KURA': { pe: -7.21 },
  'LJPC': { pe: -77.13 },
  'LYRA': { pe: -3.3 },
  'ABUS': { pe: -4.9 },
  'ADAP': { pe: -3.03 },
  'AIKI': { pe: -1.99 },
  'AKBA': { pe: -0.78 },
  'AKYA': { pe: -7.94 },
  'ALEC': { pe: -8.96 },
  'ANIX': { pe: -7.61 },
  'ANVS': { pe: -3.54 },
  'ARVN': { pe: -9.38 },
  'ATNF': { pe: 102.99 },
  'ATNX': { pe: -0.76 },
  'ATXI': { pe: null },
  'AVEO': { pe: -9.0 },
  'AVIR': { pe: -4.58 },
  'AXLA': { pe: -1.65 },
  'BBI': { pe: -0.02 },
  'BBIO': { pe: -3.29 },
  'BHVN': { pe: -10.6 },
  'BLPH': { pe: -0.62 },
  'CDTX': { pe: -0.99 },
  'SPCE': { pe: -4.55 },
  'CPE': { pe: 2.55 },
  'CRC': { pe: 8.86 },
  'CVE': { pe: 4.83 },
  'GPOR': { pe: 4.45 },
  'GPRK': { pe: 2.74 },
  'MGYOY': { pe: null },
  'VEI': { pe: null },
  'COP': { pe: 6.81 },
  'LUKOY': { pe: null },
  'OGZPY': { pe: null },
  'YPF': { pe: 1.23 },
  'BKR': { pe: 26.33 },
  'DNOW': { pe: 13.66 },
  'NR': { pe: null },
  'SDPI': { pe: 12.71 },
  'CLNE': { pe: -752.0 },
  'DCP': { pe: 8.04 },
  'GIFI': { pe: -4.73 },
  'GPP': { pe: null },
  'AAOI': { pe: -2.57 },
  'CERT': { pe: 37.86 },
  'CHMA': { pe: null },
  'CHRS': { pe: -3.98 },
  'CMPX': { pe: -8.82 },
  'CODX': { pe: 12.87 },
  'CRBP': { pe: -0.69 },
  'CRDF': { pe: -2.56 },
  'CRL': { pe: 20.23 },
  'CRVS': { pe: -1.36 },
  'CYDY': { pe: null },
  'DAWN': { pe: -11.2 },
  'EBS': { pe: 710.75 },
  'EYEN': { pe: -1.92 },
  'FLGT': { pe: 7.65 },
  'GLMD': { pe: -0.61 },
  'GLYC': { pe: -0.8 },
  'GOSS': { pe: -4.89 },
  'GRAY': { pe: -0.57 },
  'GTHX': { pe: -3.62 },
  'HGEN': { pe: -0.3 },
  'HROW': { pe: -22.35 },
  'KDMN': { pe: null },
  'KNTE': { pe: -6.01 },
  'KYMR': { pe: -10.57 },
  'KZR': { pe: -8.85 },
  'LCTX': { pe: -7.74 },
  'LUMO': { pe: -2.24 },
  'LUNG': { pe: -12.99 },
  'MRUS': { pe: -13.26 },
  'MTEM': { pe: -0.54 },
  'NAUT': { pe: -4.43 },
  'NBY': { pe: -3.23 },
  'NEXI': { pe: -0.49 },
  'NKTR': { pe: -2.25 },
  'NMTR': { pe: -1.9 },
  'OLMA': { pe: -1.34 },
  'OMIC': { pe: -2.13 },
  'ONCS': { pe: -0.95 },
  'PHAS': { pe: -0.98 },
  'PHIO': { pe: 2.78 },
  'OOMA': { pe: 33.52 },
  'ORBC': { pe: null },
  'BGAOY': { pe: 9.54 },
  'BRP': { pe: 30.52 },
  'SHEN': { pe: -1184.5 },
  'AIRI': { pe: 14.45 },
  'MSA': { pe: 24.91 },
  'WWD': { pe: 35.79 },
  'MRCY': { pe: 24.51 },
  'PAE': { pe: null },
  'SAIC': { pe: 13.51 },
  'CODA': { pe: 15.53 },
  'CRK': { pe: 5.29 },
  'ESTE': { pe: 2.79 },
  'MNRL': { pe: 10.12 },
  'PXD': { pe: 6.91 },
  'CEO': { pe: null },
  'SNP': { pe: 5.69 },
  'SU': { pe: 5.64 },
  'XOM': { pe: 7.19 },
  'KLXE': { pe: null },
  'LBRT': { pe: 8.32 },
  'HEP': { pe: 9.16 },
  'HESM': { pe: 14.49 },
  'LNG': { pe: 19.34 },
  'MMLP': { pe: null },
  'PBA': { pe: 17.07 },
  'STR': { pe: 11.25 },
  'WES': { pe: 8.85 },
  'WMB': { pe: 20.54 },
  'SVMRF': { pe: 34.83 },
  'UGP': { pe: 11.7 },
  'AKTS': { pe: -5.55 },
  'CYBE': { pe: 26.49 },
  'ENS': { pe: 14.9 },
  'FLEX': { pe: 8.76 },
  'PLXS': { pe: 20.34 },
  'REAL': { pe: -1.98 },
  'SMTOY': { pe: 10.24 },
  'AGCO': { pe: 9.32 },
  'APG': { pe: 13.31 },
  'FANUY': { pe: 27.15 },
  'KEYS': { pe: 22.51 },
  'LGL': { pe: -62.29 },
  'TEL': { pe: 18.58 },
  'HON': { pe: 23.31 },
  'MSI': { pe: 25.36 },
  'ATLKY': { pe: 6.05 },
  'BLD': { pe: 12.83 },
  'CAE': { pe: 40.23 },
  'FLS': { pe: 22.79 },
  'HAYN': { pe: 11.32 },
  'HY': { pe: -11.73 },
  'NPO': { pe: 13.81 },
  'NTIC': { pe: 23.54 },
  'IIVI': { pe: 13.74 },
  'LIQT': { pe: -1.07 },
  'BAK': { pe: 7.1 },
  'EMN': { pe: 10.22 },
  'HWKN': { pe: 16.02 },
  'TDY': { pe: 22.66 },
  'TGI': { pe: 26.14 },
  'CTK': { pe: null },
  'ESE': { pe: 26.93 },
  'GD': { pe: 19.91 },
  'LMT': { pe: 20.35 },
  'AXAS': { pe: null },
  'CHK': { pe: 6.18 },
  'EOG': { pe: 7.28 },
  'EPM': { pe: 4.94 },
  'EPSN': { pe: null },
  'GDP': { pe: null },
  'SBOW': { pe: 3.53 },
  'TALO': { pe: 4.49 },
  'WLL': { pe: 2.63 },
  'GZPFY': { pe: null },
  'MUR': { pe: 5.63 },
  'DPDW': { pe: null },
  'DWSN': { pe: null },
  'FTI': { pe: 52.38 },
  'SND': { pe: -11.35 },
  'DYAI': { pe: -7.79 },
  'EAR': { pe: -1.34 },
  'EVLO': { pe: -1.59 },
  'EXEL': { pe: 20.99 },
  'FATE': { pe: -9.18 },
  'FNCH': { pe: -1.97 },
  'HALO': { pe: 31.23 },
  'HBIO': { pe: 12.76 },
  'HEPA': { pe: -1.44 },
  'HSTO': { pe: -0.41 },
  'HTGM': { pe: -0.44 },
  'ICCC': { pe: null },
  'IDRA': { pe: null },
  'IMPL': { pe: -1.94 },
  'IMRA': { pe: -0.8 },
  'IMUX': { pe: -1.79 },
  'INVO': { pe: -1.28 },
  'LABP': { pe: -0.69 },
  'LTRN': { pe: -3.35 },
  'LXRX': { pe: -5.19 },
  'PTNR': { pe: null },
  'SBAC': { pe: 81.79 },
  'CHT': { pe: 25.59 },
  'EQIX': { pe: 94.25 },
  'DCO': { pe: 17.48 },
  'HXL': { pe: 51.08 },
  'ISSC': { pe: null },
  'LHX': { pe: 17.83 },
  'RADA': { pe: 53.85 },
  'VEC': { pe: 6.79 },
  'CLR': { pe: 5.45 },
  'ERF': { pe: 4.88 },
  'FANG': { pe: 4.8 },
  'FCX': { pe: 10.96 },
  'OVV': { pe: 4.7 },
  'PBR': { pe: 2.66 },
  'RRC': { pe: 6.35 },
  'TPL': { pe: 29.46 },
  'USEG': { pe: null },
  'SHEL': { pe: 4.73 },
  'CMT': { pe: 20.27 },
  'FRTA': { pe: null },
  'FTEK': { pe: -14.78 },
  'FTV': { pe: 21.88 },
  'GGG': { pe: 27.37 },
  'GTLS': { pe: 39.25 },
  'HEES': { pe: 12.52 },
  'HLIO': { pe: 15.1 },
  'NPSKY': { pe: 16.78 },
  'PNR': { pe: 13.56 },
  'SXI': { pe: 15.08 },
  'CGNX': { pe: 45.09 },
  'HOCPY': { pe: 30.6 },
  'PRLB': { pe: 26.19 },
  'ARKAY': { pe: 5.64 },
  'DOW': { pe: 6.93 },
  'LXU': { pe: 5.92 },
  'AIQUY': { pe: 15.75 },
  'LTHM': { pe: 22.39 },
  'YARIY': { pe: 4.28 },
  'USWS': { pe: -0.19 },
  'WHD': { pe: 23.66 },
  'BKEP': { pe: null },
  'CQP': { pe: 13.04 },
  'EQT': { pe: 11.87 },
  'KEYUF': { pe: 14.62 },
  'PAA': { pe: 10.86 },
  'SWN': { pe: 5.29 },
  'TRGP': { pe: 16.13 },
  'ARHVF': { pe: 6.84 },
  'ENSV': { pe: null },
  'SMHI': { pe: null },
  'UFAB': { pe: -1.16 },
  'VTOL': { pe: 18.11 },
  'ATCO': { pe: 9.02 },
  'BELFB': { pe: 8.19 },
  'CAMP': { pe: -47.54 },
  'CLS': { pe: 6.66 },
  'KN': { pe: 10.9 },
  'TYOYY': { pe: 10.4 },
  'EMR': { pe: 16.83 },
  'DRQ': { pe: -59.02 },
  'FET': { pe: null },
  'MRC': { pe: 9.71 },
  'NESR': { pe: null },
  'NINE': { pe: -12.61 },
  'OIS': { pe: -24.2 },
  'CPK': { pe: 26.23 },
  'KMI': { pe: 15.48 },
  'PNT': { pe: -7.84 },
  'TRP': { pe: 19.7 },
  'HP': { pe: 37.71 },
  'NBR': { pe: -4.3 },
  'NE': { pe: 20.08 },
  'OII': { pe: 28.03 },
  'ARW': { pe: 5.06 },
  'GNRC': { pe: 21.09 },
  'HWCC': { pe: null },
  'INVE': { pe: 161.7 },
  'ITRI': { pe: 67.44 },
  'MEI': { pe: 18.05 },
  'RFP': { pe: 3.51 },
  'UFPI': { pe: 8.7 },
  'ATR': { pe: 28.76 },
  'GEF': { pe: 9.05 },
  'UFPT': { pe: 27.36 },
  'VRTV': { pe: 5.96 },
  'BZH': { pe: 2.5 },
  'INVH': { pe: 60.2 },
  'SKY': { pe: 10.09 },
  'SUI': { pe: 80.07 },
  'BNET': { pe: -47.33 },
  'HCCI': { pe: 10.55 },
  'TOMZ': { pe: -13.0 },
  'WM': { pe: 30.7 },
  'AMRC': { pe: 34.92 },
  'IEA': { pe: 17.45 },
  'SBGSY': { pe: null },
  'SSYS': { pe: 118.12 },
  'ALRM': { pe: 38.86 },
  'GCP': { pe: 58.87 },
  'KRA': { pe: null },
  'MTX': { pe: 11.57 },
  'ZY': { pe: -1.05 },
  'CTT': { pe: -138.63 },
  'NP': { pe: null },
  'UFS': { pe: null },
  'BALL': { pe: 18.5 },
  'SEE': { pe: 14.1 },
  'AMH': { pe: 69.0 },
  'HCDI': { pe: -3.21 },
  'LEN': { pe: 5.22 },
  'NWHM': { pe: null },
  'ATCX': { pe: 23.89 },
  'BFLBY': { pe: 13.43 },
  'BWMN': { pe: 73.18 },
  'OEG': { pe: -0.54 },
  'WLDN': { pe: 22.41 },
  'CRWD': { pe: 161.39 },
  'DBD': { pe: -11.44 },
  'HTCKF': { pe: -14.44 },
  'ABB': { pe: 21.92 },
  'AIT': { pe: 16.11 },
  'AMDLY': { pe: null },
  'ASTE': { pe: 28.72 },
  'BMI': { pe: 45.6 },
  'CAT': { pe: 15.56 },
  'CMCO': { pe: 11.82 },
  'CMI': { pe: 12.19 },
  'DKILY': { pe: 29.66 },
  'DOV': { pe: 16.36 },
  'DXPE': { pe: 11.55 },
  'FSS': { pe: 21.55 },
  'GWW': { pe: 20.93 },
  'HRI': { pe: 10.81 },
  'KMT': { pe: 15.25 },
  'MEC': { pe: 7.94 },
  'MEEC': { pe: null },
  'PH': { pe: 16.08 },
  'ASAZY': { pe: 20.04 },
  'DUOT': { pe: -3.85 },
  'POWW': { pe: 14.37 },
  'REKR': { pe: -1.57 },
  'SWBI': { pe: 6.4 },
  'AFI': { pe: null },
  'AUVI': { pe: -2.36 },
  'CNR': { pe: null },
  'CSTE': { pe: 11.14 },
  'GMS': { pe: 6.83 },
  'SPXC': { pe: 22.04 },
  'SSD': { pe: 14.93 },
  'TGLS': { pe: 10.4 },
  'RS': { pe: 6.82 },
  'SIM': { pe: 9.3 },
  'ARNC': { pe: 9.33 },
  'MLI': { pe: 8.66 },
  'REE': { pe: -3.05 },
  'AUMN': { pe: null },
  'GATO': { pe: 11.47 },
  'PI': { pe: 224.12 },
  'ROG': { pe: null },
  'SEKEY': { pe: 8.13 },
  'AIMC': { pe: 12.59 },
  'CNHI': { pe: 9.1 },
  'DDD': { pe: -47.67 },
  'IR': { pe: 22.86 },
  'ITW': { pe: 23.81 },
  'KT': { pe: 7.0 },
  'MIDD': { pe: 16.62 },
  'MNBEY': { pe: 13.33 },
  'MSM': { pe: 13.51 },
  'NDSN': { pe: 25.91 },
  'WAB': { pe: 19.39 },
  'SAFE': { pe: 27.46 },
  'TMQ': { pe: -6.66 },
  'XONE': { pe: null },
  'CE': { pe: 6.3 },
  'DD': { pe: 18.44 },
  'PEAR': { pe: -2.83 },
  'KNBE': { pe: 106.21 },
  'NEOG': { pe: 45.6 },
  'SCWX': { pe: -15.26 },
  'SOMLY': { pe: null },
  'ZS': { pe: 212.62 },
  'BBCP': { pe: 15.64 },
  'SUM': { pe: 22.55 },
  'VMC': { pe: 32.76 },
  'APOG': { pe: 12.55 },
  'ASGLY': { pe: 9.33 },
  'DOOR': { pe: 8.78 },
  'GFF': { pe: 8.04 },
  'IBP': { pe: 12.42 },
  'LITE': { pe: 14.62 },
  'MOD': { pe: 10.05 },
  'WMS': { pe: 24.48 },
  'APEMY': { pe: 3.15 },
  'EAF': { pe: 4.27 },
  'NUE': { pe: 4.65 },
  'PKOH': { pe: 10.21 },
  'TEX': { pe: 9.17 },
  'TKR': { pe: 11.98 },
  'TRN': { pe: 25.79 },
  'ASIX': { pe: 4.99 },
  'CC': { pe: 6.47 },
  'LNDC': { pe: -6.34 },
  'SHI': { pe: 20.32 },
  'SQM': { pe: 8.9 },
  'TREC': { pe: null },
  'BCO': { pe: 10.21 },
  'CCMP': { pe: null },
  'CLMT': { pe: -11.83 },
  'FOE': { pe: null },
  'IP': { pe: 9.61 },
  'OJIPY': { pe: 5.83 },
  'WY': { pe: 11.43 },
  'PKG': { pe: 12.55 },
  'LGIH': { pe: 6.48 },
  'MHO': { pe: 2.79 },
  'RCM': { pe: 30.35 },
  'SCL': { pe: 16.72 },
  'APD': { pe: 25.52 },
  'CDXS': { pe: -16.1 },
  'CSWI': { pe: 25.63 },
  'RYAM': { pe: -8.06 },
  'VNTR': { pe: 6.55 },
  'BCC': { pe: 3.67 },
  'ARD': { pe: null },
  'BERY': { pe: 7.69 },
  'CCK': { pe: 12.93 },
  'PACK': { pe: -13.86 },
  'DHI': { pe: 4.48 },
  'ELS': { pe: 50.66 },
  'CELP': { pe: null },
  'CVA': { pe: null },
  'SBS': { pe: 12.71 },
  'SRCL': { pe: 26.28 },
  'STKL': { pe: 210.8 },
  'MYRG': { pe: 20.22 },
  'GGIFF': { pe: null },
  'GSV': { pe: -13.88 },
  'WRN': { pe: null },
  'ES': { pe: 22.71 },
  'FE': { pe: 16.97 },
  'ENG': { pe: -10.75 },
  'HE': { pe: 19.67 },
  'MIR': { pe: 16.3 },
  'CIG': { pe: 7.56 },
  'EBR': { pe: 20.55 },
  'EDN': { pe: -3.33 },
  'ELP': { pe: 7.2 },
  'IBDRY': { pe: 16.68 },
  'PAM': { pe: 4.94 },
  'ARTNA': { pe: 30.13 },
  'AWR': { pe: 34.84 },
  'ADMP': { pe: -1.94 },
  'OPNT': { pe: -1.68 },
  'ZTS': { pe: 34.35 },
  'ABIO': { pe: null },
  'NVR': { pe: 9.37 },
  'CLH': { pe: 19.48 },
  'RSG': { pe: 30.95 },
  'TMRAY': { pe: 38.26 },
  'MTRN': { pe: 17.36 },
  'ACM': { pe: 22.83 },
  'FLR': { pe: 24.31 },
  'J': { pe: 18.89 },
  'NVEE': { pe: 26.04 },
  'ROAD': { pe: 61.88 },
  'CXW': { pe: 17.68 },
  'DTSS': { pe: null },
  'MCFE': { pe: null },
  'RGR': { pe: 11.04 },
  'SENS': { pe: -14.07 },
  'VSTO': { pe: 3.97 },
  'USCR': { pe: null },
  'TPC': { pe: -6.65 },
  'BECN': { pe: 9.09 },
  'FBHS': { pe: 10.56 },
  'PATK': { pe: 4.69 },
  'PGTI': { pe: 10.98 },
  'AP': { pe: null },
  'CLF': { pe: 3.96 },
  'PKX': { pe: 3.82 },
  'X': { pe: 2.28 },
  'GSM': { pe: 2.41 },
  'PROF': { pe: -4.92 },
  'ASM': { pe: 8.86 },
  'CMCL': { pe: null },
  'HYMC': { pe: -2.68 },
  'KOR': { pe: null },
  'PLG': { pe: null },
  'ARLP': { pe: 4.84 },
  'METC': { pe: 2.16 },
  'SXC': { pe: 7.59 },
  'MDU': { pe: 17.68 },
  'SO': { pe: 21.59 },
  'APPN': { pe: -56.92 },
  'ORA': { pe: 74.43 },
  'LYEL': { pe: -7.6 },
  'MIRM': { pe: -5.91 },
  'MRKR': { pe: -0.74 },
  'NBIX': { pe: 64.78 },
  'NBRV': { pe: -0.27 },
  'NKTX': { pe: -6.55 },
  'NTLA': { pe: -10.09 },
  'OBSV': { pe: -0.42 },
  'OCGN': { pe: -7.22 },
  'OCUL': { pe: -6.35 },
  'OPT': { pe: null },
  'OVID': { pe: -2.34 },
  'PASG': { pe: -0.67 },
  'PSTI': { pe: -0.86 },
  'QBIO': { pe: null },
  'REGN': { pe: 15.4 },
  'RZLT': { pe: -1.69 },
  'SCYX': { pe: -2.02 },
  'SGMO': { pe: -4.46 },
  'SNDX': { pe: -8.94 },
  'OTTR': { pe: 11.73 },
  'KEP': { pe: -0.69 },
  'SPI': { pe: -2.72 },
  'ALPMY': { pe: 19.83 },
  'GALT': { pe: -3.04 },
  'GILD': { pe: 9.93 },
  'LPCN': { pe: -4.21 },
  'MCK': { pe: 15.42 },
  'TXMD': { pe: 1.78 },
  'UCBJF': { pe: null },
  'ACER': { pe: -0.52 },
  'ACET': { pe: -11.3 },
  'ACHL': { pe: -1.65 },
  'ALKS': { pe: 121.95 },
  'ALLK': { pe: -0.57 },
  'AMGN': { pe: 14.34 },
  'ANEB': { pe: null },
  'AQST': { pe: -1.1 },
  'ARCT': { pe: -3.12 },
  'ARDX': { pe: -1.5 },
  'ASRT': { pe: 4.02 },
  'SPPI': { pe: -2.12 },
  'SPRO': { pe: -0.43 },
  'SYNH': { pe: 13.57 },
  'TIL': { pe: -4.11 },
  'TLC': { pe: null },
  'TYME': { pe: null },
  'UBX': { pe: -1.07 },
  'VIR': { pe: 14.01 },
  'VOR': { pe: -2.07 },
  'XBIO': { pe: -1.6 },
  'XCUR': { pe: -0.02 },
  'XNCR': { pe: -20.23 },
  'ACR': { pe: 10.31 },
  'AVAH': { pe: 12.24 },
  'AWH': { pe: -3.17 },
  'EHC': { pe: 15.96 },
  'GDRX': { pe: 24.77 },
  'LH': { pe: 12.5 },
  'USPH': { pe: 27.46 },
  'ABMD': { pe: 61.13 },
  'PCOR': { pe: -87.8 },
  'TRIT': { pe: null },
  'VSEC': { pe: 15.46 },
  'APDN': { pe: -4.42 },
  'BB': { pe: -35.63 },
  'DSS': { pe: -1.23 },
  'ITRN': { pe: 13.84 },
  'NLOK': { pe: 13.22 },
  'TENB': { pe: 212.1 },
  'CMTOY': { pe: 23.53 },
  'GVA': { pe: 21.15 },
  'PWR': { pe: 22.95 },
  'CARR': { pe: 19.02 },
  'JELD': { pe: 7.5 },
  'MHK': { pe: 8.51 },
  'OC': { pe: 7.36 },
  'OTIS': { pe: 25.1 },
  'POLA': { pe: null },
  'POR': { pe: 20.6 },
  'ACOR': { pe: null },
  'ALXN': { pe: null },
  'ALXO': { pe: -4.98 },
  'AMRN': { pe: -4.41 },
  'AMTI': { pe: -0.56 },
  'ANGN': { pe: -0.63 },
  'ANIP': { pe: 26.77 },
  'ATHA': { pe: -1.49 },
  'BCTX': { pe: null },
  'BLI': { pe: -3.32 },
  'BPTH': { pe: -2.19 },
  'BXRX': { pe: -0.12 },
  'CASI': { pe: -1.63 },
  'CBIO': { pe: 2.18 },
  'CCCC': { pe: -4.09 },
  'CGEN': { pe: -2.98 },
  'CLSD': { pe: -2.83 },
  'CNSP': { pe: -0.79 },
  'CPRX': { pe: 19.9 },
  'CRIS': { pe: -1.52 },
  'MBRX': { pe: -1.7 },
  'MEDS': { pe: -3.38 },
  'MEIP': { pe: -0.72 },
  'MOR': { pe: -1.46 },
  'MRTX': { pe: -5.82 },
  'NEO': { pe: -15.52 },
  'NRBO': { pe: null },
  'OLK': { pe: -69.92 },
  'ONCR': { pe: -0.53 },
  'OTLK': { pe: -3.67 },
  'PDSB': { pe: -4.83 },
  'PHGE': { pe: -0.68 },
  'PRAX': { pe: -0.94 },
  'PRPO': { pe: -3.97 },
  'PTGX': { pe: -3.37 },
  'QURE': { pe: -5.13 },
  'RDUS': { pe: -35.13 },
  'RGNX': { pe: -5.56 },
  'RUBY': { pe: -0.37 },
  'SMMT': { pe: null },
  'FIX': { pe: 22.5 },
  'FUL': { pe: 16.5 },
  'HUBB': { pe: 22.57 },
  'TILE': { pe: 10.35 },
  'WSO': { pe: 21.01 },
  'GGB': { pe: 3.38 },
  'SID': { pe: 4.12 },
  'WOR': { pe: 9.7 },
  'ARREF': { pe: 12.99 },
  'TREVF': { pe: -0.26 },
  'CCJ': { pe: 116.62 },
  'NRP': { pe: null },
  'SLCA': { pe: 14.53 },
  'AUY': { pe: 15.71 },
  'FSM': { pe: 12.65 },
  'IAG': { pe: 44.33 },
  'SA': { pe: 447.33 },
  'VGZ': { pe: null },
  'CHRA': { pe: -4.55 },
  'AEE': { pe: 23.67 },
  'SQZ': { pe: -1.1 },
  'SRPT': { pe: -17.55 },
  'STEM': { pe: -23.56 },
  'TCON': { pe: -1.74 },
  'TVTX': { pe: -6.77 },
  'TWST': { pe: -10.21 },
  'TXG': { pe: -25.23 },
  'VCEL': { pe: -165.78 },
  'VECT': { pe: -3.07 },
  'VERA': { pe: -7.18 },
  'VRPX': { pe: -1.07 },
  'YMAB': { pe: -6.86 },
  'AMEH': { pe: 38.42 },
  'BKD': { pe: -3.09 },
  'CHEK': { pe: -1.9 },
  'CNCE': { pe: -2.31 },
  'NHWK': { pe: -1.84 },
  'OSW': { pe: 77.08 },
  'PGNY': { pe: 256.71 },
  'PRG': { pe: 8.08 },
  'DTE': { pe: 22.5 },
  'DUK': { pe: 20.41 },
  'IDA': { pe: 23.29 },
  'NEE': { pe: 31.21 },
  'NFE': { pe: 38.03 },
  'OGE': { pe: 14.06 },
  'PNW': { pe: 19.11 },
  'ALE': { pe: 16.7 },
  'PNM': { pe: 18.43 },
  'AWK': { pe: 35.64 },
  'SJW': { pe: 29.36 },
  'AMPE': { pe: -1.15 },
  'AVDL': { pe: -3.39 },
  'DFFN': { pe: -0.84 },
  'ESALY': { pe: 60.05 },
  'HRMY': { pe: 28.38 },
  'LLY': { pe: 39.9 },
  'NVO': { pe: 31.66 },
  'NVS': { pe: 14.06 },
  'RDY': { pe: 19.33 },
  'PPG': { pe: 19.88 },
  'CRS': { pe: 250.4 },
  'TMST': { pe: 4.64 },
  'ACH': { pe: 10.17 },
  'AUR': { pe: -1.97 },
  'HBM': { pe: 14.16 },
  'UEC': { pe: 0.0 },
  'AGI': { pe: 23.97 },
  'EDVMF': { pe: 11.73 },
  'SVM': { pe: 13.11 },
  'CEIX': { pe: 7.49 },
  'AGR': { pe: 21.73 },
  'CMS': { pe: 24.12 },
  'CNP': { pe: 23.39 },
  'NI': { pe: 21.86 },
  'NRG': { pe: 4.26 },
  'PCG': { pe: 11.07 },
  'PEG': { pe: 19.83 },
  'PPL': { pe: 21.87 },
  'WEC': { pe: 24.31 },
  'THC': { pe: 9.65 },
  'AXNX': { pe: -39.02 },
  'CMRX': { pe: 1.54 },
  'CUTR': { pe: -13.04 },
  'DRIO': { pe: -1.85 },
  'GBT': { pe: -14.26 },
  'HSIC': { pe: 15.41 },
  'LHDX': { pe: -11.0 },
  'MBOT': { pe: -2.78 },
  'MDXG': { pe: -15.56 },
  'RBOT': { pe: -7.03 },
  'RMED': { pe: -0.2 },
  'SRGA': { pe: -0.54 },
  'SRTS': { pe: 8.27 },
  'APR': { pe: null },
  'MSON': { pe: null },
  'STXS': { pe: -10.0 },
  'CELH': { pe: 197.96 },
  'REED': { pe: -1.41 },
  'TAP': { pe: 14.13 },
  'VTRS': { pe: 3.13 },
  'ZGNX': { pe: null },
  'ACHV': { pe: -1.28 },
  'ADIL': { pe: -0.89 },
  'ADMS': { pe: null },
  'AIM': { pe: -2.07 },
  'AKRO': { pe: -3.89 },
  'ALVR': { pe: -3.14 },
  'APTX': { pe: -0.34 },
  'ARDS': { pe: -1.73 },
  'ARWR': { pe: -39.21 },
  'ATHX': { pe: -0.69 },
  'AVXL': { pe: -17.02 },
  'AYLA': { pe: -0.61 },
  'BMEA': { pe: -4.31 },
  'BMRN': { pe: 128.16 },
  'CARA': { pe: -8.9 },
  'CLBS': { pe: -1.35 },
  'CLRB': { pe: -0.13 },
  'CMPS': { pe: -7.31 },
  'PLX': { pe: -2.62 },
  'PMVP': { pe: -9.13 },
  'PNTG': { pe: 25.82 },
  'PRTA': { pe: -9.7 },
  'QLGN': { pe: -0.82 },
  'RAIN': { pe: -2.46 },
  'RCUS': { pe: -6.53 },
  'RLAY': { pe: -7.27 },
  'RLMD': { pe: -5.17 },
  'RVNC': { pe: -7.45 },
  'RXDX': { pe: -13.93 },
  'RXRX': { pe: -8.29 },
  'SAVA': { pe: -13.61 },
  'SGTX': { pe: -0.39 },
  'SHC': { pe: 20.31 },
  'SUPN': { pe: 18.41 },
  'SYRS': { pe: -0.62 },
  'TBIO': { pe: null },
  'TCRR': { pe: -1.18 },
  'TECH': { pe: 45.01 },
  'BYND': { pe: -6.61 },
  'CHX': { pe: 17.88 },
  'OTLY': { pe: -7.85 },
  'SJM': { pe: 17.42 },
  'CLXT': { pe: -0.6 },
  'CTVA': { pe: 24.07 },
  'SANW': { pe: -1.94 },
  'CM': { pe: 11.98 },
  'SPTN': { pe: 13.75 },
  'DRI': { pe: 18.43 },
  'ELMS': { pe: null },
  'JACK': { pe: 15.46 },
  'SBUX': { pe: 30.36 },
  'SHAK': { pe: -133.33 },
  'WTRH': { pe: -0.56 },
  'CHD': { pe: 29.22 },
  'CLVR': { pe: -0.99 },
  'CRWS': { pe: null },
  'REV': { pe: null },
  'SBH': { pe: 7.25 },
  'NWPX': { pe: 10.36 },
  'ROCK': { pe: 14.47 },
  'SCHN': { pe: 5.32 },
  'TX': { pe: 2.84 },
  'FQVLF': { pe: 10.16 },
  'SCCO': { pe: 16.27 },
  'IMII': { pe: null },
  'IPI': { pe: 5.25 },
  'IVPAF': { pe: 15.13 },
  'JIXAY': { pe: 7.06 },
  'NEXA': { pe: 4.95 },
  'NTR': { pe: 5.46 },
  'AU': { pe: 7.83 },
  'BVN': { pe: 2.07 },
  'NTGSF': { pe: null },
  'RGLD': { pe: 28.38 },
  'AMR': { pe: 1.6 },
  'CNX': { pe: 5.99 },
  'AEP': { pe: 20.95 },
  'AES': { pe: 15.83 },
  'CUE': { pe: -2.09 },
  'CYCC': { pe: -1.03 },
  'CYTH': { pe: -1.71 },
  'DARE': { pe: -3.5 },
  'EDIT': { pe: -5.01 },
  'EQ': { pe: -1.32 },
  'FMTX': { pe: -2.71 },
  'FREQ': { pe: -0.87 },
  'GERN': { pe: -5.66 },
  'GLSI': { pe: -13.81 },
  'GMDA': { pe: -2.69 },
  'GNCA': { pe: null },
  'HZNP': { pe: 13.22 },
  'ICLR': { pe: 20.51 },
  'IGMS': { pe: -4.25 },
  'IKT': { pe: -1.11 },
  'INGN': { pe: -20.0 },
  'ISR': { pe: -10.82 },
  'JAZZ': { pe: 9.11 },
  'JNCE': { pe: -1.86 },
  'BWEN': { pe: -6.88 },
  'ORN': { pe: -13.4 },
  'DIPGY': { pe: null },
  'CWT': { pe: 36.09 },
  'ENDP': { pe: 0.23 },
  'PCSA': { pe: -3.17 },
  'STSA': { pe: -3.05 },
  'TTNP': { pe: -2.29 },
  'ADMA': { pe: -7.21 },
  'ALDX': { pe: -5.42 },
  'ALIM': { pe: -3.48 },
  'ALLO': { pe: -6.43 },
  'ALNY': { pe: -29.05 },
  'AMRX': { pe: 3.39 },
  'ARQT': { pe: -4.63 },
  'ARTL': { pe: -0.07 },
  'AUTL': { pe: -2.03 },
  'BCDA': { pe: -2.29 },
  'BCLI': { pe: -5.66 },
  'BDTX': { pe: -1.45 },
  'TELA': { pe: -2.92 },
  'TFFP': { pe: -4.09 },
  'TLIS': { pe: -0.27 },
  'VIRI': { pe: -3.1 },
  'VIVO': { pe: 26.98 },
  'VNDA': { pe: 44.83 },
  'XERS': { pe: -2.34 },
  'XLRN': { pe: null },
  'ZNTL': { pe: -5.21 },
  'ZYNE': { pe: -1.47 },
  'AHG': { pe: null },
  'HRC': { pe: null },
  'LFST': { pe: -12.02 },
  'LHCG': { pe: 32.42 },
  'ONEM': { pe: -9.12 },
  'OPTN': { pe: -4.73 },
  'TDOC': { pe: -0.58 },
  'THTX': { pe: -3.52 },
  'TLMD': { pe: null },
  'WELL': { pe: 104.58 },
  'DNLI': { pe: -13.23 },
  'ENLV': { pe: -9.79 },
  'ENTX': { pe: -2.83 },
  'ETNB': { pe: -1.08 },
  'FIXX': { pe: 27.8 },
  'FOLD': { pe: -13.52 },
  'GNPX': { pe: -4.84 },
  'HARP': { pe: -0.94 },
  'HOTH': { pe: -1.05 },
  'ICPT': { pe: -7.8 },
  'IKNA': { pe: -2.95 },
  'IMGN': { pe: -6.8 },
  'INCY': { pe: 36.38 },
  'INMB': { pe: -4.32 },
  'IONS': { pe: -16.68 },
  'IOVA': { pe: -5.12 },
  'KALV': { pe: -3.71 },
  'KIN': { pe: null },
  'KPTI': { pe: -2.48 },
  'KRON': { pe: -1.72 },
  'YCBD': { pe: null },
  'LPL': { pe: -7.36 },
  'FUJIY': { pe: 14.9 },
  'NINOY': { pe: 14.6 },
  'GAME': { pe: -0.94 },
  'HLF': { pe: 8.65 },
  'MTN': { pe: 27.73 },
  'RIDE': { pe: -2.82 },
  'FTDR': { pe: 22.71 },
  'RGS': { pe: null },
  'WTW': { pe: 16.16 },
  'BILL': { pe: -398.37 },
  'CSGS': { pe: 16.37 },
  'GIB': { pe: 17.82 },
  'MDRX': { pe: 21.32 },
  'TLS': { pe: 48.3 },
  'AMN': { pe: 9.56 },
  'HSON': { pe: 8.41 },
  'FC': { pe: 45.11 },
  'METX': { pe: -0.32 },
  'KOD': { pe: -1.66 },
  'KRYS': { pe: -14.0 },
  'LQDA': { pe: -7.74 },
  'MATX': { pe: 2.65 },
  'MBIO': { pe: -0.9 },
  'MGNX': { pe: -1.62 },
  'MNPR': { pe: -2.21 },
  'MORF': { pe: -16.63 },
  'NGM': { pe: -7.56 },
  'NVAX': { pe: 6.39 },
  'OMER': { pe: -3.08 },
  'ORGS': { pe: -4.45 },
  'PHAT': { pe: -2.41 },
  'POAI': { pe: null },
  'PSTV': { pe: -0.68 },
  'QGEN': { pe: 21.64 },
  'RKDA': { pe: -1.2 },
  'RNA': { pe: -6.26 },
  'RVMD': { pe: -7.47 },
  'RVPH': { pe: -0.56 },
  'BKH': { pe: 19.16 },
  'EIX': { pe: 15.84 },
  'EXC': { pe: 20.56 },
  'NFG': { pe: 11.42 },
  'SRE': { pe: 19.49 },
  'CWEN': { pe: 7.43 },
  'RGCO': { pe: 19.58 },
  'OEZVY': { pe: null },
  'CDZI': { pe: -9.57 },
  'MSEX': { pe: 36.6 },
  'ABBV': { pe: 10.19 },
  'AZN': { pe: 19.95 },
  'NOVN': { pe: -1.55 },
  'OPHLY': { pe: 14.84 },
  'PFE': { pe: 7.55 },
  'ABEO': { pe: -0.03 },
  'ACRS': { pe: -11.44 },
  'ACRX': { pe: 0.99 },
  'ADCT': { pe: -4.22 },
  'ADVM': { pe: -0.91 },
  'ATRC': { pe: -46.38 },
  'CAH': { pe: 14.19 },
  'DYNT': { pe: -2.09 },
  'ECOR': { pe: -2.03 },
  'EKSO': { pe: -2.31 },
  'ICUI': { pe: 24.75 },
  'MLAB': { pe: 31.21 },
  'MMSI': { pe: 25.0 },
  'MYO': { pe: -1.17 },
  'NVIV': { pe: null },
  'OCDX': { pe: 19.81 },
  'OFIX': { pe: 52.98 },
  'OPK': { pe: -6.68 },
  'PSTX': { pe: -1.88 },
  'QDEL': { pe: 5.34 },
  'QTNT': { pe: -0.22 },
  'STE': { pe: 25.68 },
  'XENT': { pe: -21.89 },
  'CTSO': { pe: -2.5 },
  'IRIX': { pe: -5.49 },
  'UTI': { pe: 26.96 },
  'BYDDY': { pe: -155.89 },
  'F': { pe: 7.63 },
  'FUV': { pe: -1.93 },
  'OSK': { pe: 24.71 },
  'SHYF': { pe: 24.93 },
  'ALSN': { pe: 7.68 },
  'AXL': { pe: 11.75 },
  'BWA': { pe: 8.99 },
  'GTES': { pe: 9.98 },
  'HZN': { pe: null },
  'LEA': { pe: 16.84 },
  'LKQ': { pe: 14.21 },
  'MPAA': { pe: 9.23 },
  'PSIX': { pe: 19.0 },
  'VNE': { pe: null },
  'BOMN': { pe: 171.73 },
  'CREX': { pe: -9.08 },
  'DSP': { pe: -11.82 },
  'HUBS': { pe: 160.29 },
  'ACU': { pe: 12.31 },
  'ARTH': { pe: null },
  'ATOS': { pe: -5.25 },
  'AXGN': { pe: -22.12 },
  'BAX': { pe: 16.82 },
  'BTCY': { pe: -2.82 },
  'ESTA': { pe: -25.25 },
  'LIVN': { pe: 27.06 },
  'OMCL': { pe: 28.22 },
  'RWLK': { pe: -4.54 },
  'SNWV': { pe: null },
  'VNRX': { pe: -3.28 },
  'AHCO': { pe: 18.26 },
  'NVCR': { pe: -101.54 },
  'PDCO': { pe: 12.85 },
  'ABEV': { pe: 18.63 },
  'BUD': { pe: 17.69 },
  'PRMW': { pe: 20.55 },
  'NISUY': { pe: null },
  'SYIEY': { pe: 37.82 },
  'SANA': { pe: -4.23 },
  'SIOX': { pe: null },
  'SRRA': { pe: null },
  'STOK': { pe: -7.09 },
  'SWTX': { pe: -5.36 },
  'TCDA': { pe: -5.66 },
  'TENX': { pe: -0.67 },
  'TERN': { pe: -2.01 },
  'TGEN': { pe: -21.0 },
  'TSHA': { pe: -1.01 },
  'UTHR': { pe: 13.97 },
  'VBLT': { pe: -0.5 },
  'VERU': { pe: -22.65 },
  'VKTX': { pe: -4.05 },
  'VSTM': { pe: -2.95 },
  'XBIT': { pe: null },
  'XGN': { pe: -1.76 },
  'BLFS': { pe: -10.38 },
  'DVA': { pe: 11.46 },
  'INMD': { pe: 15.84 },
  'BIIB': { pe: 13.18 },
  'BIOC': { pe: -1.38 },
  'BLCM': { pe: -0.83 },
  'CBAY': { pe: -3.23 },
  'CCXI': { pe: -28.85 },
  'CDAK': { pe: -1.32 },
  'CLVS': { pe: -0.82 },
  'CMPI': { pe: -3.56 },
  'COCP': { pe: -1.15 },
  'CYCN': { pe: -0.7 },
  'CYTK': { pe: -14.13 },
  'DCPH': { pe: -6.09 },
  'EIGR': { pe: -4.57 },
  'EWTX': { pe: -7.58 },
  'FBRX': { pe: -1.93 },
  'FLXN': { pe: null },
  'FRLN': { pe: -0.4 },
  'FULC': { pe: -2.66 },
  'GANX': { pe: -2.69 },
  'GH': { pe: -8.23 },
  'KROS': { pe: -8.47 },
  'KRTX': { pe: -31.1 },
  'LPTX': { pe: -2.93 },
  'LRMR': { pe: -1.14 },
  'MDGL': { pe: -4.82 },
  'MIST': { pe: -4.28 },
  'MRNA': { pe: 5.9 },
  'MRNS': { pe: -1.75 },
  'MRVI': { pe: 14.3 },
  'NDRA': { pe: -1.33 },
  'NRIX': { pe: -4.06 },
  'NVTA': { pe: -1.57 },
  'OCX': { pe: -2.19 },
  'ONCT': { pe: -1.4 },
  'PCRX': { pe: 18.4 },
  'PIRS': { pe: -3.11 },
  'PLRX': { pe: -6.27 },
  'PLXP': { pe: -0.65 },
  'PRTK': { pe: -3.13 },
  'PSNL': { pe: -1.82 },
  'MYSZ': { pe: null },
  'YEXT': { pe: -40.58 },
  'ATEN': { pe: 23.17 },
  'DISCK': { pe: -106.17 },
  'FOX': { pe: 10.32 },
  'SIRI': { pe: 21.03 },
  'TGNA': { pe: 6.94 },
  'HYPR': { pe: -1.18 },
  'SBSNF': { pe: 60.52 },
  'APP': { pe: 754.5 },
  'ZNGA': { pe: 26.39 },
  'BURL': { pe: 29.54 },
  'CPNG': { pe: -80.95 },
  'DLA': { pe: 5.43 },
  'GPS': { pe: -184.33 },
  'SFIX': { pe: -3.73 },
  'UAA': { pe: 27.64 },
  'URBN': { pe: 10.84 },
  'CONN': { pe: 18.39 },
  'CAL': { pe: 6.89 },
  'LIFE': { pe: -1.95 },
  'MNR': { pe: null },
  'NTRA': { pe: -9.06 },
  'APEN': { pe: -6.68 },
  'APYX': { pe: -13.32 },
  'ATEC': { pe: -8.12 },
  'BSX': { pe: 24.11 },
  'CDNA': { pe: -14.07 },
  'GKOS': { pe: -22.61 },
  'GMED': { pe: 31.68 },
  'INFU': { pe: 114.29 },
  'KRMD': { pe: -11.9 },
  'MASI': { pe: 35.45 },
  'MDT': { pe: 17.64 },
  'NOVT': { pe: 51.79 },
  'OMI': { pe: 11.26 },
  'PDEX': { pe: 18.45 },
  'SIEN': { pe: -0.99 },
  'SOLY': { pe: null },
  'SSKN': { pe: -5.0 },
  'AKUS': { pe: -1.14 },
  'ALNA': { pe: -0.41 },
  'APLS': { pe: -11.7 },
  'APRE': { pe: -0.18 },
  'ARAV': { pe: -0.36 },
  'ASMB': { pe: -1.13 },
  'AYTU': { pe: -0.11 },
  'AZYO': { pe: -3.15 },
  'BDSI': { pe: null },
  'BLUE': { pe: -1.45 },
  'BTAI': { pe: -2.88 },
  'CERS': { pe: -18.77 },
  'CGEM': { pe: 7.75 },
  'CLSN': { pe: -0.53 },
  'COGT': { pe: -6.41 },
  'COLL': { pe: 3.55 },
  'CRMD': { pe: -4.73 },
  'CTXR': { pe: null },
  'DMAC': { pe: -2.77 },
  'DRNA': { pe: null },
  'SONO': { pe: 22.29 },
  'AEBZY': { pe: null },
  'MGPI': { pe: 24.48 },
  'BGS': { pe: 21.35 },
  'BRBR': { pe: 22.27 },
  'FDP': { pe: 26.25 },
  'HSY': { pe: 28.11 },
  'JBSS': { pe: null },
  'LANC': { pe: 38.72 },
  'LSF': { pe: -0.77 },
  'THS': { pe: 35.15 },
  'TTCF': { pe: -8.91 },
  'USFD': { pe: 14.78 },
  'ADM': { pe: 12.64 },
  'BG': { pe: 7.88 },
  'PPC': { pe: 7.04 },
  'HYFM': { pe: -4.57 },
  'GO': { pe: 40.44 },
  'BBQ': { pe: 14.76 },
  'TXRH': { pe: 24.54 },
  'CROX': { pe: 7.59 },
  'DBI': { pe: 9.8 },
  'SHOO': { pe: 11.41 },
  'CVS': { pe: 12.27 },
  'RAD': { pe: -3.74 },
  'BNED': { pe: -13.95 },
  'AZO': { pe: 19.61 },
  'MNRO': { pe: 34.79 },
  'AVY': { pe: 20.26 },
  'TRUP': { pe: -73.68 },
  'LOW': { pe: 15.99 },
  'SMG': { pe: 20.13 },
  'CF': { pe: 5.83 },
  'MBUU': { pe: 8.32 },
  'THO': { pe: 6.33 },
  'YAMCY': { pe: 23.62 },
  'PBI': { pe: 18.58 },
  'SCSC': { pe: 8.2 },
  'ALK': { pe: 10.48 },
  'DAL': { pe: 12.61 },
  'ALCO': { pe: null },
  'DAR': { pe: 14.47 },
  'IBA': { pe: 7.78 },
  'SNES': { pe: -0.67 },
  'TSN': { pe: 10.29 },
  'PFGC': { pe: 17.49 },
  'ARCO': { pe: 15.91 },
  'FAT': { pe: -3.36 },
  'STKS': { pe: 12.35 },
  'YUMC': { pe: 47.65 },
  'ENR': { pe: 10.27 },
  'EOLS': { pe: -9.96 },
  'JNJ': { pe: 16.67 },
  'KERN': { pe: -0.09 },
  'KMB': { pe: 24.17 },
  'VLTA': { pe: -2.71 },
  'HBI': { pe: 9.08 },
  'GPRO': { pe: 8.56 },
  'NEPT': { pe: -0.01 },
  'TCMD': { pe: -24.54 },
  'WST': { pe: 35.84 },
  'XTNT': { pe: null },
  'ARAY': { pe: -32.44 },
  'BEAM': { pe: -12.89 },
  'BIOR': { pe: -3.37 },
  'IRMD': { pe: 36.71 },
  'IVC': { pe: -0.74 },
  'XAIR': { pe: -5.45 },
  'KNBWY': { pe: 15.37 },
  'KO': { pe: 26.37 },
  'PEP': { pe: 27.02 },
  'STZ': { pe: 22.93 },
  'BBAR': { pe: 2.9 },
  'DANOY': { pe: null },
  'DIN': { pe: 13.12 },
  'HRL': { pe: 26.47 },
  'SAFM': { pe: 5.23 },
  'UTZ': { pe: 34.8 },
  'AVO': { pe: 24.8 },
  'HOOK': { pe: -1.27 },
  'IART': { pe: 16.68 },
  'IDXG': { pe: -1.14 },
  'INAB': { pe: -1.56 },
  'ITCI': { pe: -15.99 },
  'ITRM': { pe: -1.06 },
  'JANX': { pe: -8.07 },
  'KNSA': { pe: -15.76 },
  'LGVN': { pe: -7.17 },
  'LMNX': { pe: null },
  'LOGC': { pe: -0.47 },
  'MCRB': { pe: -2.45 },
  'MGTX': { pe: -3.77 },
  'MRSN': { pe: -3.39 },
  'MTNB': { pe: -7.12 },
  'NBSE': { pe: -0.68 },
  'OCUP': { pe: -2.13 },
  'OGEN': { pe: -2.11 },
  'OPGN': { pe: -1.01 },
  'ORPH': { pe: null },
  'BCEL': { pe: -0.81 },
  'BCRX': { pe: -11.31 },
  'BIVI': { pe: -2.83 },
  'BLRX': { pe: -2.74 },
  'BNGO': { pe: -7.74 },
  'BNTC': { pe: -0.68 },
  'CORT': { pe: 29.36 },
  'CSBR': { pe: 85.64 },
  'CSTL': { pe: -11.59 },
  'DRRX': { pe: -4.58 },
  'DVAX': { pe: 7.29 },
  'ELOX': { pe: -0.85 },
  'ENTA': { pe: -12.22 },
  'ESPR': { pe: -1.84 },
  'ETTX': { pe: null },
  'EVFM': { pe: -0.06 },
  'EYPT': { pe: -4.36 },
  'FDMT': { pe: -2.6 },
  'FENC': { pe: -10.73 },
  'FGEN': { pe: -4.61 },
  'PTIX': { pe: null },
  'PULM': { pe: -0.79 },
  'RARE': { pe: -6.2 },
  'RDHL': { pe: -2.97 },
  'RPHM': { pe: -1.42 },
  'SELB': { pe: -59.75 },
  'SLGL': { pe: -14.68 },
  'SLNO': { pe: -0.69 },
  'SPRB': { pe: -1.01 },
  'SRRK': { pe: -3.74 },
  'TALS': { pe: -2.27 },
  'TPTX': { pe: -9.44 },
  'TRVI': { pe: -5.48 },
  'TRVN': { pe: -0.68 },
  'VBIV': { pe: -2.08 },
  'VRCA': { pe: -3.35 },
  'WVE': { pe: -1.58 },
  'XFOR': { pe: -0.57 },
  'ACHC': { pe: 26.37 },
  'AMED': { pe: 22.24 },
  'PAC': { pe: 18.66 },
  'RENO': { pe: null },
  'SNCY': { pe: 53.64 },
  'RPD': { pe: 411.81 },
  'CLDT': { pe: -104.31 },
  'HOFV': { pe: -3.66 },
  'IHG': { pe: 22.43 },
  'SHO': { pe: 35.65 },
  'VAC': { pe: 15.24 },
  'RAIL': { pe: -8.08 },
  'WJRYY': { pe: 28.81 },
  'USX': { pe: 281.0 },
  'ABNB': { pe: 56.68 },
  'LTRPA': { pe: null },
  'VVI': { pe: 87.67 },
  'ESEA': { pe: 1.5 },
  'PSHG': { pe: null },
  'ACVA': { pe: -18.78 },
  'AN': { pe: 5.16 },
  'BYD': { pe: 10.22 },
  'RICK': { pe: 12.88 },
  'WYNN': { pe: -18.43 },
  'ELAN': { pe: 16.54 },
  'JBSAY': { pe: 4.71 },
  'ACI': { pe: 9.4 },
  'ASAI': { pe: 21.9 },
  'BFI': { pe: -0.84 },
  'BJRI': { pe: 936.67 },
  'DENN': { pe: 19.4 },
  'OLO': { pe: 188.5 },
  'RRGB': { pe: -3.88 },
  'SMFR': { pe: -1.44 },
  'TACO': { pe: null },
  'BMRA': { pe: null },
  'CL': { pe: 27.21 },
  'IFF': { pe: 21.41 },
  'CRCT': { pe: 20.08 },
  'WISA': { pe: -0.73 },
  'GHSI': { pe: -0.93 },
  'NATR': { pe: 25.85 },
  'GAIA': { pe: 111.33 },
  'DSGN': { pe: -21.15 },
  'DYN': { pe: -3.3 },
  'EDSA': { pe: null },
  'EGRX': { pe: 4.48 },
  'EPZM': { pe: -0.45 },
  'EVAX': { pe: -2.81 },
  'EVOK': { pe: -0.97 },
  'EVTC': { pe: 13.41 },
  'GLTO': { pe: -0.8 },
  'GRCL': { pe: -7.83 },
  'HRTX': { pe: -2.63 },
  'IBIO': { pe: -1.22 },
  'IDYA': { pe: -4.96 },
  'IMNM': { pe: -1.64 },
  'INBX': { pe: -6.12 },
  'IRWD': { pe: 10.01 },
  'ISEE': { pe: -8.64 },
  'KLDO': { pe: null },
  'KRBP': { pe: -0.22 },
  'LBPH': { pe: -1.79 },
  'SSDOY': { pe: 60.72 },
  'UEIC': { pe: 8.38 },
  'VOXX': { pe: 39.44 },
  'NTDOY': { pe: 14.08 },
  'PRPH': { pe: 9.22 },
  'DS': { pe: -2.16 },
  'MSGS': { pe: 194.17 },
  'LTCH': { pe: -0.86 },
  'PM': { pe: 17.72 },
  'TPB': { pe: 11.45 },
  'FORR': { pe: 18.84 },
  'HCKT': { pe: 15.13 },
  'IRM': { pe: 31.57 },
  'RSSS': { pe: -52.0 },
  'SRT': { pe: 9.64 },
  'CDAY': { pe: 118.86 },
  'CNDT': { pe: 13.39 },
  'CTSH': { pe: 15.14 },
  'NXGN': { pe: 20.23 },
  'ASO': { pe: 6.84 },
  'GENI': { pe: -11.55 },
  'ROL': { pe: 51.44 },
  'SKIN': { pe: 1333.0 },
  'STMP': { pe: null },
  'TRI': { pe: 47.41 },
  'CACI': { pe: 15.87 },
  'MMS': { pe: 23.29 },
  'MTBC': { pe: -6.3 },
  'PAYC': { pe: 68.18 },
  'MATW': { pe: null },
  'DSEY': { pe: 18.92 },
  'TNC': { pe: 16.04 },
  'BGSF': { pe: 10.74 },
  'DLHC': { pe: null },
  'IS': { pe: 44.6 },
  'RANJY': { pe: 9.98 },
  'PRDO': { pe: null },
  'STRA': { pe: 24.74 },
  'HINOY': { pe: -3.96 },
  'GOLF': { pe: 19.22 },
  'NLS': { pe: -1.0 },
  'SWM': { pe: null },
  'ATEX': { pe: -20.04 },
  'FCN': { pe: 25.33 },
  'IT': { pe: 34.28 },
  'MARA': { pe: -7.15 },
  'CCRD': { pe: 13.34 },
  'CERN': { pe: null },
  'PCYG': { pe: 21.92 },
  'TEAM': { pe: 181.06 },
  'TNET': { pe: 15.07 },
  'TYL': { pe: 54.07 },
  'ARMK': { pe: 26.02 },
  'AIRG': { pe: 102.25 },
  'KFRC': { pe: 13.66 },
  'MAN': { pe: 9.41 },
  'UPWK': { pe: -123.67 },
  'ZIP': { pe: 56.81 },
  'BOXL': { pe: 12.21 },
  'OSUR': { pe: -6.83 },
  'OTIC': { pe: -0.64 },
  'PCVX': { pe: -8.16 },
  'PGEN': { pe: -4.3 },
  'PRLD': { pe: -3.21 },
  'PTCT': { pe: -8.23 },
  'RCKT': { pe: -5.82 },
  'REPL': { pe: -7.04 },
  'RIGL': { pe: -3.12 },
  'RYTM': { pe: -6.29 },
  'SBBP': { pe: null },
  'SBTX': { pe: -2.96 },
  'SEEL': { pe: -1.5 },
  'SESN': { pe: -2.08 },
  'SONN': { pe: -0.54 },
  'SRNE': { pe: -2.26 },
  'STRO': { pe: -2.21 },
  'TARA': { pe: -1.04 },
  'TGTX': { pe: -4.86 },
  'THMO': { pe: -0.68 },
  'IMAC': { pe: -2.73 },
  'MOH': { pe: 18.7 },
  'RDNT': { pe: 48.41 },
  'SEM': { pe: 14.01 },
  'SGRY': { pe: 153.3 },
  'SHCR': { pe: -9.26 },
  'SP': { pe: 12.23 },
  'SSMXY': { pe: 50.06 },
  'ALGN': { pe: 30.92 },
  'BDX': { pe: 22.88 },
  'BSGM': { pe: -1.36 },
  'IRTC': { pe: -38.75 },
  'LNTH': { pe: 23.08 },
  'PEN': { pe: 509.7 },
  'SPNE': { pe: -5.06 },
  'SRDX': { pe: -36.27 },
  'STAA': { pe: 90.66 },
  'VCYT': { pe: -27.88 },
  'COHR': { pe: 30.16 },
  'HOLX': { pe: 16.16 },
  'FHTX': { pe: -6.47 },
  'FSTX': { pe: -2.36 },
  'FUSN': { pe: -1.08 },
  'HCM': { pe: -4.21 },
  'ILMN': { pe: 75.01 },
  'IMVT': { pe: -3.22 },
  'INFI': { pe: -2.87 },
  'INSM': { pe: -7.58 },
  'INVA': { pe: 7.33 },
  'INZY': { pe: -1.76 },
  'ITOS': { pe: 9.93 },
  'KDNY': { pe: -8.82 },
  'LGND': { pe: 38.7 },
  'MASS': { pe: -18.32 },
  'MGTA': { pe: -1.3 },
  'MNKD': { pe: -10.92 },
  'MOLN': { pe: null },
  'MYOV': { pe: -10.24 },
  'NPCE': { pe: -2.46 },
  'ODT': { pe: null },
  'CHGG': { pe: 18.88 },
  'EDU': { pe: null },
  'TAL': { pe: -15.21 },
  'ZVO': { pe: null },
  'AYRO': { pe: -2.1 },
  'GOEV': { pe: -1.67 },
  'HMC': { pe: 9.11 },
  'REVG': { pe: 14.15 },
  'SOLO': { pe: -2.61 },
  'TM': { pe: 10.49 },
  'ADNT': { pe: 34.17 },
  'ALV': { pe: 19.16 },
  'MYE': { pe: 13.54 },
  'TSP': { pe: -4.05 },
  'WKHS': { pe: -7.21 },
  'TTD': { pe: 70.17 },
  'BBGI': { pe: -2.77 },
  'DISCB': { pe: -106.3 },
  'FUBO': { pe: -1.79 },
  'HMTV': { pe: -11.97 },
  'ME': { pe: -5.77 },
  'MNOV': { pe: -5.29 },
  'MTCR': { pe: -0.68 },
  'MYGN': { pe: -851.0 },
  'NERV': { pe: -0.06 },
  'NLTX': { pe: -0.94 },
  'ORIC': { pe: -2.07 },
  'OYST': { pe: -1.09 },
  'PBYI': { pe: 63.8 },
  'PLSE': { pe: -0.93 },
  'QTRX': { pe: -4.92 },
  'RAPT': { pe: -9.75 },
  'RGLS': { pe: -0.1 },
  'RPTX': { pe: -4.78 },
  'SCPS': { pe: null },
  'SEER': { pe: -6.99 },
  'SGEN': { pe: -49.22 },
  'STTK': { pe: -1.41 },
  'TARS': { pe: -5.57 },
  'VACC': { pe: -23.33 },
  'PRFT': { pe: 19.41 },
  'SYKE': { pe: null },
  'XELA': { pe: -0.02 },
  'CSV': { pe: 13.15 },
  'FIGS': { pe: 90.0 },
  'SGC': { pe: 10.62 },
  'CHE': { pe: 24.73 },
  'HCSG': { pe: 31.96 },
  'BBSI': { pe: 13.47 },
  'HSII': { pe: 8.56 },
  'NSP': { pe: 23.47 },
  'TBI': { pe: 8.65 },
  'ESI': { pe: 13.29 },
  'GPX': { pe: null },
  'GP': { pe: -5.32 },
  'TSLA': { pe: 72.61 },
  'TTM': { pe: -95.03 },
  'BRDCY': { pe: 14.01 },
  'CVGI': { pe: 7.37 },
  'DM': { pe: -8.17 },
  'TMBR': { pe: null },
  'TTOO': { pe: -0.46 },
  'VCNX': { pe: null },
  'VLON': { pe: null },
  'VTVT': { pe: -4.3 },
  'VYGR': { pe: -2.9 },
  'XOMA': { pe: -17.67 },
  'YTEN': { pe: -1.07 },
  'ZYME': { pe: -1.66 },
  'ADUS': { pe: 26.21 },
  'ALR': { pe: -1.36 },
  'DGX': { pe: 14.31 },
  'ENSG': { pe: 21.97 },
  'HCA': { pe: 12.15 },
  'SDC': { pe: -2.87 },
  'SNDA': { pe: -1.91 },
  'UHS': { pe: 10.61 },
  'AGTI': { pe: 19.6 },
  'ANGO': { pe: 1195.0 },
  'AXDX': { pe: -4.17 },
  'DNZOY': { pe: 17.67 },
  'HYLN': { pe: -5.12 },
  'LPTH': { pe: -23.33 },
  'STRT': { pe: 13.92 },
  'SUP': { pe: -22.27 },
  'TWI': { pe: 7.01 },
  'VLEEY': { pe: null },
  'ADV': { pe: 6.14 },
  'DV': { pe: 67.6 },
  'INUV': { pe: -8.44 },
  'PERI': { pe: 11.71 },
  'CMCSA': { pe: 10.85 },
  'FOXA': { pe: 11.13 },
  'MSGN': { pe: null },
  'NWSA': { pe: 17.13 },
  'TSQ': { pe: 5.95 },
  'KNWN': { pe: -27.63 },
  'NFLX': { pe: 23.85 },
  'DIS': { pe: 29.88 },
  'FUN': { pe: 13.36 },
  'NTUS': { pe: null },
  'SCND': { pe: null },
  'CABGY': { pe: 19.51 },
  'FIZZ': { pe: 32.93 },
  'MNST': { pe: 38.94 },
  'NBEV': { pe: null },
  'FARM': { pe: -5.3 },
  'FREE': { pe: 16.61 },
  'NSRGY': { pe: null },
  'SMPL': { pe: 22.28 },
  'AGFY': { pe: -0.29 },
  'ANDE': { pe: 10.25 },
  'BCPC': { pe: 33.65 },
  'CALM': { pe: 10.22 },
  'CVGW': { pe: 33.47 },
  'LMNR': { pe: 132.5 },
  'MBII': { pe: null },
  'FLGC': { pe: -2.18 },
  'CAKE': { pe: 13.97 },
  'MCD': { pe: 27.09 },
  'CNK': { pe: -16.19 },
  'DLPN': { pe: -39.36 },
  'IMAX': { pe: 55.86 },
  'ROKU': { pe: -24.74 },
  'HMHC': { pe: null },
  'LEE': { pe: 34.42 },
  'LTRY': { pe: null },
  'PLTK': { pe: 17.77 },
  'SKLZ': { pe: -2.39 },
  'WMG': { pe: 30.84 },
  'PSMT': { pe: 19.93 },
  'GIL': { pe: 10.5 },
  'LULU': { pe: 34.94 },
  'RCKY': { pe: 10.08 },
  'WWW': { pe: 9.57 },
  'FTCH': { pe: -10.27 },
  'IMBI': { pe: -1.23 },
  'TCS': { pe: 6.52 },
  'ROST': { pe: 21.84 },
  'DRVN': { pe: 27.44 },
  'CEMI': { pe: null },
  'FEMY': { pe: -2.08 },
  'HNGR': { pe: 16.45 },
  'MXDHF': { pe: -3.18 },
  'NMRD': { pe: -3.4 },
  'NSPR': { pe: -0.69 },
  'NVRO': { pe: -17.83 },
  'NVST': { pe: 19.77 },
  'PAVM': { pe: -1.4 },
  'RMTI': { pe: -0.69 },
  'SMTI': { pe: -84.85 },
  'TMCI': { pe: -22.93 },
  'ISRG': { pe: 49.51 },
  'LNSR': { pe: -2.57 },
  'STRR': { pe: -11.67 },
  'COKE': { pe: null },
  'KDP': { pe: 23.7 },
  'TSGTY': { pe: 27.83 },
  'AVSFY': { pe: null },
  'CHEF': { pe: 25.21 },
  'ONTX': { pe: -1.55 },
  'OPCH': { pe: 37.34 },
  'PACB': { pe: -5.6 },
  'PBLA': { pe: null },
  'PRVB': { pe: -2.89 },
  'PTN': { pe: -3.11 },
  'PYPD': { pe: -2.59 },
  'RCEL': { pe: -5.06 },
  'RETA': { pe: -2.26 },
  'RGEN': { pe: 77.66 },
  'RPRX': { pe: 14.78 },
  'SAGE': { pe: -4.93 },
  'SCPH': { pe: -4.23 },
  'SLDB': { pe: -1.05 },
  'SNGX': { pe: -2.61 },
  'SNSE': { pe: -1.33 },
  'SURF': { pe: -1.48 },
  'SVRA': { pe: -6.52 },
  'SYBX': { pe: -1.16 },
  'SYN': { pe: -0.12 },
  'MSGE': { pe: -106.24 },
  'RBLX': { pe: -38.42 },
  'COST': { pe: 42.15 },
  'KSS': { pe: 8.12 },
  'CTRN': { pe: 14.27 },
  'LEVI': { pe: 12.67 },
  'BOOT': { pe: 12.96 },
  'ELA': { pe: 15.19 },
  'KIRK': { pe: -6.67 },
  'PCTY': { pe: 80.83 },
  'PRTY': { pe: 9.44 },
  'FIVE': { pe: 29.69 },
  'WBA': { pe: 9.02 },
  'ORLY': { pe: 23.35 },
  'WDFC': { pe: 41.31 },
  'MOV': { pe: 8.84 },
  'DLX': { pe: 5.23 },
  'WOOF': { pe: 18.09 },
  'ICON': { pe: null },
  'DIIBF': { pe: -6.27 },
  'VIE': { pe: null },
  'VINC': { pe: -0.62 },
  'XENE': { pe: -17.67 },
  'YMTX': { pe: null },
  'FMS': { pe: 12.02 },
  'MED': { pe: 9.8 },
  'OM': { pe: -7.44 },
  'SURG': { pe: 5.73 },
  'TNDM': { pe: -211.24 },
  'ABC': { pe: 13.43 },
  'AEMD': { pe: -1.6 },
  'AGRX': { pe: -0.08 },
  'AVNS': { pe: 17.06 },
  'BVS': { pe: 16.73 },
  'CFMS': { pe: -0.97 },
  'COO': { pe: 24.63 },
  'EYE': { pe: 50.12 },
  'HAE': { pe: 28.77 },
  'INSP': { pe: -80.58 },
  'MLSS': { pe: -8.42 },
  'STIM': { pe: -2.64 },
  'WNC': { pe: 9.67 },
  'CCO': { pe: -6.17 },
  'MGNI': { pe: 13.1 },
  'NLSN': { pe: 14.34 },
  'OUT': { pe: 21.79 },
  'PUBM': { pe: 29.63 },
  'QNST': { pe: 67.84 },
  'SCOR': { pe: 104.5 },
  'BHC': { pe: 1.7 },
  'IHRT': { pe: 15.44 },
  'LBRDA': { pe: 13.03 },
  'LBTYK': { pe: 4.03 },
  'WOW': { pe: 59.73 },
  'AMC': { pe: -17.51 },
  'CIDM': { pe: -7.03 },
  'EDUC': { pe: 8.17 },
  'NWS': { pe: 17.42 },
  'ATVI': { pe: 26.06 },
  'MSGM': { pe: -0.22 },
  'NDLS': { pe: 59.67 },
  'RUTH': { pe: 13.03 },
  'WING': { pe: 83.28 },
  'CLX': { pe: 39.53 },
  'MMM': { pe: 14.19 },
  'NUS': { pe: 13.86 },
  'PBH': { pe: 13.63 },
  'PG': { pe: 25.78 },
  'TLRY': { pe: -4.31 },
  'UL': { pe: null },
  'RAVN': { pe: null },
  'UFI': { pe: 23.5 },
  'NXTC': { pe: -1.79 },
  'FWONA': { pe: 107.08 },
  'UA': { pe: 25.03 },
  'GPOVY': { pe: null },
  'PPD': { pe: null },
  'ARC': { pe: 11.44 },
  'BAH': { pe: 23.48 },
  'CMPR': { pe: -13.93 },
  'CPB': { pe: 17.6 },
  'KHC': { pe: 14.2 },
  'MKC': { pe: 30.86 },
  'MMMB': { pe: 16.06 },
  'VITL': { pe: 197.0 },
  'IDXX': { pe: 47.16 },
  'LAND': { pe: -79.42 },
  'WFCF': { pe: 25.6 },
  'CRON': { pe: -12.27 },
  'KR': { pe: 12.42 },
  'DEN': { pe: 11.97 },
  'GTIM': { pe: null },
  'LOCO': { pe: 15.02 },
  'PBPB': { pe: -318.5 },
  'TAST': { pe: -2.77 },
  'EL': { pe: 38.57 },
  'ELF': { pe: 43.73 },
  'EPC': { pe: 16.91 },
  'SONM': { pe: null },
  'NILE': { pe: -0.88 },
  'SIG': { pe: 6.29 },
  'TUP': { pe: 7.12 },
  'JOUT': { pe: 17.53 },
  'MPX': { pe: null },
  'WGO': { pe: 5.24 },
  'KNTK': { pe: 25.74 },
  'XRX': { pe: 15.89 },
  'CULP': { pe: -10.0 },
  'KBAL': { pe: 12.27 },
  'RCII': { pe: 7.03 },
  'AIR': { pe: 17.59 },
  'UPS': { pe: 15.89 },
  'CHH': { pe: 23.58 },
  'INN': { pe: null },
  'ARCB': { pe: 6.22 },
  'AUTO': { pe: -0.73 },
  'ECHO': { pe: null },
  'KNX': { pe: 10.34 },
  'SAIA': { pe: 16.76 },
  'SEAS': { pe: 12.62 },
  'JWN': { pe: 8.53 },
  'MAURY': { pe: 28.11 },
  'CRI': { pe: 11.5 },
  'DLTH': { pe: 12.29 },
  'KTB': { pe: 9.89 },
  'OXM': { pe: 11.8 },
  'TDUP': { pe: -3.8 },
  'ZUMZ': { pe: 8.57 },
  'TPR': { pe: 10.22 },
  'AONNY': { pe: 61.56 },
  'CPRI': { pe: 8.26 },
  'PLCE': { pe: 7.89 },
  'QRTEA': { pe: 3.96 },
  'WSM': { pe: 10.35 },
  'DG': { pe: 22.03 },
  'EYES': { pe: null },
  'CENTA': { pe: 14.02 },
  'GRWG': { pe: -2.21 },
  'BC': { pe: 8.28 },
  'MNTX': { pe: null },
  'NAVB': { pe: null },
  'NVCN': { pe: -0.63 },
  'RMD': { pe: 39.87 },
  'SIBN': { pe: -9.11 },
  'SWAV': { pe: 117.62 },
  'TMDX': { pe: -28.25 },
  'VAPO': { pe: -0.43 },
  'VIVE': { pe: -0.34 },
  'AFIB': { pe: -0.41 },
  'ICAD': { pe: -6.5 },
  'NNOX': { pe: -9.5 },
  'VREX': { pe: 17.52 },
  'ZYXI': { pe: 21.4 },
  'SAM': { pe: 45.68 },
  'WBD': { pe: -40.18 },
  'WTER': { pe: -1.93 },
  'AJINY': { pe: 30.96 },
  'APRN': { pe: -2.05 },
  'FLO': { pe: 21.75 },
  'HNI': { pe: 14.22 },
  'HOFT': { pe: 8.81 },
  'VIRC': { pe: 11.91 },
  'ALGT': { pe: 51.45 },
  'LUV': { pe: 17.94 },
  'FLL': { pe: -66.0 },
  'HST': { pe: 22.24 },
  'NSC': { pe: 18.86 },
  'DSKE': { pe: 7.67 },
  'HUBG': { pe: 8.18 },
  'ODFL': { pe: 25.65 },
  'WERN': { pe: 11.33 },
  'YELL': { pe: 4.61 },
  'CMRE': { pe: 3.05 },
  'CPLP': { pe: 3.28 },
  'GLBS': { pe: 1.35 },
  'GLOG': { pe: null },
  'NMM': { pe: 1.58 },
  'NNA': { pe: null },
  'SBLK': { pe: 3.58 },
  'TALK': { pe: -3.02 },
  'SSTK': { pe: 16.67 },
  'FNKO': { pe: 11.1 },
  'MAT': { pe: 15.65 },
  'DKS': { pe: 10.37 },
  'VGR': { pe: 10.89 },
  'XXII': { pe: -7.76 },
  'HRB': { pe: 15.41 },
  'OBLG': { pe: -0.6 },
  'QUMU': { pe: -0.95 },
  'CPSI': { pe: 10.69 },
  'FISV': { pe: 16.67 },
  'INFY': { pe: 28.3 },
  'MHH': { pe: 12.12 },
  'QMCO': { pe: -18.2 },
  'TDC': { pe: 21.9 },
  'FVRR': { pe: 92.79 },
  'RHI': { pe: 12.9 },
  'WNS': { pe: 23.87 },
  'LINC': { pe: 13.48 },
  'BKNG': { pe: 22.47 },
  'TRIP': { pe: 31.4 },
  'YTRA': { pe: 56.2 },
  'EDRY': { pe: 1.12 },
  'GLNG': { pe: 114.32 },
  'ABG': { pe: 5.11 },
  'LAD': { pe: 5.64 },
  'PAG': { pe: 6.71 },
  'AESE': { pe: null },
  'IGT': { pe: 14.09 },
  'MCRI': { pe: 15.93 },
  'QUAD': { pe: null },
  'CPS': { pe: -1.23 },
  'DASTY': { pe: 39.13 },
  'DOX': { pe: 16.19 },
  'PRAH': { pe: null },
  'PRDS': { pe: -1.47 },
  'STOR': { pe: 24.08 },
  'WIT': { pe: 20.37 },
  'HI': { pe: 11.83 },
  'BFAM': { pe: 28.5 },
  'EPAM': { pe: 46.35 },
  'JOB': { pe: 6.97 },
  'KELYA': { pe: 10.09 },
  'PAYX': { pe: 35.63 },
  'RCMT': { pe: 8.34 },
  'STAF': { pe: -0.62 },
  'AMST': { pe: null },
  'HSTM': { pe: 76.0 },
  'LOPE': { pe: 14.82 },
  'FUJHY': { pe: 13.63 },
  'HAYW': { pe: 11.51 },
  'HOG': { pe: 9.33 },
  'MCFT': { pe: 5.5 },
  'ONEW': { pe: 4.24 },
  'POOL': { pe: 20.72 },
  'CSIOY': { pe: 18.24 },
  'KNL': { pe: null },
  'SCS': { pe: 47.0 },
  'SNBR': { pe: 8.53 },
  'TPX': { pe: 10.64 },
  'AIRYY': { pe: -2.87 },
  'ICAGY': { pe: -13.43 },
  'ZNH': { pe: -3.08 },
  'CPLG': { pe: null },
  'H': { pe: 197.44 },
  'HGV': { pe: 17.13 },
  'PK': { pe: 21.85 },
  'WH': { pe: 19.53 },
  'GATX': { pe: 17.34 },
  'CYRX': { pe: -51.05 },
  'SKIL': { pe: -8.8 },
  'BLBD': { pe: -44.68 },
  'GM': { pe: 5.61 },
  'NSANY': { pe: 9.45 },
  'RACE': { pe: 41.12 },
  'CAAS': { pe: 8.05 },
  'CPRT': { pe: 28.41 },
  'CTTAY': { pe: 18.22 },
  'MGA': { pe: 13.07 },
  'PRTS': { pe: -172.0 },
  'THRM': { pe: 33.41 },
  'VC': { pe: 24.51 },
  'IPG': { pe: 11.05 },
  'LAMR': { pe: 21.24 },
  'SHSP': { pe: null },
  'TRMR': { pe: 5.99 },
  'ATUS': { pe: 7.81 },
  'CHTR': { pe: 14.01 },
  'CMLS': { pe: 5.94 },
  'LBTYA': { pe: 3.81 },
  'TBPH': { pe: -21.64 },
  'URGN': { pe: -1.78 },
  'VALN': { pe: -7.27 },
  'VTGN': { pe: -0.67 },
  'VVOS': { pe: -1.16 },
  'VXRT': { pe: -4.48 },
  'VYNE': { pe: -0.88 },
  'ZBH': { pe: 16.81 },
  'ZSAN': { pe: null },
  'CYH': { pe: -1.64 },
  'GRTS': { pe: -2.92 },
  'JYNT': { pe: 125.0 },
  'KIDS': { pe: -59.67 },
  'LFMD': { pe: -3.27 },
  'TVTY': { pe: 18.47 },
  'VTR': { pe: 2525.5 },
  'AMPH': { pe: 18.85 },
  'AVGR': { pe: -0.33 },
  'BIOL': { pe: -1.54 },
  'CAPR': { pe: -6.05 },
  'GIS': { pe: 19.38 },
  'HAIN': { pe: 19.89 },
  'JJSF': { pe: 53.36 },
  'KWPCY': { pe: 20.36 },
  'LW': { pe: 32.11 },
  'MDLZ': { pe: 22.43 },
  'RIBT': { pe: -2.2 },
  'SYY': { pe: 22.94 },
  'TBLMY': { pe: null },
  'UNFI': { pe: 9.38 },
  'TRC': { pe: 145.82 },
  'CGC': { pe: -0.73 },
  'IMCC': { pe: -1.32 },
  'CBRL': { pe: 18.3 },
  'CHUY': { pe: 17.84 },
  'CMG': { pe: 51.93 },
  'KRUS': { pe: -572.75 },
  'PZZA': { pe: 30.24 },
  'COTY': { pe: 26.0 },
  'PRGO': { pe: 17.46 },
  'STNG': { pe: 4.7 },
  'ZIM': { pe: 1.14 },
  'GPI': { pe: 4.3 },
  'KMX': { pe: 18.87 },
  'LMPX': { pe: null },
  'LYFT': { pe: 43.83 },
  'RUSHA': { pe: 8.33 },
  'UHAL': { pe: null },
  'VRM': { pe: -0.83 },
  'CNTY': { pe: 12.86 },
  'CZR': { pe: -14.18 },
  'DKNG': { pe: -6.86 },
  'GLPI': { pe: 22.33 },
  'MGM': { pe: 72.94 },
  'SCPL': { pe: 13.45 },
  'SATS': { pe: 11.37 },
  'CSSE': { pe: -2.74 },
  'GHC': { pe: 19.4 },
  'MDP': { pe: null },
  'CCOEY': { pe: 12.87 },
  'EDR': { pe: 11.74 },
  'EVTV': { pe: null },
  'GME': { pe: -25.65 },
  'GOGO': { pe: 27.46 },
  'TTWO': { pe: 28.0 },
  'ZDGE': { pe: 16.74 },
  'SVNDY': { pe: 20.65 },
  'GES': { pe: 7.91 },
  'GIII': { pe: 6.03 },
  'JILL': { pe: 7.2 },
  'CNXN': { pe: 14.81 },
  'CFRHF': { pe: 26.48 },
  'SWGAY': { pe: null },
  'DECK': { pe: 19.23 },
  'GCO': { pe: 8.75 },
  'LEV': { pe: -483.0 },
  'NKLA': { pe: -5.39 },
  'DORM': { pe: 20.0 },
  'JCI': { pe: 18.7 },
  'KE': { pe: 12.0 },
  'PCAR': { pe: 12.08 },
  'XPEL': { pe: 51.54 },
  'DMS': { pe: -3.72 },
  'ICLK': { pe: -3.37 },
  'PHUN': { pe: -3.6 },
  'EVC': { pe: 15.84 },
  'SBGI': { pe: 0.7 },
  'SJR': { pe: 22.57 },
  'TV': { pe: 78.78 },
  'NYT': { pe: 33.91 },
  'SCHL': { pe: null },
  'ZD': { pe: 12.81 },
  'EA': { pe: 21.58 },
  'HEAR': { pe: -18.5 },
  'BJ': { pe: 20.76 },
  'LSTR': { pe: 13.2 },
  'ULH': { pe: 7.02 },
  'TZOO': { pe: 12.13 },
  'XSPA': { pe: -4.26 },
  'ASC': { pe: 3.81 },
  'DAC': { pe: 2.22 },
  'EGLE': { pe: 2.69 },
  'FRO': { pe: 10.81 },
  'GOGL': { pe: 4.51 },
  'NM': { pe: null },
  'TGP': { pe: null },
  'NCLH': { pe: -3.49 },
  'SAH': { pe: 5.55 },
  'ELYS': { pe: -2.71 },
  'PENN': { pe: 29.29 },
  'CNMD': { pe: 30.31 },
  'CSII': { pe: -18.19 },
  'DCTH': { pe: -1.2 },
  'DXCM': { pe: 110.17 },
  'DXR': { pe: null },
  'EW': { pe: 40.05 },
  'ITGR': { pe: 17.14 },
  'LMAT': { pe: 50.69 },
  'MDWD': { pe: -5.12 },
  'MNMD': { pe: -3.64 },
  'MOTS': { pe: -0.03 },
  'NARI': { pe: -113.85 },
  'NUVA': { pe: 22.52 },
  'ORGO': { pe: 14.8 },
  'PODD': { pe: 620.0 },
  'VERO': { pe: -1.06 },
  'EPIX': { pe: -2.84 },
  'MDVL': { pe: -1.29 },
  'NEPH': { pe: -2.75 },
  'SYK': { pe: 23.61 },
  'ULTA': { pe: 20.05 },
  'VZIO': { pe: 0.0 },
  'DXYN': { pe: null },
  'BBW': { pe: 6.86 },
  'JAKK': { pe: 5.77 },
  'CDXC': { pe: -5.29 },
  'LFVN': { pe: null },
  'USNA': { pe: 18.14 },
  'BGFV': { pe: 6.99 },
  'CLAR': { pe: 49.47 },
  'ESCA': { pe: 7.18 },
  'MO': { pe: 9.39 },
  'ACN': { pe: 29.06 },
  'CNXC': { pe: 10.95 },
  'CRAI': { pe: 17.34 },
  'DFIN': { pe: 11.67 },
  'G': { pe: 17.74 },
  'RGP': { pe: 9.42 },
  'DXC': { pe: 7.41 },
  'SCVL': { pe: 6.39 },
  'ETSY': { pe: 53.88 },
  'FLWS': { pe: 28.17 },
  'QRTEB': { pe: 12.61 },
  'TAC': { pe: 17.35 },
  'TLYS': { pe: 12.94 },
  'DLTR': { pe: 20.53 },
  'JOAN': { pe: 34.06 },
  'OLLI': { pe: 34.28 },
  'CASY': { pe: 24.27 },
  'VVV': { pe: 15.32 },
  'EBF': { pe: 15.29 },
  'ODP': { pe: 8.27 },
  'FRPT': { pe: -52.22 },
  'HSKA': { pe: 73.61 },
  'PETQ': { pe: 11.27 },
  'SITE': { pe: 23.46 },
  'TBLT': { pe: null },
  'ACKAY': { pe: 13.96 },
  'TJX': { pe: 21.83 },
  'WMT': { pe: 23.85 },
  'ANF': { pe: 10.48 },
  'LE': { pe: 23.64 },
  'VNCE': { pe: 28.33 },
  'VRA': { pe: 11.38 },
  'AVT': { pe: 5.57 },
  'CDW': { pe: 18.85 },
  'NSIT': { pe: 10.81 },
  'BKE': { pe: 6.65 },
  'NKE': { pe: 33.25 },
  'FL': { pe: 7.33 },
  'RVLV': { pe: 34.89 },
  'CORE': { pe: null },
  'SFT': { pe: -0.5 },
  'CTHR': { pe: 131.99 },
  'CHWY': { pe: -137.86 },
  'LL': { pe: 13.93 },
  'LCUT': { pe: 8.21 },
  'LAZY': { pe: 5.33 },
  'VRAY': { pe: -6.33 },
  'WAT': { pe: 27.88 },
  'XRAY': { pe: 14.59 },
  'BF.B': { pe: 41.98 },
  'DEO': { pe: null },
  'NAPA': { pe: 27.51 },
  'AGFS': { pe: -3.06 },
  'K': { pe: 18.25 },
  'TWNK': { pe: 23.9 },
  'CAG': { pe: 14.68 },
  'IPW': { pe: 7.12 },
  'CBD': { pe: -190.0 },
  'SFM': { pe: 13.79 },
  'BLMN': { pe: 9.38 },
  'DASH': { pe: -31.56 },
  'DPZ': { pe: 32.57 },
  'EAT': { pe: 10.25 },
  'FRGI': { pe: -104.29 },
  'JAX': { pe: null },
  'PLAY': { pe: 12.41 },
  'MEDP': { pe: 28.85 },
  'NCR': { pe: 11.65 },
  'PTC': { pe: 25.84 },
  'SNX': { pe: 8.93 },
  'CTAS': { pe: 38.22 },
  'ECL': { pe: 36.37 },
  'ASGN': { pe: 15.63 },
  'CCRN': { pe: 4.96 },
  'FSI': { pe: 6.33 },
  'HQI': { pe: 15.27 },
  'KELYB': { pe: 10.02 },
  'WW': { pe: 8.38 },
  'APEI': { pe: -2.22 },
  'ATGE': { pe: 9.7 },
  'COUR': { pe: -29.27 },
  'LAUR': { pe: 22.72 },
  'LRN': { pe: 13.95 },
  'WIX': { pe: -60.06 },
  'AAP': { pe: 15.47 },
  'GNTX': { pe: 19.45 },
  'MKTAY': { pe: 17.6 },
  'TTC': { pe: 20.48 },
  'ALC': { pe: 28.38 },
  'NWL': { pe: 11.47 },
  'HZO': { pe: 5.06 },
  'SWIM': { pe: 9.16 },
  'CAJ': { pe: 13.88 },
  'BSET': { pe: 3.13 },
  'CSPR': { pe: null },
  'CAAP': { pe: 9.66 },
  'CEA': { pe: -1.76 },
  'CPA': { pe: 20.19 },
  'GOL': { pe: -3.59 },
  'JBLU': { pe: -9.67 },
  'RYAAY': { pe: 17.1 },
  'HLT': { pe: 31.31 },
  'PLYA': { pe: 14.8 },
  'RHP': { pe: 46.18 },
  'TNL': { pe: 10.33 },
  'XHR': { pe: 51.2 },
  'PLOW': { pe: 17.6 },
  'TACT': { pe: -4.92 },
  'HVT': { pe: 7.59 },
  'PRPL': { pe: -11.31 },
  'AER': { pe: -10.67 },
  'HA': { pe: -3.84 },
  'UAL': { pe: 121.75 },
  'ULCC': { pe: -82.47 },
  'FWRD': { pe: 15.21 },
  'DRH': { pe: 21.64 },
  'PEB': { pe: -33.12 },
  'CJPRY': { pe: 29.59 },
  'GBX': { pe: 21.82 },
  'CVLG': { pe: 6.18 },
  'JBHT': { pe: 19.51 },
  'SNDR': { pe: 9.27 },
  'XPO': { pe: 10.05 },
  'EXPE': { pe: 15.64 },
  'MMYT': { pe: -194.94 },
  'WEN': { pe: 25.27 },
  'YUM': { pe: 26.18 },
  'HELE': { pe: 13.23 },
  'HNST': { pe: -10.7 },
  'IPAR': { pe: 26.26 },
  'KCDMY': { pe: 18.87 },
  'UNLYF': { pe: null },
  'HBB': { pe: 5.77 },
  'NEON': { pe: null },
  'SHCAY': { pe: 10.44 },
  'SPB': { pe: 44.42 },
  'HAS': { pe: 15.81 },
  'JAGX': { pe: -0.3 },
  'NGVC': { pe: null },
  'ELY': { pe: 25.44 },
  'FWONK': { pe: 124.79 },
  'HIBB': { pe: 6.29 },
  'PLNT': { pe: 50.53 },
  'TMX': { pe: 30.82 },
  'UNP': { pe: 20.61 },
  'MRTN': { pe: 15.95 },
  'RRTS': { pe: null },
  'DSSI': { pe: null },
  'GRIN': { pe: 2.94 },
  'TNP': { pe: 3.47 },
  'LIND': { pe: -5.92 },
  'CARG': { pe: 15.21 },
  'CARS': { pe: 8.18 },
  'CVNA': { pe: -5.94 },
  'VRRM': { pe: 16.29 },
  'EVRI': { pe: 15.78 },
  'GDEN': { pe: 13.64 },
  'GT': { pe: 7.39 },
  'NAV': { pe: null },
  'SMP': { pe: 10.18 },
  'CDLX': { pe: -3.87 },
  'CRTD': { pe: null },
  'IBEX': { pe: 10.03 },
  'KBNT': { pe: -1.04 },
  'MDLA': { pe: null },
  'OMC': { pe: 10.86 },
  'VERB': { pe: -1.88 },
  'AMT': { pe: 49.9 },
  'DISH': { pe: 6.84 },
  'LBRDK': { pe: 14.19 },
  'SALM': { pe: 5.21 },
  'TU': { pe: 23.69 },
  'PARA': { pe: 11.43 },
  'SSP': { pe: 6.09 },
  'LYV': { pe: 152.31 },
  'SIX': { pe: 14.03 },
  'YALA': { pe: null },
  'SABR': { pe: -7.98 },
  'ALEX': { pe: 38.06 },
  'GSL': { pe: 2.47 },
  'NAT': { pe: -27.9 },
  'NVGS': { pe: 11.23 },
  'PANL': { pe: 2.7 },
  'SFL': { pe: 8.68 },
  'TK': { pe: null },
  'RCL': { pe: -6.0 },
  'UBER': { pe: -6.54 },
  'ACEL': { pe: 11.3 },
  'AGS': { pe: -25.29 },
  'CHDN': { pe: 27.76 },
  'LVS': { pe: -39.01 },
  'SCR': { pe: null },
  'ACTG': { pe: -1.47 },
  'ATTO': { pe: -0.99 },
  'HGBL': { pe: 8.58 },
  'HURN': { pe: 21.14 },
  'ICFI': { pe: 19.52 },
  'NEWT': { pe: 8.03 },
  'RRD': { pe: null },
  'ZI': { pe: 63.27 },
  'ADP': { pe: 35.04 },
  'CTG': { pe: 12.56 },
  'GPN': { pe: 14.1 },
  'HCAT': { pe: -29.1 },
  'LAKE': { pe: 13.09 },
  'UNF': { pe: 28.77 },
  'ABM': { pe: 13.09 },
  'EXLS': { pe: 31.02 },
  'KFY': { pe: 10.8 },
  'ASPU': { pe: -2.51 },
  'VST': { pe: -11.76 },
  'APTV': { pe: 33.79 },
  'M': { pe: 4.58 },
  'SYM': { pe: -98.31 },
  'WMMVY': { pe: 26.87 },
  'RL': { pe: 13.1 },
  'VFC': { pe: 15.76 },
  'SKX': { pe: 14.95 },
  'BBBY': { pe: -3.57 },
  'FRG': { pe: 8.89 },
  'SPWH': { pe: 8.3 },
  'SQBG': { pe: null },
  'W': { pe: -9.2 },
  'XELB': { pe: null },
  'ID': { pe: -7.89 },
  'CENT': { pe: 14.91 },
  'TSCO': { pe: 21.32 },
  'LCII': { pe: 7.04 },
  'LESL': { pe: 17.97 },
  'PII': { pe: 11.99 },
  'PTON': { pe: -3.13 },
  'YETI': { pe: 18.42 },
  'GPC': { pe: 20.12 },
  'IOCJY': { pe: null },
  'SRI': { pe: -87.52 },
  'TEN': { pe: 22.11 },
  'VLDR': { pe: -1.87 },
  'ML': { pe: -7.23 },
  'SEAC': { pe: null },
  'SQSP': { pe: 70.55 },
  'ZETA': { pe: 44.5 },
  'GTN': { pe: 3.62 },
  'NXST': { pe: 7.68 },
  'RCI': { pe: 16.01 },
  'AMCX': { pe: 3.79 },
  'NCMI': { pe: -8.3 },
  'GCI': { pe: 13.82 },
  'CURI': { pe: -2.03 },
  'SLGG': { pe: -1.39 },
  'DDS': { pe: 8.98 },
  'TGT': { pe: 21.78 },
  'CHS': { pe: 10.57 },
  'ALOT': { pe: null },
  'KRNT': { pe: -64.66 },
  'MITK': { pe: 12.74 },
  'LEG': { pe: 15.09 },
  'LOVE': { pe: 12.16 },
  'RH': { pe: 13.2 },
  'AAL': { pe: -17.81 },
  'MESA': { pe: -3.17 },
  'SAVE': { pe: -12.43 },
  'SKYW': { pe: 16.62 },
  'EXPD': { pe: 13.1 },
  'BHR': { pe: 20.93 },
  'MCS': { pe: -434.75 },
  'KSU': { pe: null },
  'BVH': { pe: 6.31 },
  'DESP': { pe: -13.39 },
  'DHT': { pe: 80.9 },
  'GNK': { pe: 3.38 },
  'KEX': { pe: 32.05 },
  'SB': { pe: 2.71 },
  'CCL': { pe: -2.92 },
  'KAR': { pe: 48.29 },
  'R': { pe: 5.52 },
  'BALY': { pe: 17.4 },
  'COLM': { pe: 14.9 },
  'GAN': { pe: -2.44 },
  'JRSH': { pe: 11.04 },
  'PVH': { pe: 8.2 },
  'BBY': { pe: 12.95 },
  'AEO': { pe: 12.41 },
  'BODY': { pe: -2.48 },
  'DXLG': { pe: 8.59 },
  'EXPR': { pe: 15.33 },
  'SKT': { pe: 23.66 },
  'BIG': { pe: -10.86 },
  'MUSA': { pe: 13.61 },
  'SNA': { pe: 14.3 },
  'FOSL': { pe: null },
  'CVET': { pe: 19.28 },
  'PETS': { pe: 24.54 },
  'HD': { pe: 19.65 },
  'WHR': { pe: 7.68 },
  'CWH': { pe: 6.29 },
  'FOXF': { pe: 20.19 },
  'MGRC': { pe: 19.98 },
  'RICOY': { pe: 17.0 },
  'FLXS': { pe: 8.57 },
  'GOED': { pe: 4.73 },
  'LZB': { pe: 9.15 },
  'ATSG': { pe: 13.53 },
  'AAWW': { pe: 5.96 },
  'FDX': { pe: 10.5 },
  'HT': { pe: -12.04 },
  'MAR': { pe: 25.28 },
  'CSX': { pe: 17.96 },
  'CHRW': { pe: 13.42 },
  'HTLD': { pe: 15.35 },
  'PTSI': { pe: 7.07 },
  'USAK': { pe: 8.37 },
  'CAI': { pe: null },
  'DSX': { pe: 3.79 },
  'GASS': { pe: 4.75 },
  'INSW': { pe: 6.13 },
  'RLGT': { pe: 9.6 },
  'TGH': { pe: 5.55 },
  'TNK': { pe: 10.4 },
  'TRMD': { pe: 4.77 },
  'CAR': { pe: 4.06 },
  'CRMT': { pe: 9.17 },
  'HYRE': { pe: -1.97 },
  'IAA': { pe: 15.83 },
  'RMBL': { pe: 8.88 },
  'RUSHB': { pe: 9.48 },
  'GMBL': { pe: -0.18 },
  'NGMS': { pe: -118.07 },
  'RRR': { pe: 23.39 }
}
