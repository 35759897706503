import axios from '@axios'
import { SECTOR_URL } from '@/constants/app'

export const STATE = {
  sectors: [],
  sectorMap: {},
  sectorsLoading: false,
  sectorLoading: false
}

export const ACTIONS = {
  async getSectors({ commit }) {
    commit('setSectorsLoading', true)
    try {
      const { data } = await axios.get(SECTOR_URL)
      commit('setSectors', data)
      return data
    }
    finally {
      commit('setSectorsLoading', false)
    }
  },
  async getSector({ commit }, id) {
    commit('setSectorLoading', true)
    try {
      const { data } = await axios.get(`${ SECTOR_URL }/${ id }`)
      commit('setSector', { id, data })
      return data
    }
    finally {
      commit('setSectorLoading', false)
    }
  }
}

export const MUTATIONS = {
  setSectors(state, sectors) {
    state.sectors = sectors
  },
  setSector(state, { id, data }) {
    state.sectorMap[id] = data
  },
  setSectorsLoading(state, bool) {
    state.sectorsLoading = bool
  },
  setSectorLoading(state, bool) {
    state.sectorLoading = bool
  }
}
