import { ROUTE_NAMES } from '@/constants/routes'
import { CONTEXTS } from '@/constants/search'

export default [
  {
    path: `/etfs`,
    name: ROUTE_NAMES.ETFS_PREVIEW,
    component: () => import('@pages/ETFPreview/ShowPage.vue'),
    requiredProps: [CONTEXTS.ETF.storeKey],
    meta: {
      context: CONTEXTS.ETF
    }
  }
]
