import { CREATING, DESTROYING, LOADING, UPDATING } from '@/constants/stages'
import useApollo from '@core/hooks/apollo'

const actions = {}

actions.reloadReportLinks = async ({ commit }) => {
  commit('setStage', LOADING)
  try {
    const { ainstein } = useApollo()
    const { data: { map } } = await ainstein.reload('Ainstein/PBI/pbiReportLinks')
    return commit('setReportLinks', map)
  }
  finally {
    commit('resetStage')
  }
}
actions.createLink = async ({ commit }, variables) => {
  commit('setStage', CREATING)
  try {
    const { ainstein } = useApollo()
    await ainstein.send('Ainstein/PBI/pbiReportLinkCreate', variables)
  }
  finally {
    commit('resetStage')
  }
}
actions.updateLink = async ({ commit }, variables) => {
  commit('setStage', UPDATING)
  try {
    const { ainstein } = useApollo()
    await ainstein.send('Ainstein/PBI/pbiReportLinkUpdate', variables)
  }
  finally {
    commit('resetStage')
  }
}
actions.removeLink = async ({ commit }, variables) => {
  commit('setStage', DESTROYING)
  try {
    const { ainstein } = useApollo()
    await ainstein.send('Ainstein/PBI/pbiReportLinkDelete', variables)
  }
  finally {
    commit('resetStage')
  }
}

export default actions
