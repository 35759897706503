import { ROUTE_NAMES } from '@/constants/routes'
import { CONTEXTS } from '@/constants/search'

export default [
  {
    path: `/stocks`,
    name: ROUTE_NAMES.STOCKS,
    component: () => import('@pages/Stocks/ShowPage.vue'),
    requiredProps: [CONTEXTS.TICKER.storeKey],
    meta: {
      context: CONTEXTS.TICKER
    }
  }
]
