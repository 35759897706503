import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import routes from './routes'
import useAuth from '@hooks/auth'
import { useStore } from '@store'
import { DEFAULT_SEARCH_CONTEXT } from '@/constants/search'
import { ROUTE_NAMES } from '@/constants/routes'
import { APP, SEARCH } from '@/constants/store'
import { initialization } from '@core/hooks'
// Browser
import { browser } from '@/utils'
import ability from '@/libs/acl/ability'
import { ACTIONS } from '@/constants/ability'
// eslint-disable-next-line no-duplicate-imports
export * as routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach(async (to, _, next) => {
  const { isUserAuthenticated } = useAuth()
  const authenticated = await isUserAuthenticated()

  const { state: { initialization: { initialized } } } = useStore(APP)
  if (!initialized) {
    const { useInitialization } = initialization
    const { initialize } = await useInitialization()
    await initialize()
  }

  // Check permission to see requested page first.
  if (!ability.can(ACTIONS.VIEW, to.name)) {
    return next({
      name: authenticated ? ROUTE_NAMES.ERROR_403 : ROUTE_NAMES.LOGIN
    })
  }

  return next()
})

router.beforeEach((to, _, next) => {
  const { dispatch, commit } = useStore(SEARCH)
  const { meta: { context = DEFAULT_SEARCH_CONTEXT } = {} } = to

  const { storeKey, paramKey, mutationName } = context
  const key = paramKey || storeKey
  if (key in to.params) {
    commit(mutationName, to.params[key].toString())
  }

  dispatch('setContext', context)

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.afterEach(to => {
  const matched = to.matched.filter(el => el.name).map(el => el.name).join(': ')
  document.title = browser.tabTitle(matched)
})

export default router
