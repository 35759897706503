import DEFAULT_PAGES from './pages'
import ADMIN_ROUTES from './admin'
import SECTORS_TREE from './sectorsTree'
import { useStore } from '@store'
import { ROUTE_NAMES } from '@/constants/routes'
import { DEFAULT_SEARCH_CONTEXT } from '@/constants/search'
import { SEARCH } from '@/constants/store'
import { ENABLED_TRACKERS } from '@/constants/app'
import { trackers } from '@/constants'

// Dynamic import of trackers.
// All the Trackers are defined in "@/constants/trackers".
const imports = []
const trackersList = Object.values(trackers)
for (const enabledTracker of ENABLED_TRACKERS) {
  const tracker = trackersList.find(tracker => tracker.code === enabledTracker)
  // The path must be defined here explicitly.
  // Otherwise, webpack won't recognize it.
  tracker && imports.push(require(`@/router/routes/trackers/${ tracker.routesModule }`).default)
}

const modules = imports.flat()

const HOME_PAGE = modules[0].children ? modules[0].children[0] : modules[0]
export const HOME_PAGE_ROUTE = {
  path: '/',
  redirect: () => {
    const { state } = useStore(SEARCH)
    const context = HOME_PAGE.meta?.context || DEFAULT_SEARCH_CONTEXT
    const { storeKey, paramKey } = context
    const name = HOME_PAGE.name
    return {
      name, params: {
        [paramKey]: state[storeKey]
      }
    }
  }
}


export {
  ADMIN_ROUTES
}

export default [
  HOME_PAGE_ROUTE,
  ...SECTORS_TREE,
  ...DEFAULT_PAGES,
  ...ADMIN_ROUTES,
  ...modules,
  {
    path: '*',
    redirect: ROUTE_NAMES.ERROR_404
  }
]
