const getters = {}

getters.staticSearchData = ({ context, searchDataSource }) => {
  const { key } = context
  const { data } = searchDataSource[key] // to keep reactivity
  return {
    [key]: { ...searchDataSource[key], data }
  }
}
getters.remoteSearchData = ({ context, searchDataSource }) => {
  const { key } = context
  const { remoteSearchData } = searchDataSource[key] // to keep reactivity
  return remoteSearchData
}
getters.isStaticDataLoading = ({ context, searchDataSource }) => {
  const { key } = context
  return searchDataSource[key].isEmpty
}
getters.isRemoteDataLoading = ({ remoteLoading }) => {
  return remoteLoading
}

export default getters
