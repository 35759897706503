import Vue from 'vue'
import { default as Vuex } from 'vuex'
// Modules
import { CORE_MODULES } from '@/constants/store'
import { computed } from '@vue/composition-api'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {},
  strict: true
})

const imports = new Map()

const useState = key => {
  if (!store.state[key]) {
    // eslint-disable-next-line no-console
    console.warn(`Module "${ key }" does not exist`, Object.keys(store.state))
  }
  return store.state[key]
}
const useGetters = key => {
  return getter => computed(() => store.getters[`${ key }/${ getter }`])
}
const useMutations = key => {
  return (mutation, value) => store.commit(`${ key }/${ mutation }`, value)
}
const useActions = key => {
  return (action, value) => store.dispatch(`${ key }/${ action }`, value)
}

const registerModule = (module, moduleImport) => {
  if (!store.hasModule(module.key)) {
    store.registerModule(module.key, moduleImport)
  }
  return store.hasModule(module.key)
}
const registerImport = (module, moduleImport) => {
  if (!imports.has(module.key)) {
    imports.set(module.key, moduleImport.default)
  }
  return imports.has(module.key)
}

const isModuleRegistered = module => {
  return imports.has(module.key) && store.hasModule(module.key)
}

/**
 *
 * @param module
 * @param {{getters: array}} options
 */
export const useStore = module => {
  if (!isModuleRegistered(module)) {
    // Path must be explicitly concatenated here.
    // Otherwise, webpack would not recognise it.
    registerImport(module, require(`@/store/${ module.path }`))
    registerModule(module, imports.get(module.key))
  }
  return {
    state: useState(module.key),
    getter: useGetters(module.key),
    commit: useMutations(module.key),
    dispatch: useActions(module.key)
  }
}

export const useStoreLazy = async module => {
  if (!isModuleRegistered(module)) {
    // Path must be explicitly concatenated here.
    // Otherwise, webpack would not recognise it.
    registerImport(module, await import(`@/store/${ module.path }`))
    registerModule(module, imports.get(module.key))
  }
  return useStore(module)
}

CORE_MODULES.forEach(useStore)

export default store
