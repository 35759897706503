
export const PORTFOLIO = {
  code: 'PORTFOLIO',
  routesModule: 'portfolio',
  navigationModule: 'portfolio'
}
export const COMPANY = {
  code: 'COMPANY',
  routesModule: 'company',
  navigationModule: 'company'
}
export const ETF = {
  code: 'ETF',
  routesModule: 'etf',
  navigationModule: 'etf'
}
export const EARNINGS_ENGINE = {
  code: 'EARNINGS_ENGINE',
  routesModule: 'earnings-engine',
  navigationModule: 'earnings-engine'
}
export const FORECASTS = {
  code: 'FORECASTS',
  routesModule: 'forecasts',
  navigationModule: 'forecasts'
}
export const INDUSTRIES = {
  code: 'INDUSTRIES',
  routesModule: 'industries',
  navigationModule: 'industries'
}
export const EXCHANGES = {
  code: 'EXCHANGES',
  routesModule: 'exchanges',
  navigationModule: 'exchanges'
}
export const MARKETS = {
  code: 'MARKETS',
  routesModule: 'markets',
  navigationModule: 'markets'
}
export const SMART_NEWS = {
  code: 'SMART_NEWS',
  routesModule: 'smart-news',
  navigationModule: 'smart-news'
}
export const MUTUAL_FUNDS = {
  code: 'MUTUAL_FUNDS',
  routesModule: 'mutual-funds',
  navigationModule: 'mutual-funds'
}
export const ESG = {
  code: 'ESG',
  routesModule: 'esg',
  navigationModule: 'esg'
}
export const BONDS = {
  code: 'BONDS',
  routesModule: 'bonds',
  navigationModule: 'bonds'
}
export const COMPANIES = {
  code: 'COMPANIES',
  routesModule: 'companies',
  navigationModule: 'companies'
}
export const EUMONICS = {
  code: 'EUMONICS',
  routesModule: 'eumonics',
  navigationModule: 'eumonics'
}
export const ACTIVE_TRADING = {
  code: 'ACTIVE_TRADING',
  routesModule: 'active-trading',
  navigationModule: 'active-trading'
}
export const STOCKS = {
  code: 'STOCKS',
  routesModule: 'stocks',
  navigationModule: 'stocks'
}
export const ETFS_PREVIEW = {
  code: 'ETFS_PREVIEW',
  routesModule: 'etfs-preview',
  navigationModule: 'etfs-preview'
}
export const INDUSTRY_TRADING = {
  code: 'INDUSTRY_TRADING',
  routesModule: 'industry-trading',
  navigationModule: 'industry-trading'
}
export const ADRS = {
  code: 'ADRS',
  routesModule: 'adrs',
  navigationModule: 'adrs'
}
export const INDUSTRY_PERFORMANCE = {
  code: 'INDUSTRY_PERFORMANCE',
  routesModule: 'industry-performance',
  navigationModule: 'industry-performance'
}
export const RATINGS = {
  code: 'RATINGS',
  routesModule: 'ratings',
  navigationModule: 'ratings'
}
