import { Ability, AbilityBuilder } from '@casl/ability'
import { ROUTE_NAMES } from '@/constants/routes'
import { ACTIONS } from '@/constants/ability'
import { ADMIN_IDS } from '@/constants/app'

const ADMINS = ADMIN_IDS.map(item => item.trim()).filter(_ => _)

const ability = new Ability()
const { can, rules } = new AbilityBuilder(Ability)

const resetAbilities = () => {
  const count = rules.length
  for (let x = 0; x < count; x++) {
    rules.pop()
  }
}

const adminAbilities = () => {
  can(ACTIONS.VIEW, ROUTE_NAMES.ADMIN_ROOT)
  can(ACTIONS.VIEW, ROUTE_NAMES.ADMIN_PBI)
}

const guestAbility = () => {
  can(ACTIONS.VIEW, ROUTE_NAMES.LOGIN)
  can(ACTIONS.VIEW, ROUTE_NAMES.ACTIVE_TRADING)
  can(ACTIONS.VIEW, ROUTE_NAMES.STOCKS)
  can(ACTIONS.VIEW, ROUTE_NAMES.ETFS_PREVIEW)
  can(ACTIONS.VIEW, ROUTE_NAMES.INDUSTRY_TRADING)
  can(ACTIONS.VIEW, ROUTE_NAMES.ADRS)
  can(ACTIONS.VIEW, ROUTE_NAMES.INDUSTRY_PERFORMANCE)
  can(ACTIONS.VIEW, ROUTE_NAMES.RATINGS)

  can(ACTIONS.VIEW, ROUTE_NAMES.PORTFOLIO_ROOT)
  can(ACTIONS.VIEW, ROUTE_NAMES.PORTFOLIO_CUBE)
  can(ACTIONS.VIEW, ROUTE_NAMES.PORTFOLIOS)

  can(ACTIONS.VIEW, ROUTE_NAMES.WELCOME)
}

const userAbility = (user = {}) => {
  can(ACTIONS.VIEW, ROUTE_NAMES.BONDS)
  can(ACTIONS.VIEW, ROUTE_NAMES.COMPANIES)
  can(ACTIONS.VIEW, ROUTE_NAMES.COMPANY_ROOT)
  can(ACTIONS.VIEW, ROUTE_NAMES.COMPANY_SNAPSHOT)
  can(ACTIONS.VIEW, ROUTE_NAMES.COMPANY_PEER_COMPARISON)
  can(ACTIONS.VIEW, ROUTE_NAMES.EARNINGS_ENGINE)
  can(ACTIONS.VIEW, ROUTE_NAMES.ESG)

  can(ACTIONS.VIEW, ROUTE_NAMES.ETF_ROOT)
  can(ACTIONS.VIEW, ROUTE_NAMES.ETF_CUBE)
  can(ACTIONS.VIEW, ROUTE_NAMES.ETF_SNAPSHOT)
  can(ACTIONS.VIEW, ROUTE_NAMES.ETF_ENGINE)
  can(ACTIONS.VIEW, ROUTE_NAMES.ETF_FACTS)
  can(ACTIONS.VIEW, ROUTE_NAMES.ETF_VIEWER)
  can(ACTIONS.VIEW, ROUTE_NAMES.ETF_ISSUERS)

  can(ACTIONS.VIEW, ROUTE_NAMES.EUMONICS_ROOT)
  can(ACTIONS.VIEW, ROUTE_NAMES.EUMONICS_MARKET)
  can(ACTIONS.VIEW, ROUTE_NAMES.EUMONICS_OPPORTUNITY_FINDER)
  can(ACTIONS.VIEW, ROUTE_NAMES.EUMONICS_PPCC_DIFF)
  can(ACTIONS.VIEW, ROUTE_NAMES.EUMONICS_PPCC_ANALYZER)

  can(ACTIONS.VIEW, ROUTE_NAMES.MARKETS_ROOT)
  can(ACTIONS.VIEW, ROUTE_NAMES.MARKETS_INDUSTRY_VIEW)
  can(ACTIONS.VIEW, ROUTE_NAMES.MARKETS_ISSUER_VIEW)

  can(ACTIONS.VIEW, ROUTE_NAMES.MUTUAL_FUNDS_ROOT)
  can(ACTIONS.VIEW, ROUTE_NAMES.MUTUAL_FUNDS_SNAPSHOT)
  can(ACTIONS.VIEW, ROUTE_NAMES.MUTUAL_FUNDS_ENGINE)
  can(ACTIONS.VIEW, ROUTE_NAMES.MUTUAL_FUNDS_FACTS)
  can(ACTIONS.VIEW, ROUTE_NAMES.MUTUAL_FUNDS_VIEWER)
  can(ACTIONS.VIEW, ROUTE_NAMES.MUTUAL_FUNDS_ISSUERS)

  can(ACTIONS.VIEW, ROUTE_NAMES.PORTFOLIO_ROOT)
  can(ACTIONS.VIEW, ROUTE_NAMES.PORTFOLIO_CUBE)
  can(ACTIONS.VIEW, ROUTE_NAMES.PORTFOLIO_CHECK_UP)
  can(ACTIONS.VIEW, ROUTE_NAMES.PORTFOLIO_HIDDEN_HOLDINGS)

  can(ACTIONS.VIEW, ROUTE_NAMES.SMART_NEWS)
  can(ACTIONS.VIEW, ROUTE_NAMES.SECTORS_TREE)
  can(ACTIONS.VIEW, ROUTE_NAMES.PORTFOLIOS)

  can(ACTIONS.VIEW, ROUTE_NAMES.EXCHANGES)
  can(ACTIONS.VIEW, ROUTE_NAMES.FORECASTS)
  can(ACTIONS.VIEW, ROUTE_NAMES.INDUSTRIES)

  can(ACTIONS.VIEW, ROUTE_NAMES.USER_PORTFOLIO)

  // If user is admin.
  ADMINS.includes(user.sub) && adminAbilities()
}

export const defineAbilitiesFor = user => {
  resetAbilities()

  can(ACTIONS.VIEW, ROUTE_NAMES.ERROR_404)
  can(ACTIONS.VIEW, ROUTE_NAMES.ERROR_403)
  can(ACTIONS.VIEW, ROUTE_NAMES.SECTORS_TREE)

  // User authorized if they have id. Sub contains id.
  user?.sub ? userAbility(user) : guestAbility(user)
  ability.update(rules)
}

// Initial ability
defineAbilitiesFor({})

export default ability
