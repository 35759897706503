export const spec = name => require(`@graphQL/${ name }.graphql`)

export const mockResolvers = {
  Query: {
    test: () => true,
    searchEtfs(_, { searchText }) {
      return [{
        ticker: 'SPY',
        name: 'SPY',
        __typename: 'ETF'
      }, {
        ticker: 'QQQ',
        name: 'QQQ',
        __typename: 'ETF'
      }, {
        ticker: 'XQQ',
        name: 'XQQ',
        __typename: 'ETF'
      }].filter(({
        ticker,
        name
      }) => `${ ticker } ${ name }`.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
    },
    searchCompanies() {
      return []
    }
  }
}
