import { CUBE_TYPES } from './cubes'

export const PORTFOLIO_RIBBON_OPTIONS = [ // todo use cubeRibbons instead
  {
    subtitle: 'SubIndustry',
    code: CUBE_TYPES.PORTFOLIO_SUB_INDUSTRY
  },
  {
    subtitle: 'Industry',
    code: CUBE_TYPES.PORTFOLIO_INDUSTRY
  },
  {
    subtitle: 'Sector',
    code: CUBE_TYPES.PORTFOLIO_SECTOR,
    separator: true
  },
  {
    title: 'Value',
    code: 'value'
  },
  {
    title: 'Performance',
    code: 'performance'
  },
  {
    title: 'Trading Sentiment',
    code: 'timings'
  },
  {
    title: 'Options',
    code: 'options'
  },
  {
    title: 'ESG',
    code: 'esg'
  },
  {
    title: 'Earnings',
    code: 'earnings'
  },
  {
    title: 'Revenue',
    code: 'revenue'
  },
  {
    title: 'Cash Flow',
    code: 'cashFlow'
  },
  {
    title: 'Margins',
    code: 'margin'
  },
  {
    title: 'Growth',
    code: 'growth'
  },
  {
    title: 'Bonds',
    code: 'bonds'
  },
  {
    title: 'Short Interest',
    code: 'short_interest'
  },
  {
    title: 'News Sentiment',
    code: 'news_sentiment'
  },
  {
    title: 'Forecasts',
    code: 'forecasts'
  },
  {
    title: 'Ratio Analysis',
    code: 'ration_analysis'
  },
  {
    title: 'Risk',
    code: 'risk'
  },
  {
    title: 'Risk Concentration',
    code: 'risk_discovery_map'
  },
  {
    title: 'Safety',
    code: 'safety',
    separator: true
  },
  {
    title: 'New York',
    subtitle: 'Exchange',
    code: 'new_york',
    icon: 'mdiWeb'
  }
]

export const RATINGS = { 1: 'A', 2: 'B', 3: 'C', 4: 'D', 5: 'F' }
