import { stages } from '@/utils/store'
import { STATUS_EDITING } from '@/constants/portfolios'
import { USD } from '@/utils/currency'
import defaultState from './state'

const mutations = {
  ...stages.MUTATIONS
}

mutations.setMode = (state, mode) => {
  state.mode = mode
}
mutations.setAssetStatus = (state, { id, status }) => {
  const asset = state.assets.find(_ => _.itemID === id)
  asset && (asset.status = status)
}
mutations.resetInstance = state => {
  Object.assign(state, defaultState())
}
mutations.setPortfolios = (state, portfolios) => {
  state.portfolios = portfolios
}
mutations.setUserPortfolio = (state, portfolio) => {
  state.userPortfolio = portfolio
}
mutations.setPortfolioAssets = (state, assets) => {
  state.assets = assets
}
mutations.addAsset = (state, asset) => {
  const security = asset.security || asset.id && state.securities.find(_ => _.id === asset.id)
  const isValid = security ? !state.assets.some(({ ticker }) => ticker.security_id === security.id) : false
  const id = Date.now().toString(36) + Math.random().toString(36).substring(2)

  const assetToAdd = {
    itemID: id,
    price: asset.price,
    shares: asset.quantity,
    rating: security && security.company.ratings[0]?.value,
    valid: isValid,
    errorMessage: isValid ? null : security ? 'This ticker already exist' : 'Ticker not found',
    dirty: false,
    security_id: security && security.id,
    status: asset.status || STATUS_EDITING.ADDED,
    total: USD(asset.price).multiply(asset.quantity),
    ticker: {
      ticker: security ? security.ticker : asset.ticker,
      name: security && security.company.name,
      security_id: security && security.id
    }
  }

  state.assets.unshift(assetToAdd)
}
mutations.removeAsset = (state, id) => {
  const assetToRemove = state.assets.find(filter => filter.itemID === id)
  assetToRemove && (assetToRemove.status = STATUS_EDITING.REMOVED)
}
mutations.cancelItem = (state, id) => {
  const index = state.assets.findIndex(filter => filter.itemID === id)
  index > -1 && state.assets.splice(index, 1)
}
mutations.updateAsset = (state, { id, update }) => {
  const updateItem = state.assets.find(filter => filter.itemID === id)
  if (updateItem) {
    updateItem.ticker = update.ticker
    updateItem.shares = update.shares
    updateItem.price = update.price
    updateItem.total = USD(update.price).multiply(update.shares)
    updateItem.status === STATUS_EDITING.LOADED && (updateItem.status = STATUS_EDITING.UPDATED)
    updateItem.dirty = true
    updateItem.valid = true
  }
}
mutations.updateSecurities = (state, data) => {
  state.securities = Object.values(data)
}

export default mutations
