const defineStore = (key, path) => {
  return Object.freeze({ key, path })
}

export const APP = defineStore('app', 'app')
export const USER = defineStore('user', 'user')
export const PORTFOLIO = defineStore('portfolio', 'portfolio')
export const USER_PORTFOLIO = defineStore('userPortfolio', 'user/portfolio')
export const PBI_REPORTS = defineStore('powerbi', 'powerBI')
export const ETF = defineStore('etf', 'etf')
export const SEARCH = defineStore('search', 'search')
export const INDUSTRIES = defineStore('industries', 'industries')
/**
 * Industry Map Source
 */
export const CLASSIFICATION = defineStore('classification', 'classification')

/**
 * Core modules supposed to be built in core files.
 * Others will be available to use through lazy loading.
 *
 * @type {Readonly<{path, key}>[]}
 */
export const CORE_MODULES = [
  APP, USER, SEARCH, ETF, PBI_REPORTS, PORTFOLIO
]
