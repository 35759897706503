export default Object.freeze({
  PORTFOLIOS_CUBE_EARNINGS: {
    code: 'portfolios_cube_earnings',
    name: 'Portfolio > Cube > Earnings'
  },
  PORTFOLIOS_CUBE_REVENUE: {
    code: 'portfolios_cube_revenue',
    name: 'Portfolio > Cube > Revenue'
  },
  PORTFOLIOS_CUBE_CASH_FLOW: {
    code: 'portfolios_cube_cash_flow',
    name: 'Portfolio > Cube > Cash Flow'
  },
  PORTFOLIOS_CUBE_MARGIN: {
    code: 'portfolios_cube_margin',
    name: 'Portfolio > Cube > Margins'
  },
  PORTFOLIOS_CUBE_GROWTH: {
    code: 'portfolios_cube_growth',
    name: 'Portfolio > Cube > Growth'
  },
  PORTFOLIOS_CUBE_FORECASTS: {
    code: 'portfolios_cube_forecasts',
    name: 'Portfolio > Cube > Forecasts'
  },
  PORTFOLIOS_CUBE_BONDS: {
    code: 'portfolios_cube_bonds',
    name: 'Portfolio > Cube > Bonds'
  },
  PORTFOLIOS_CUBE_SHORT_INTEREST: {
    code: 'portfolios_cube_short_interest',
    name: 'Portfolio > Cube > Short Interest'
  },
  PORTFOLIOS_CUBE_NEWS_SENTIMENT: {
    code: 'portfolios_cube_news_sentiment',
    name: 'Portfolio > Cube > News Sentiment'
  },
  ETF_CUBE_FUND_FAMILY: {
    code: 'etf_cube_fund_family',
    name: 'ETFs > Cube > Fund Family'
  },
  ETF_CUBE_FAMILY_AND_TYPE: {
    code: 'etf_cube_family_and_type',
    name: 'ETFs > Cube > Family & Type'
  },
  ETF_CUBE_FUND_TYPE: {
    code: 'etf_cube_fund_type',
    name: 'ETFs > Cube > Fund Type'
  },
  ETF_CUBE_SECTOR_FOCUS: {
    code: 'etf_cube_sector_focus',
    name: 'ETFs > Cube > Sector Focus'
  },
  PORTFOLIOS_CUBE_ESG: {
    code: 'portfolios_cube_esg',
    name: 'Portfolio > Cube > ESG'
  },
  PORTFOLIOS_CUBE_RATION_ANALYSIS: {
    code: 'portfolios_cube_ration_analysis',
    name: 'Portfolio > Cube > Ration Analysis'
  },
  PORTFOLIOS_CUBE_RISK: {
    code: 'portfolios_cube_risk',
    name: 'Portfolio > Cube > Risk'
  },
  PORTFOLIOS_CUBE_SAFETY: {
    code: 'portfolios_cube_safety',
    name: 'Portfolio > Cube > Safety'
  },
  PORTFOLIOS_CUBE_SUBINDUSTRY: {
    code: 'portfolios_cube_subindustry',
    name: 'Portfolio > Cube > Subindustry'
  },
  PORTFOLIOS_CUBE_INDUSTRY: {
    code: 'portfolios_cube_industry',
    name: 'Portfolio > Cube > Industry'
  },
  PORTFOLIOS_CUBE_SECTOR: {
    code: 'portfolios_cube_sector',
    name: 'Portfolio > Cube > Sector'
  },
  PORTFOLIOS_CUBE_NEW_YORK: {
    code: 'portfolios_cube_new_york',
    name: 'Portfolio > Cube > New York'
  },
  COMPANY_SNAPSHOT: {
    code: 'company_snapshot',
    name: 'Company > Snapshot'
  },
  COMPANY_PEER_COMPARISON: {
    code: 'peer-comparison',
    name: 'Company > Peer Comparison'
  },
  EARNINGS_ENGINE: {
    code: 'earnings_engine',
    name: 'Earnings Engine'
  },
  ETF_SNAPSHOT: {
    code: 'etf_snapshot',
    name: 'ETFs > ETF Snapshot'
  },
  ETF_ENGINE: {
    code: 'etf_engine',
    name: 'ETFs > ETF Engine'
  },
  ETF_FACTS: {
    code: 'etf_facts',
    name: 'ETFs > ETF Facts'
  },
  ETF_VIEWER: {
    code: 'etf_industry',
    name: 'ETFs > ETF Viewer'
  },
  ETF_ISSUERS: {
    code: 'etf_issuer',
    name: 'ETFs > ETF Issuers'
  },
  FORECASTS: {
    code: 'forecasts',
    name: 'Forecasts'
  },
  INDUSTRIES: {
    code: 'industries',
    name: 'Industries'
  },
  MARKETS: {
    code: 'markets',
    name: 'Markets'
  },
  SMART_NEWS: {
    code: 'smart_news',
    name: 'Smart News'
  },
  MUTUAL_FUNDS_SNAPSHOT: {
    code: 'mutual_funds_snapshot',
    name: 'Mutual Funds -> MF Snapshot'
  },
  MUTUAL_FUNDS_ENGINE: {
    code: 'mutual_funds_engine',
    name: 'Mutual Funds -> MF Engine'
  },
  MUTUAL_FUNDS_FACTS: {
    code: 'mutual_funds_facts',
    name: 'Mutual Funds -> MF Facts'
  },
  MUTUAL_FUNDS_VIEWER: {
    code: 'mutual_funds_viewer',
    name: 'Mutual Funds -> MF Viewer'
  },
  MUTUAL_FUNDS_ISSUERS: {
    code: 'mutual_funds_issuers',
    name: 'Mutual Funds -> MF Issuers'
  },
  EXCHANGES: {
    code: 'exchanges',
    name: 'Exchanges'
  },
  ESG: {
    code: 'esg',
    name: 'ESG'
  },
  BONDS: {
    code: 'bonds',
    name: 'Bonds'
  },
  COMPANIES: {
    code: 'companies2',
    name: 'Companies'
  },
  PORTFOLIOS_CHECK_UP: {
    code: 'portfolios_check_up',
    name: 'Portfolio > Check-up'
  },
  PORTFOLIOS_HIDDEN_HOLDINGS: {
    code: 'portfolios_hidden_holdings',
    name: 'Portfolio > Hidden Holdings'
  }
})
