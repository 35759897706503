import { APP, PBI_REPORTS } from '@/constants/store'
import { useStoreLazy, useStore } from '@store'

export const useInitialization = async () => {
  const { dispatch } = await useStoreLazy(PBI_REPORTS)
  const { commit, state } = useStore(APP)

  const initialize = async () => {
    if (!state.initialization.initialized) {
      await dispatch('reloadReportLinks')
      commit('setInitialized', true)
    }
    return state.initialization.initialized
  }

  return {
    initialize
  }
}
