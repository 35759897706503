import axios from '@axios'
import { SUB_INDUSTRY_URL } from '@/constants/app'

export const STATE = {
  subindustries: [],
  subindustryMap: {},
  subindustriesLoading: false,
  subindustryLoading: false
}

export const ACTIONS = {
  async getSubindustries({ commit }) {
    commit('setSubindustriesLoading', true)
    try {
      const { data } = await axios.get(SUB_INDUSTRY_URL)
      commit('setSubindustries', data)
      return data
    }
    finally {
      commit('setSubindustriesLoading', false)
    }
  },
  async getSubindustry({ commit }, id) {
    commit('setSubindustryLoading', true)
    try {
      const { data } = await axios.get(`${ SUB_INDUSTRY_URL }/${ id }`)
      commit('setSubindustry', { id, data })
      return data
    }
    finally {
      commit('setSubindustryLoading', false)
    }
  }
}

export const MUTATIONS = {
  setSubindustries(state, subindustries) {
    state.subindustries = subindustries
  },
  setSubindustry(state, { id, data }) {
    state.subindustryMap[id] = data
  },
  setSubindustriesLoading(state, bool) {
    state.subindustriesLoading = bool
  },
  setSubindustryLoading(state, bool) {
    state.subindustryLoading = bool
  }
}
