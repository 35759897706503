import { computed, watch } from '@vue/composition-api'
import { useStore } from '@store'
import { APP } from '@/constants/store'

export default function usAppConfig() {
  const { state, commit } = useStore(APP)
  // ------------------------------------------------
  // isVerticalMenuCollapsed
  // ------------------------------------------------
  const isVerticalMenuCollapsed = computed({
    get: () => state.layout.isVerticalMenuCollapsed,
    set: val => {
      commit('UPDATE_VERTICAL_MENU_COLLAPSED', val)
    }
  })

  // ------------------------------------------------
  // RTL
  // ------------------------------------------------
  const isRTL = computed({
    get: () => state.layout.isRTL,
    set: val => {
      commit('TOGGLE_RTL', val)
    }
  })

  // ------------------------------------------------
  // Skin
  // ------------------------------------------------
  const skin = computed({
    get: () => state.layout.skin,
    set: val => {
      commit('UPDATE_SKIN', val)
    }
  })

  const skinClasses = computed(() => {
    if (skin.value === 'bordered') {
      return 'bordered-layout'
    }
    if (skin.value === 'semi-dark') {
      return 'semi-dark-layout'
    }

    // Do not return any class for dark layout because dark layout updates class in body
    // Do not return any class for light layout as that is default layout
    return null
  })

  // ------------------------------------------------
  // routerTransition
  // ------------------------------------------------
  const routerTransition = computed({
    get: () => state.layout.routerTransition,
    set: val => {
      commit('UPDATE_ROUTER_TRANSITION', val)
    }
  })

  // *===============================================---*
  // *--------- LAYOUT ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // layoutType
  // ------------------------------------------------

  const layoutType = computed({
    get: () => state.layout.type,
    set: val => {
      commit('UPDATE_LAYOUT_TYPE', val)
    }
  })

  // Reset skin if skin is semi-dark and move to horizontal layout
  watch(layoutType, val => {
    if (val === 'horizontal' && skin.value === 'semi-dark') {
      skin.value = 'light'
    }
  })

  // ------------------------------------------------
  // Content Width (Full/Boxed)
  // ------------------------------------------------
  const contentWidth = computed({
    get: () => state.layout.contentWidth,
    set: val => {
      commit('UPDATE_CONTENT_WIDTH', val)
    }
  })

  // ------------------------------------------------
  // isNavMenuHidden
  // ------------------------------------------------
  const isNavMenuHidden = computed({
    get: () => state.layout.menu.hidden,
    set: val => {
      commit('UPDATE_NAV_MENU_HIDDEN', val)
    }
  })

  // *===============================================---*
  // *--------- NAVBAR ---------------------------------------*
  // *===============================================---*

  const navbarBackgroundColor = computed({
    get: () => state.layout.navbar.backgroundColor,
    set: val => {
      commit('UPDATE_NAVBAR_CONFIG', { backgroundColor: val })
    }
  })

  const navbarType = computed({
    get: () => state.layout.navbar.type,
    set: val => {
      commit('UPDATE_NAVBAR_CONFIG', { type: val })
    }
  })

  // *===============================================---*
  // *--------- FOOTER ---------------------------------------*
  // *===============================================---*

  const footerType = computed({
    get: () => state.layout.footer.type,
    set: val => {
      commit('UPDATE_FOOTER_CONFIG', { type: val })
    }
  })

  return {
    isVerticalMenuCollapsed,
    isRTL,
    skin,
    skinClasses,
    routerTransition,

    // Navbar
    navbarBackgroundColor,
    navbarType,

    // Footer
    footerType,

    // Layout
    layoutType,
    contentWidth,
    isNavMenuHidden,
    shallShowOverlay: state.shallShowOverlay
  }
}
