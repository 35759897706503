import { industry, sector, subIndustry } from '@/utils/store'

const mutations = {
  ...industry.MUTATIONS,
  ...subIndustry.MUTATIONS,
  ...sector.MUTATIONS
}

mutations.setCompanies = (state, { data, code }) => {
  state.companiesData = { ...state.companiesData, [code]: data }
}
mutations.setPortfolio = (state, { data, id }) => {
  state.portfolios = { ...state.portfolios, [id]: data }
}
mutations.setLoading = (state, status) => {
  state.loading = status
}
mutations.updateSecurities = (state, data) => {
  const { securities } = state
  state.securities = Object.assign({}, securities, data)
}

export default mutations
