export const PORTFOLIO_TYPES = {
  ETF: 'ETF',
  SECTOR: 'Sector',
  PORTFOLIO: 'Portfolio',
  INDUSTRY: 'Industry',
  SUB_INDUSTRY: 'Sub Industry'
}

export const MODES = Object.freeze({
  EDIT: 'EDIT',
  VIEW: 'VIEW'
})

export const STATUS_EDITING = Object.freeze({
  LOADED: 'LOADED',
  ADDED: 'ADDED',
  REMOVED: 'REMOVED',
  UPDATED: 'UPDATED'
})
