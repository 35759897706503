import Vue from 'vue'
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import { useStore, default as store } from './store'
import App from './App.vue'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

Vue.use(VueVirtualScroller)

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'
import { createProvider } from '@/libs/vue-apollo'
import { USER } from '@/constants/store'


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// TODO: needs to be moved to src/libs/apollo along with `createProvider`.
const { state } = useStore(USER)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  apolloProvider: createProvider(state.token),
  render: h => h(App)
}).$mount('#app')
