import { ROUTE_NAMES } from '@/constants/routes'

const ADMIN_PATH_ROOT = '/admin'

export default [
  {
    path: ADMIN_PATH_ROOT,
    name: ROUTE_NAMES.ADMIN_ROOT,
    component: () => import('@/views/admin/Admin.vue'),
    meta: {
      pageTitle: 'Admin',
      home: true
    },
    children: [
      {
        path: 'pbi',
        name: ROUTE_NAMES.ADMIN_PBI,
        component: () => import('@/views/pages/config/PBI.vue'),
        icon: 'BarChartIcon',
        meta: {
          pageTitle: 'Power BI',
          home: true,
          breadcrumb: [
            {
              text: 'Admin',
              to: ADMIN_PATH_ROOT
            },
            {
              text: 'Power BI',
              active: true
            }
          ]
        }
      }
    ]
  }
]
