const mutations = {}

mutations.UPDATE_WINDOW_WIDTH = (state, val) => {
  state.windowWidth = val
}
mutations.TOGGLE_OVERLAY = (state, val) => {
  state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
}

// This section came from 'app-config'.
mutations.setInitialized = (state, initialized) => {
  state.initialization.initialized = initialized
}
mutations.TOGGLE_RTL = state => {
  state.layout.isRTL = !state.layout.isRTL
  document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
}
mutations.UPDATE_SKIN = (state, skin) => {
  state.layout.skin = skin

  // Update value in localStorage
  localStorage.setItem('vuexy-skin', skin)

  // Update DOM for dark-layout
  if (skin === 'dark') {
    document.body.classList.add('dark-layout')
  }
  else if (document.body.className.match('dark-layout')) {
    document.body.classList.remove('dark-layout')
  }
}
mutations.UPDATE_ROUTER_TRANSITION = (state, val) => {
  state.layout.routerTransition = val
}
mutations.UPDATE_LAYOUT_TYPE = (state, val) => {
  state.layout.type = val
}
mutations.UPDATE_CONTENT_WIDTH = (state, val) => {
  state.layout.contentWidth = val
}
mutations.UPDATE_NAV_MENU_HIDDEN = (state, val) => {
  state.layout.menu.hidden = val
}
mutations.UPDATE_NAVBAR_CONFIG = (state, obj) => {
  Object.assign(state.layout.navbar, obj)
}
mutations.UPDATE_FOOTER_CONFIG = (state, obj) => {
  Object.assign(state.layout.footer, obj)
}

// This section came from 'vertical-menu'.
mutations.UPDATE_VERTICAL_MENU_COLLAPSED = (state, val) => {
  state.layout.isVerticalMenuCollapsed = val
}
export default mutations
