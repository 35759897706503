/**
 * Sorry, for such complex definition.
 *
 * This allows to define getters and operations dynamically.
 * We can be sure that those operations will be consistent with state and getters.
 * It helps to avoid duplications of code.
 *
 * Basic idea of the module is to be responsible for the data we call "industries", "industry map",
 * or "classification". It consists of data types "sectors", "industries", "sub-industries",
 * "companies", "securities", "exchanges". Later "etfs" and "etfIssuers" will be added.
 *
 * It's responsible for all aspects including realtime data and hierarchy.
 **/

const defineEntity = (name, idKey) => ({ name, idKey, parents: [], children: [] })
const defineChild = (definition, parentIdPath, prop) => ({ ...definition, parentIdPath, prop })
const defineParent = (definition, parentIdPath, prop) => ({ ...definition, parentIdPath, prop })
/**
 * Classical join operation.
 */
const join = ([leftEntity, leftAs], [rightEntity, rightAs], on) => {
  leftEntity.children.push(defineChild(rightEntity, on, rightAs))
  rightEntity.parents.push(defineParent(leftEntity, on, leftAs))
}

/**
 * The heart of the entire module. It is basis for everything including dynamic operations.
 * Please be careful changing some of these.
 * On the other hand you can easily introduce another entity by adding it to the list and
 * configure its dependencies.
 */
export const ENTITY_TYPES = {
  sectors: defineEntity('sectors', 'id'),
  industries: defineEntity('industries', 'id'),
  subIndustries: defineEntity('subIndustries', 'id'),
  companies: defineEntity('companies', 'id'),
  securities: defineEntity('securities', 'ticker'),
  exchanges: defineEntity('exchanges', 'id'),
  etfs: defineEntity('etfs', 'id')
}
join([ENTITY_TYPES.sectors, 'sector'], [ENTITY_TYPES.industries, 'industries'], 'sector.id')
join([ENTITY_TYPES.sectors, 'sector'], [ENTITY_TYPES.companies, 'companies'], 'sector.id')

join([ENTITY_TYPES.industries, 'industry'], [ENTITY_TYPES.subIndustries, 'subIndustries'], 'industry.id')
join([ENTITY_TYPES.industries, 'industry'], [ENTITY_TYPES.companies, 'companies'], 'industry.id')

join([ENTITY_TYPES.subIndustries, 'subIndustry'], [ENTITY_TYPES.companies, 'companies'], 'subIndustry.id')

join([ENTITY_TYPES.companies, 'company'], [ENTITY_TYPES.securities, 'securities'], 'company.id')
join([ENTITY_TYPES.exchanges, 'exchange'], [ENTITY_TYPES.securities, 'securities'], 'exchange.id')

export const ENTITY_TYPE_LIST = Object.values(ENTITY_TYPES)

export default () => {
  const state = {
    total: null
  }

  for (const entity of ENTITY_TYPE_LIST) {
    state[entity.name] = {
      loading: false,
      loaded: false,
      entities: new Map(),
      list: [],
      changed: 1
    }
  }

  return state
}
