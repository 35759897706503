import useApollo from '@core/hooks/apollo'
import * as queries from './queries'

const { ainstein } = useApollo()
const loaders = new Map()
const stream = new Map()
const SNAPSHOT_STREAM_OBSERVER = 'observers:SNAP_UPDATES'
const SNAPSHOT_STREAM_SUBSCRIPTION = 'subscriptions:SNAP_UPDATES'

const actions = {}

/**
 * Save promise to the map 'loaders'.
 * It will mark appropriate flags as 'started' when the loader
 * was started its work and 'finished' when it's done.
 */
actions.registerLoader = ({ commit }, { type, loader }) => {
  commit('startLoader', type)
  loaders.set(type, loader())

  return loaders.get(type).then(result => {
    commit('finishLoader', type)
    return result
  })
}

/**
 * Will return promise of it was registered already (read as "was called").
 * Will call the loader and pass results to "handler" otherwise.
 */
actions.loaderOrWork = async ({ dispatch }, { type, loader, handler }) => {
  // If handler is not provided then it may mean that handler was implemented as part of the loader.
  if (!handler) {
    handler = loader => loader
  }
  return loaders.get(type) || handler(await dispatch('registerLoader', { type, loader }))
}

/**
 * Incremental loading data using provided query by incremental change 'limit' and 'offset'.
 */
actions.batchLoading = async ({ dispatch }, params) => {
  const { query, total, offset, handler, pageSize, errorPolicy } = params
  try {
    const result = await ainstein.load(query, { limit: pageSize, offset }, {
      errorPolicy: errorPolicy || 'all'
    })
    handler(result.data)
  }
  catch (e) {
    console.error(e)
  }
  params.offset += pageSize
  if (total > params.offset) {
    return dispatch('batchLoading', params)
  }
}

actions.initDataLoading = async ({ dispatch }) => {
  await dispatch('loadAllSectors')
  await dispatch('loadAllIndustries')
  await dispatch('loadAllSubIndustries')
  await dispatch('loadAllExchanges')
  await dispatch('loadAllCompaniesIncrementally')
  // await dispatch('loadAllSecuritiesIncrementally')
}

actions.loadAllSectors = ({ commit, dispatch }) => {
  const type = 'sectors'
  return dispatch('loaderOrWork', {
    type,
    loader: () => ainstein.load(queries.SECTORS),
    handler: ({ data: { sectors: items } }) => {
      commit('objectsToEntities', { type, items })
      commit('runCollectionChange', type)
    }
  })
}

actions.loadAllIndustries = ({ commit, dispatch }) => {
  const type = 'industries'
  return dispatch('loaderOrWork', {
    type,
    loader: () => ainstein.load(queries.INDUSTRIES),
    handler: ({ data: { industries: items } }) => {
      commit('objectsToEntities', { type, items })
      commit('mapIndustries')
      commit('runCollectionChange', type)
    }
  })
}

actions.loadAllSubIndustries = ({ commit, dispatch }) => {
  const type = 'subIndustries'
  return dispatch('loaderOrWork', {
    type,
    loader: () => ainstein.load(queries.SUB_INDUSTRIES),
    handler: ({ data: { subIndustries: items } }) => {
      commit('objectsToEntities', { type, items })
      commit('mapSubIndustries')
      commit('runCollectionChange', type)
    }
  })
}

actions.loadAllCompaniesIncrementally = ({ commit, dispatch }, pageSize = 500) => {
  const type = 'companies'
  return dispatch('loaderOrWork', {
    type,
    loader: async () => {
      const result = await ainstein.load(queries.COMPANIES_TOTAL)
      const total = result.data.listCompanies.total
      commit('setTotal', total)
      return dispatch('batchLoading', {
        query: queries.COMPANIES,
        offset: 0,
        total: total,
        pageSize,
        errorPolicy: 'ignore',
        handler: ({ listCompanies: { items } }) => {
          commit('objectsToEntities', { type, items })
          commit('runCollectionChange', type)
          commit('runCollectionChange', 'securities')
        }
      })
    },
    handler() {
      // commit('mapCompanies')
    }
  })
}

actions.loadAllExchanges = ({ commit, dispatch }) => {
  const type = 'exchanges'
  return dispatch('loaderOrWork', {
    type,
    loader: () => ainstein.load(queries.EXCHANGES),
    handler: ({ data: { exchanges: items } }) => {
      commit('objectsToEntities', { type, items })
    }
  })
}

actions.loadAllSecuritiesIncrementally = ({ commit, dispatch }, pageSize = 200) => {
  const type = 'securities'
  return dispatch('loaderOrWork', {
    type,
    loader: async () => {
      const result = await ainstein.load(queries.SECURITIES_TOTAL)
      await dispatch('batchLoading', {
        query: queries.SECURITIES,
        offset: 0,
        total: result.data.listSecurities.total,
        pageSize,
        errorPolicy: 'ignore',
        handler: ({ listSecurities: { items } }) => {
          commit('objectsToEntities', { type, items })
          // commit('mapSecurities', items)
        }
      })
    },
    handler() {
      // commit('runCollectionChange', type)
    }
  })
}

actions.loadAllETFsIncrementally = ({ commit, dispatch }, pageSize = 500) => {
  const type = 'etfs'
  return dispatch('loaderOrWork', {
    type,
    loader: async () => {
      const result = await ainstein.load(queries.ETFS_TOTAL)
      return dispatch('batchLoading', {
        query: queries.ETFS,
        offset: 0,
        total: result.data.listEtfs.total,
        pageSize,
        errorPolicy: 'ignore',
        handler: ({ listEtfs: { items } }) => {
          commit('objectsToEntities', { type, items })
          commit('runCollectionChange', type)
        }
      })
    },
    handler() {
      // commit('mapCompanies')
    }
  })
}

actions.startSnapshotsStream = () => {
  if (!stream.get(SNAPSHOT_STREAM_OBSERVER)) {
    stream.set(SNAPSHOT_STREAM_OBSERVER, ainstein.getObserver(queries.QUOTE_SNAPSHOT, {
      tickers: ['MSFT']
    }))
  }

  // TODO: implement handling of realtime data with buffer.

  if (!stream.get(SNAPSHOT_STREAM_SUBSCRIPTION)) {
    stream.set(SNAPSHOT_STREAM_SUBSCRIPTION, stream
      .get(SNAPSHOT_STREAM_OBSERVER)
      .subscribe(() => {
      /**
       * {
       *     "data": {
       *         "quoteSnapshot": {
       *             "ticker": "MSFT",
       *             "price": 222.39,
       *             "volume": 3854514,
       *             "change": -6.71,
       *             "percentageChange": -2.93,
       *             "open": 236.92,
       *             "high": 227.5,
       *             "low": 221.775,
       *             "timestamp": "2023-01-05T20:59:40.487Z"
       *         }
       *     }
       * }
       */
      }))
  }
}

actions.stopSnapshotsStream = () => {
  if (stream.get(SNAPSHOT_STREAM_SUBSCRIPTION)) {
    stream.get(SNAPSHOT_STREAM_SUBSCRIPTION).unsubscribe()
    stream.delete(SNAPSHOT_STREAM_SUBSCRIPTION)
  }
}

export default actions
