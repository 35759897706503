import currency from 'currency.js'

export const USD = value => currency(value, {
  symbol: '$',
  decimal: '.',
  separator: ',',
  pattern: '!#',
  negativePattern: '-!#',
  precision: 0
})

export const NUMBERS = value => currency(value, {
  symbol: '',
  separator: ',',
  precision: 0
})

export const FINANCE_USD = value => currency(value, {
  symbol: '$',
  separator: ',',
  precision: 0,
  negativePattern: '(!#)'
})

export const FINANCE_PERCENT = value => currency(value, {
  symbol: '',
  separator: ',',
  precision: 0,
  pattern: '+!#%',
  negativePattern: '(!#%)'
})
