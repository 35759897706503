export const RIBBON_TABS = { // todo use cubeRibbons instead
  timing: [
    { code: 'Bollinger_Bands', title: 'Bollinger Bands' },
    { code: 'Stochastics', title: 'Stochastics' },
    { code: 'MACD', title: 'MACD' },
    { code: 'RSI', title: 'RSI' }
  ],
  options: [
    { code: 'POY', title: 'POY' },
    { code: 'POR', title: 'POR' },
    { code: 'COY', title: 'COY' },
    { code: 'COR', title: 'COR' },
    { code: 'PPCC', title: 'PPCC' }
  ]
}
