import { CONTEXTS } from '@/constants/search'
import PbiReports from '@/constants/pbi-reports'
import { ROUTE_NAMES } from '@/constants/routes'

export const COMPANY_SNAPSHOT = {
  path: 'snapshot',
  name: ROUTE_NAMES.COMPANY_SNAPSHOT,
  component: () => import('@core/components/power-bi/PowerBiView.vue'),
  props: route => ({ report: PbiReports.COMPANY_SNAPSHOT.code, ...route.params }),
  requiredProps: [CONTEXTS.TICKER.storeKey],
  meta: {
    context: CONTEXTS.TICKER
  }
}

export const COMPANY_PEER_COMPARISON = {
  path: 'peer-comparison',
  name: ROUTE_NAMES.COMPANY_PEER_COMPARISON,
  component: () => import('@core/components/e-chart/ChartPageWrapper.vue'),
  props: () => ({ chartType: 'performance' }),
  meta: {
    context: CONTEXTS.TICKER
  }
}

export default [
  {
    path: `/companies/:${ CONTEXTS.TICKER.paramKey }`,
    name: ROUTE_NAMES.COMPANY_ROOT,
    component: () => import('@/views/company/Company.vue'),
    children: [COMPANY_SNAPSHOT, COMPANY_PEER_COMPARISON]
  }
]
