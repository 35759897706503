import PbiReports from '@/constants/pbi-reports'
import { ROUTE_NAMES } from '@/constants/routes'

export default [
  {
    path: `/markets`,
    name: ROUTE_NAMES.MARKETS_ROOT,
    component: () => import('@core/components/power-bi/PowerBiView.vue'),
    props: () => ({ report: PbiReports.MARKETS.code, portfolioId: 1 /* unused, but necessary */ }),
    meta: {
      searchUnavailable: true
    }
  }
]
