import { ROUTE_NAMES } from '@/constants/routes'

export default [
  {
    path: '/error-404',
    name: ROUTE_NAMES.ERROR_404,
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/error-403',
    name: ROUTE_NAMES.ERROR_403,
    component: () => import('@/views/error/Error403.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read'
    }
  },
  {
    path: '/login',
    name: ROUTE_NAMES.LOGIN,
    component: () => import('@/views/pages/authentication/Login'),
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/my-portfolio',
    name: ROUTE_NAMES.USER_PORTFOLIO,
    component: () => import('@/views/pages/config/Portfolios'),
    icon: 'ClipboardIcon',
    meta: {
      pageTitle: 'Portfolio',
      home: true
    }
  },
  {
    path: '/welcome',
    name: ROUTE_NAMES.WELCOME,
    component: () => import('@/views/pages/Guest/WelcomePage'),
    meta: {
      pageTitle: 'Welcome Page',
      searchUnavailable: true
    }
  }
]
