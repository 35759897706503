import { stages } from '@/utils/store'

const mutations = {
  ...stages.MUTATIONS
}
mutations.setReportLinks = (state, reportLinks) => {
  state.reportLinks = reportLinks
}

export default mutations
