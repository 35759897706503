import PbiReports from '@/constants/pbi-reports'
import { ROUTE_NAMES } from '@/constants/routes'

export default [
  {
    path: `/mutual-funds`,
    name: ROUTE_NAMES.MUTUAL_FUNDS_ROOT,
    component: () => import('@/views/mutual-funds/MFunds.vue'),
    meta: {
      searchUnavailable: true
    },
    children: [
      {
        path: `snapshot`,
        name: ROUTE_NAMES.MUTUAL_FUNDS_SNAPSHOT,
        component: () => import('@core/components/power-bi/PowerBiView.vue'),
        props: () => ({
          report: PbiReports.MUTUAL_FUNDS_SNAPSHOT.code,
          portfolioId: 1 /* unused, but necessary */
        }),
        meta: {
          searchUnavailable: true
        }
      },
      {
        path: `issuers`,
        name: ROUTE_NAMES.MUTUAL_FUNDS_ISSUERS,
        component: () => import('@core/components/power-bi/PowerBiView.vue'),
        props: () => ({
          report: PbiReports.MUTUAL_FUNDS_ISSUERS.code,
          portfolioId: 1 /* unused, but necessary */
        }),
        meta: {
          searchUnavailable: true
        }
      },
      {
        path: `engine`,
        name: ROUTE_NAMES.MUTUAL_FUNDS_ENGINE,
        component: () => import('@core/components/power-bi/PowerBiView.vue'),
        props: () => ({
          report: PbiReports.MUTUAL_FUNDS_ENGINE.code,
          portfolioId: 1 /* unused, but necessary */
        }),
        meta: {
          searchUnavailable: true
        }
      },
      {
        path: `facts`,
        name: ROUTE_NAMES.MUTUAL_FUNDS_FACTS,
        component: () => import('@core/components/power-bi/PowerBiView.vue'),
        props: () => ({
          report: PbiReports.MUTUAL_FUNDS_FACTS.code,
          portfolioId: 1 /* unused, but necessary */
        }),
        meta: {
          searchUnavailable: true
        }
      },
      {
        path: `viewer`,
        name: ROUTE_NAMES.MUTUAL_FUNDS_VIEWER,
        component: () => import('@core/components/power-bi/PowerBiView.vue'),
        props: () => ({
          report: PbiReports.MUTUAL_FUNDS_VIEWER.code,
          portfolioId: 1 /* unused, but necessary */
        }),
        meta: {
          searchUnavailable: true
        }
      }
    ]
  }
]
