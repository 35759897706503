import { ROUTE_NAMES } from '@/constants/routes'
import { CONTEXTS } from '@/constants/search'

export default [
  {
    path: `/active-trading`,
    name: ROUTE_NAMES.ACTIVE_TRADING,
    component: () => import('@pages/ActiveTrading/ShowPage.vue'),
    requiredProps: [CONTEXTS.TICKER.storeKey],
    meta: {
      context: CONTEXTS.TICKER
    }
  }
]
