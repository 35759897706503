import { stages } from '@/utils/store'
import { MODES } from '@/constants/portfolios'


/**
 * It can be a function that returns an object,
 * if you think that this module can be instantiated.
 */
export default () => ({
  ...stages.STATE(),
  userPortfolio: null,
  portfolios: [],
  assets: [],
  mode: MODES.VIEW,
  securities: []
})
