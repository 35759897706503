import { ROUTE_NAMES } from '@/constants/routes'

export default [
  {
    path: `/eumonics`,
    name: ROUTE_NAMES.EUMONICS_ROOT,
    component: () => import('@/views/eumonics/Eumonics.vue'),
    children: [
      {
        path: 'market',
        name: ROUTE_NAMES.EUMONICS_MARKET,
        component: () => import('@/views/eumonics/EumonicsEmbed.vue'),
        props: { path: 'ppcc-market' }
      },
      {
        path: 'opportunity-finder',
        name: ROUTE_NAMES.EUMONICS_OPPORTUNITY_FINDER,
        component: () => import('@/views/eumonics/EumonicsEmbed.vue'),
        props: { path: 'opportunity-finder' }
      },
      {
        path: 'ppcc-difference',
        name: ROUTE_NAMES.EUMONICS_PPCC_DIFF,
        component: () => import('@/views/eumonics/EumonicsEmbed.vue'),
        props: { path: 'ppcc-diff/new' }
      },
      {
        path: 'ppcc-analyzer',
        name: ROUTE_NAMES.EUMONICS_PPCC_ANALYZER,
        component: () => import('@/views/eumonics/EumonicsEmbed.vue'),
        props: { path: 'ppcc/new' }
      }
    ]
  }
]
