import Vue from 'vue'
import VueApollo from 'vue-apollo'

import { SubscriptionClient } from 'subscriptions-transport-ws'

import { createUploadLink } from 'apollo-upload-client'

import { from, split, InMemoryCache, ApolloClient } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import { EUMONICS_TOKEN, AINSTEIN_GQL_HTTP, EUMONICS_GQL_HTTP, AINSTEIN_GQL_WS } from '@/constants/app'
import { mockResolvers } from '@/utils/graphql'

const CLIENT_ID_AINSTEIN = 'Ainstein'
const CLIENT_ID_EUMONICS = 'Eumonics'

Vue.use(VueApollo)

const notify = (title, text) => {
  // TODO: get back to ToastificationContent when server API is stable and clean of stupid errors.
  console.error(title, text)
}

function createApolloClient({ getAuth, httpEndpoint, wsEndpoint, clientId }) {
  let wsClient

  const cache = new InMemoryCache({
    addTypename: false,
    resultCaching: false
  })

  // TODO: describe what this is needed for.
  if (typeof window !== 'undefined') {
    const state = window.__APOLLO_STATE__
    if (state && state[clientId]) {
      cache.restore(state[clientId])
    }
  }

  const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        notify(`GQL Request Error`, message)
        // eslint-disable-next-line no-console
        console.error(`[GraphQL error]: Message: ${ message }, Path: ${ path }, Location:`, locations)
      })
    }
    if (networkError) {
      notify(`GQL Network Error`, networkError.message)
      // eslint-disable-next-line no-console
      console.error(`[Network error]: ${ networkError }`)
    }
  })

  const httpLink = createUploadLink({ uri: httpEndpoint })

  const authLink = setContext(async (_, { headers }) => {
    const Authorization = await getAuth()
    const authorizationHeader = Authorization ? { Authorization } : {}
    return {
      headers: {
        ...headers,
        ...authorizationHeader
      }
    }
  })

  let link = from([onErrorLink, authLink, httpLink])

  if (wsEndpoint) {
    wsClient = new SubscriptionClient(wsEndpoint, {
      reconnect: true,
      lazy: true, // to not connect without request
      connectionParams: () => {
        const Authorization = getAuth()
        return Authorization ? { Authorization, headers: { Authorization } } : {}
      }
    })

    const wsLink = new WebSocketLink(wsClient)

    link = split(arg => {
      const { query } = arg
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition'
        && (
          definition.operation === 'subscription'
          // To be able to send requests via WS.
          || arg.getContext().throughWS
        )
    }, wsLink, link)
  }

  const apolloClient = new ApolloClient({
    link,
    cache,
    typeDefs: require(`@graphQL/${ clientId === CLIENT_ID_AINSTEIN ? 'Ainstein' : 'Eumonics' }/_mocks.graphql`),
    resolvers: mockResolvers
  })

  apolloClient.wsClient = wsClient

  return apolloClient
}

export const APOLLO_CLIENTS = {
  // will be filled in createProvider
}

export const createProvider = token => {
  APOLLO_CLIENTS.AINSTEIN = createApolloClient({
    getAuth() {
      return `Token ${ token }`
    },
    httpEndpoint: AINSTEIN_GQL_HTTP,
    wsEndpoint: AINSTEIN_GQL_WS,
    clientId: CLIENT_ID_AINSTEIN
  })
  APOLLO_CLIENTS.EUMONICS = createApolloClient({
    getAuth() {
      return `Token ${ EUMONICS_TOKEN }`
    },
    httpEndpoint: EUMONICS_GQL_HTTP,
    clientId: CLIENT_ID_EUMONICS
  })
  return new VueApollo({
    clients: APOLLO_CLIENTS
  })
}

// Manually call this when user log in
export function onLogin() {
  return Promise.all(Object.values(APOLLO_CLIENTS).map(client => {
    const { wsClient } = client
    wsClient && wsClient.close(true)
    return client.resetStore()
  })).catch(e => {
    // eslint-disable-next-line no-console
    console.error('%cError on cache reset (login)', 'color: orange;', e.message)
  })
}

// Manually call this when user log out
export const onLogout = onLogin
