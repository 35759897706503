import { ENTITY_TYPE_LIST } from './state'

const getters = {}

/**
 * Defines something like this:
 * ```
 * getters.sectors = state => {
 *   return state.sectors.changed && Array.from(state.sectors.entities.values())
 * }
 * ```
 *
 * Sorry, for such complex definition.
 */
ENTITY_TYPE_LIST.forEach(type => {
  getters[type.name] = state => state[type.name].list
})

// General loading flag.
// Represents status of loading of the entire catalog.
// True if any of children loaders is true.
getters.loading = state => {
  return ENTITY_TYPE_LIST.some(type => state[type.name].loading)
}

// General flag. `TRUE` while all child flags `loaded` is `TRUE`.
getters.loaded = state => {
  return ENTITY_TYPE_LIST.every(type => state[type.name].loaded)
}

export default getters
