import { stages } from '@/utils/store'

const getters = {
  ...stages.GETTERS
}

getters.isReportExists = ({ reportLinks }) => {
  return code => {
    return reportLinks.some(({ sectionCode }) => sectionCode === code)
  }
}

export default getters
