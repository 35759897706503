/**
 * It can be a function that returns an object,
 * if you think that this module can be instantiated.
 */
export default {
  companiesData: {},
  portfolios: {},
  loading: false,
  maxBubbleSize: 90,
  etfMap: {}
}
