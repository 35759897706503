import PbiReports from '@/constants/pbi-reports'
import { ROUTE_NAMES } from '@/constants/routes'

export default [
  {
    path: `/exchanges`,
    name: ROUTE_NAMES.EXCHANGES,
    component: () => import('@core/components/power-bi/PowerBiView.vue'),
    props: () => ({
      report: PbiReports.EXCHANGES.code,
      // Unused, but required.
      portfolioId: 1
    }),
    meta: {
      searchUnavailable: true
    }
  }
]
