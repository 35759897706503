const mutations = {}

mutations.setCompanies = (state, { data, code }) => {
  state.companiesData = { ...state.companiesData, [code]: data }
}
mutations.setPortfolio = (state, { data, id }) => {
  state.portfolios = { ...state.portfolios, [id]: data }
}
mutations.setLoading = (state, status) => {
  state.loading = status
}
mutations.setMaxBubbleSize = (state, value) => {
  state.maxBubbleSize = value
}
mutations.setETFInfo = (state, { ticker, value }) => {
  const { etfMap } = state
  etfMap[ticker] = value
  state.etfMap = { ...etfMap }
}
export default mutations
