import PbiReports from '@/constants/pbi-reports'
import { RIBBONED_CUBE_TYPES } from '@/constants/cubes'
import { ROUTE_NAMES } from '@/constants/routes'
import { CONTEXTS, DEFAULT_SEARCH_ETF } from '@/constants/search'

export default [
  {
    path: '/etf',
    name: ROUTE_NAMES.ETF_ROOT,
    component: () => import('@/views/etf/ETF.vue'),
    children: [
      {
        path: `:${ CONTEXTS.ETF.paramKey }/cube`,
        name: ROUTE_NAMES.ETF_CUBE,
        component: () => import('@core/components/cubes/RibbonedCube.vue'),
        meta: {
          contentRenderer: 'sidebar-left-detached',
          context: CONTEXTS.ETF
        },
        props: () => ({ type: RIBBONED_CUBE_TYPES.ETF }),
        requiredProps: [{
          source: CONTEXTS.ETF.storeKey,
          target: CONTEXTS.ETF.paramKey
        }]
      },
      {
        path: 'snapshot',
        name: ROUTE_NAMES.ETF_SNAPSHOT,
        component: () => import('@core/components/power-bi/PowerBiView.vue'),
        meta: {
          context: CONTEXTS.ETF,
          searchUnavailable: true
        },
        props: route => ({
          report: PbiReports.ETF_SNAPSHOT.code,
          portfolioId: DEFAULT_SEARCH_ETF, ...route.params
        }),
        requiredProps: [{
          source: CONTEXTS.ETF.storeKey,
          target: CONTEXTS.ETF.paramKey
        }]
      },
      {
        path: 'engine',
        name: ROUTE_NAMES.ETF_ENGINE,
        component: () => import('@core/components/power-bi/PowerBiView.vue'),
        meta: {
          context: CONTEXTS.ETF,
          searchUnavailable: true
        },
        props: route => ({
          report: PbiReports.ETF_ENGINE.code,
          portfolioId: DEFAULT_SEARCH_ETF, ...route.params
        }),
        requiredProps: [{
          source: CONTEXTS.ETF.storeKey,
          target: CONTEXTS.ETF.paramKey
        }]
      },
      {
        path: 'facts',
        name: ROUTE_NAMES.ETF_FACTS,
        component: () => import('@core/components/power-bi/PowerBiView.vue'),
        meta: {
          context: CONTEXTS.ETF,
          searchUnavailable: true
        },
        props: route => ({
          report: PbiReports.ETF_FACTS.code,
          portfolioId: DEFAULT_SEARCH_ETF, ...route.params
        }),
        requiredProps: [{
          source: CONTEXTS.ETF.storeKey,
          target: CONTEXTS.ETF.paramKey
        }]
      },
      {
        path: 'viewer',
        name: ROUTE_NAMES.ETF_VIEWER,
        component: () => import('@core/components/power-bi/PowerBiView.vue'),
        meta: {
          context: CONTEXTS.ETF,
          searchUnavailable: true
        },
        props: route => ({
          report: PbiReports.ETF_VIEWER.code,
          portfolioId: DEFAULT_SEARCH_ETF, ...route.params
        }),
        requiredProps: [{
          source: CONTEXTS.ETF.storeKey,
          target: CONTEXTS.ETF.paramKey
        }]
      },
      {
        path: 'issuers',
        name: ROUTE_NAMES.ETF_ISSUERS,
        component: () => import('@core/components/power-bi/PowerBiView.vue'),
        meta: {
          context: CONTEXTS.ETF,
          searchUnavailable: true
        },
        props: route => ({
          report: PbiReports.ETF_ISSUERS.code,
          portfolioId: DEFAULT_SEARCH_ETF, ...route.params
        }),
        requiredProps: [{
          source: CONTEXTS.ETF.storeKey,
          target: CONTEXTS.ETF.paramKey
        }]
      }
    ]
  }
]
