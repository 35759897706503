export const ROUTE_NAMES = {
  ACTIVE_TRADING: 'Active Trading',
  ADMIN_ROOT: 'Admin',
  ADMIN_PBI: 'PBI',
  BONDS: 'Bonds',
  COMPANIES: 'Companies',
  COMPANY_ROOT: 'Company',
  COMPANY_SNAPSHOT: 'Snapshot',
  COMPANY_PEER_COMPARISON: 'Peer Comparison',
  EARNINGS_ENGINE: 'Earnings Engine',
  ESG: 'ESG',
  ETF_ROOT: 'ETFs',
  ETF_CUBE: 'ETF Cube',
  ETF_SNAPSHOT: 'ETF Snapshot',
  ETF_ENGINE: 'ETF Engine',
  ETF_FACTS: 'ETF Facts',
  ETF_VIEWER: 'ETF Viewer',
  ETF_ISSUERS: 'ETF Issuers',
  EUMONICS_ROOT: 'Eumonics',
  EUMONICS_MARKET: 'Market',
  EUMONICS_OPPORTUNITY_FINDER: 'Opportunity Finder',
  EUMONICS_PPCC_DIFF: 'PPCC Difference',
  EUMONICS_PPCC_ANALYZER: 'PPCC Analyzer',
  EXCHANGES: 'Exchanges',
  FORECASTS: 'Forecasts',
  INDUSTRIES: 'Industries',
  MARKETS_ROOT: 'Markets',
  MARKETS_INDUSTRY_VIEW: 'Industry View',
  MARKETS_ISSUER_VIEW: 'Issuer View',
  MUTUAL_FUNDS_ROOT: 'Mutual Funds',
  MUTUAL_FUNDS_SNAPSHOT: 'MF Snapshot',
  MUTUAL_FUNDS_ENGINE: 'MF Engine',
  MUTUAL_FUNDS_FACTS: 'MF Facts',
  MUTUAL_FUNDS_VIEWER: 'MF Viewer',
  MUTUAL_FUNDS_ISSUERS: 'MF Issuers',
  ERROR_404: 'Error-404',
  ERROR_403: 'Error-403',
  PORTFOLIO_ROOT: 'Portfolio',
  PORTFOLIO_CUBE: 'Cube',
  PORTFOLIO_CHECK_UP: 'Check-up',
  PORTFOLIO_HIDDEN_HOLDINGS: 'Hidden Holdings',
  SMART_NEWS: 'Smart News',
  STOCKS: 'Stocks',
  WELCOME: 'Welcome',
  LOGIN: 'Login',
  SECTORS_TREE: 'Tree',
  PORTFOLIOS: 'Portfolios',
  USER_PORTFOLIO: 'User Portfolio',
  ETFS_PREVIEW: 'ETFs Preview',
  INDUSTRY_TRADING: 'Industry Trading',
  ADRS: 'ADRs',
  INDUSTRY_PERFORMANCE: 'Industry Performance',
  RATINGS: 'Ratings'
}
