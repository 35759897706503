import PbiReports from '@/constants/pbi-reports'
import { ROUTE_NAMES } from '@/constants/routes'
import { DEFAULT_SEARCH_CONTEXT } from '@/constants/search'

export default [
  {
    path: `/smart-news/:${ DEFAULT_SEARCH_CONTEXT.paramKey }`,
    name: ROUTE_NAMES.SMART_NEWS,
    component: () => import('@core/components/power-bi/PowerBiView.vue'),
    props: route => ({ report: PbiReports.SMART_NEWS.code, ...route.params }),
    requiredProps: [DEFAULT_SEARCH_CONTEXT.storeKey]
  }
]
