import { spec } from './graphql'
import { APOLLO_CLIENTS } from '@/libs/vue-apollo'
import { AINSTEIN } from '@/constants/apollo'

const type = action => ({
  load: 'query',
  reload: 'query',
  send: 'mutation',
  subscribe: 'query'
})[action]

const method = action => ({
  load: 'query',
  reload: 'query',
  send: 'mutate',
  subscribe: 'subscribe'
})[action]

const body = (action, specDef, options) => {
  const query = {
    ...options,
    [type(action)]: typeof specDef === 'string' ? spec(specDef) : specDef
  }

  query.fetchPolicy = 'no-cache'

  return query
}

/**
 * Performs GraphQL request query/mutation.
 *
 * @see load
 * @see send
 *
 * @param client
 * @param action
 * @param spec
 * @param options
 * @param variables
 * @return {Promise<unknown>}
 */
const makeRequest = (client = AINSTEIN, action, spec, variables, options) => {
  return APOLLO_CLIENTS[client][method(action)](body(action, spec, { variables, ...options }))
}

/**
 * Performs GraphQL mutation.
 *
 * @Example
 *   apollo.send('Users/Create', { name: 'John', email: 'doe@example.com', password: 'pass' })
 *
 * @param client
 * @param spec
 * @param options
 * @param variables
 * @return {Promise<unknown>}
 */
export const send = (client, spec, variables, options) => makeRequest(client, 'send', spec, variables, options)

/**
 * Performs GraphQL query using gql query-files.
 *
 * @Example
 *   apollo.load('Me')
 *
 * @param client
 * @param spec
 * @param options
 * @param variables
 * @return {Promise<unknown>}
 */
export const load = (client, spec, variables, options) => makeRequest(client, 'load', spec, variables, options)

/**
 * Performs GraphQL query.
 *
 * @Example
 *   apollo.load('Me')
 *
 * @param client
 * @param spec
 * @param options
 * @param variables
 * @return {Promise<unknown>}
 */
export const query = (client, spec, variables, options) => makeRequest(client, 'load', spec, variables, options)

/**
 * Performs GraphQL query ignoring any local cache.
 *
 * @Example
 *   apollo.load('Me')
 *
 * @param client
 * @param spec
 * @param options
 * @param variables
 * @return {Promise<unknown>}
 */
export const reload = (client, spec, variables, options) => makeRequest(client, 'reload', spec, variables, options)

export const getObserver = (client, spec, variables, options) => makeRequest(client, 'subscribe', spec, variables, options)
