import { industry, sector, subIndustry } from '@/utils/store'

/**
 * It can be a function that returns an object,
 * if you think that this module can be instantiated.
 */
export default {
  ...industry.STATE,
  ...subIndustry.STATE,
  ...sector.STATE,
  companiesData: {},
  portfolios: {},
  loading: false,
  securities: null
}
