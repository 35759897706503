import config from '../../config.yml'
import merge from 'lodash/merge'

try {
  const envConf = require(`../../config.${ process.env.NODE_ENV }.yml`).default

  if (envConf) {
    merge(config, envConf)
  }
}
catch (e) {
  // That's ok if an overridden file is not found.
}

export default config
