import config from '@/config'

// API
export const API = config.api.trimEnd('/')
export const AINSTEIN_API = config.ain_api.trimEnd('/')
export const CUBE_API = config.cube.url.trimEnd('/')

// Power BI
export const POWER_BI_COMPANY_URL = `${ API }/powerbi`
export const POWER_BI_PORTFOLIO_URL = `${ API }/powerbip`

// Authentication
export const SSO_URL = config.sso.sso_url.trimEnd('/')
export const SSO_CLIENT_ID = config.sso.clientId
export const REDIRECT_URI = config.sso.redirect_uri
export const AUTH_URL = config.sso.auth_url.trimEnd('/')

// Eumonics
export const EUMONICS_APP_URL = config.eumonics.url.trimEnd('/')
export const EUMONICS_URL_TA = `${ EUMONICS_APP_URL }/series-viewer`
export const EUMONICS_TOKEN = config.eumonics.token

// Ratings
export const RATINGS_URL = `${ API }/ratings/`

// Ribbon Screens
export const RIBBON_SCREENS_URL = `${ API }/ribbon-screens`

// Stocks
export const STOCKS_URL = `${ AINSTEIN_API }/stocks`

// Industry
export const INDUSTRY_URL = `${ AINSTEIN_API }/industries`

// SubIndustry
export const SUB_INDUSTRY_URL = `${ AINSTEIN_API }/subindustries`

// Sector
export const SECTOR_URL = `${ AINSTEIN_API }/sectors`

// Portfolio
export const PORTFOLIO_URL = `${ AINSTEIN_API }/portfolios`

// Portfolio Data
export const PORTFOLIO_DATA_URL = `${ API }/portfolio-data/:id`

export const AINSTEIN_GQL_HTTP = config.graphql.ainstein.trimEnd('/')
export const EUMONICS_GQL_HTTP = config.graphql.eumonics.trimEnd('/')
export const AINSTEIN_GQL_WS = config.graphql.ws_url.trimEnd('/')
export const ENABLED_TRACKERS = config.trackers
export const ADMIN_IDS = config.admins || []

