import useApollo from '@core/hooks/apollo'
const { ainstein } = useApollo()

const actions = {}


actions.getSectors = async ({ commit }) => {
  commit('setLoading', true)
  try {
    const { data: { sectors } } = await ainstein.load('Ainstein/Sectors/sectors')
    commit('setSectors', sectors)
    return sectors
  }
  finally {
    commit('setLoading', false)
  }
}
actions.getSectorIndustries = async ({ commit }, id) => {
  commit('setLoading', true)
  try {
    const { data: { sector: { industries } } } = await ainstein.send('Ainstein/Sectors/sector', { id: id })
    return industries
  }
  finally {
    commit('setLoading', false)
  }
}
actions.getIndustrySubIndustries = async ({ commit }, id) => {
  commit('setLoading', true)
  try {
    const { data: { industry: { subIndustries } } } = await ainstein.send('Ainstein/Sectors/industry', { id: id })
    return subIndustries
  }
  finally {
    commit('setLoading', false)
  }
}
actions.getSubIndustryCompanies = async ({ commit }, id) => {
  commit('setLoading', true)
  try {
    const { data: { subIndustry: { companies } } } = await ainstein.send('Ainstein/Sectors/subIndustry', { id: id })
    return companies
  }
  finally {
    commit('setLoading', false)
  }
}

export default actions
