import { stages } from '@/utils/store'
import { STATUS_EDITING } from '@/constants/portfolios'
import { USD } from '@/utils/currency'
import sumBy from 'lodash/sumBy'

const FIELDS = Object.freeze({
  SHARES: 'shares',
  GAIN: 'gain',
  LOSS: 'loss'
})

const sumByField = (array, fieldName) => {
  return sumBy(array, asset => asset[fieldName])
}
const getters = {
  ...stages.GETTERS
}

getters.isAssetsDirty = ({ assets }) => {
  return assets.some(({ status }) => status !== STATUS_EDITING.LOADED)
}
getters.isAssetsInvalid = ({ assets }) => {
  return assets.some(({ valid }) => !valid)
}
getters.invalidAssets = ({ assets }) => {
  return assets.filter(({ valid }) => !valid)
}
getters.totalTotal = ({ assets }) => {
  return assets.reduce((res, { total }) =>
    res.add(total), USD(0))
}
getters.totalShares = ({ assets }) => {
  return sumByField(assets, FIELDS.SHARES)
}
getters.totalGain = ({ assets }) => {
  return sumByField(assets, FIELDS.GAIN)
}
getters.totalLoss = ({ assets }) => {
  return sumByField(assets, FIELDS.LOSS)
}

export default getters
