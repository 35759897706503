import config from '@/config'

export const CUBE_URL = config.cube.url.trimEnd('/')

export const RIBBONED_CUBE_TYPES = Object.freeze({
  PORTFOLIO: 'PORTFOLIO',
  ETF: 'ETF'
})

export const CUBE_TYPES = Object.freeze({
  PORTFOLIO_SUB_INDUSTRY: 'subIndustry',
  PORTFOLIO_INDUSTRY: 'industry',
  PORTFOLIO_SECTOR: 'sector',
  ETF_FUND_FAMILY: 'fundFamily',
  ETF_FUND_FAMILY_AND_TYPE: 'fundFamilyAndType',
  ETF_FUND_FUND_TYPE: 'fundType',
  ETF_SECTOR_FOCUS: 'sectorFocus'
})

export const CUBE_TYPE_QUERY_MAP = Object.freeze({
  [CUBE_TYPES.PORTFOLIO_SUB_INDUSTRY]: 'Sub Industry',
  [CUBE_TYPES.PORTFOLIO_INDUSTRY]: 'Industry',
  [CUBE_TYPES.PORTFOLIO_SECTOR]: 'Sector',
  [CUBE_TYPES.ETF_FUND_FAMILY]: 'FundFamily',
  [CUBE_TYPES.ETF_FUND_FAMILY_AND_TYPE]: 'FundFamilyAndType',
  [CUBE_TYPES.ETF_FUND_FUND_TYPE]: 'FundType',
  [CUBE_TYPES.ETF_SECTOR_FOCUS]: 'SectorFocus',
  PORTFOLIO_DEFAULT: 'Portfolio'
})

export const buildCubeURL = (ribbonedCubeType = {}, cubeID, type) => {
  let URL_PART = '/Portfolio/Test'
  const query = {}
  switch (ribbonedCubeType.value) {
    case RIBBONED_CUBE_TYPES.ETF:
      URL_PART = !type ? '/Portfolio/Testv1' : '/ETF/Test'
      if (type) {
        query.cubeType = CUBE_TYPE_QUERY_MAP[type]
      }
      break
    default: // RIBBONED_CUBE_TYPES.PORTFOLIO
      query.cubeType = CUBE_TYPE_QUERY_MAP[type] || CUBE_TYPE_QUERY_MAP.PORTFOLIO_DEFAULT
  }
  const url = new URL(`${ CUBE_URL }/${ URL_PART }/${ cubeID }`)
  Object.keys(query).forEach(key => url.searchParams.set(key, query[key]))
  return url
}
