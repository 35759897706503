import Vue from 'vue'
import { ENTITY_TYPES } from './state'
import { camelCase } from 'lodash'
import securityIndicators from '@/constants/securityIndicators'

const mutations = {}
const toCamelCase = array => {
  if (!Array.isArray(array)) {
    console.warn(`Provided value is of type "${ typeof array }". It must be array. (utils.toCamelCase).`)
    return array
  }
  return array.reduce((acc, entity) => {
    acc[camelCase(entity.type)] = entity.value
    return acc
  }, {})
}

mutations.setTotal = (state, total) => {
  state.total = total
}

/**
 * Sets state of a loader.
 */
mutations.startLoader = (state, type) => {
  // We should validate `ENTITIES[type]` rather than `state[type]` as in `state[type]` can be
  // other properties which can be valid for `state[type]`.
  if (!ENTITY_TYPES[type]) {
    throw new Error(`Type of loader "${ type }" does not exist (classification.mutation.startLoader).`)
  }
  state[type].loading = true
}

mutations.finishLoader = (state, type) => {
  // We should validate `ENTITIES[type]` rather than `state[type]` as in `state[type]` can be
  // other properties which can be valid for `state[type]`.
  if (!ENTITY_TYPES[type]) {
    throw new Error(`Type of loader "${ type }" does not exist (classification.mutation.finishLoader).`)
  }
  Vue.set(state[type], 'loading', false)
  Vue.set(state[type], 'loaded', true)
}

mutations.objectsToEntities = (state, { type, items }) => {
  const entities = state[type].entities
  let properties = null
  const reservedProperties = new Set()

  for (const parent of ENTITY_TYPES[type].parents) {
    reservedProperties.add(parent.prop)
  }
  for (const child of ENTITY_TYPES[type].children) {
    reservedProperties.add(child.prop)
  }

  for (const item of items) {

    // Guard. In case if the entity has came as null.
    //
    // Server may respond with null when the item has problems with its properties.
    // Eg: property `indicators` of item `security` has some indicators stored in ADX (warm data).
    // If a record in ADX is not found for a specific security, then service will consider it
    // as an error. In that case it will return `null` instead of the entire `security` object and
    // add an error to list of errors.
    // This is incorrect behavior which will be fixed in the future. There might be other errors
    // providing the same effect though.
    // So we would still need this guard presented.
    if (!item) {
      continue
    }

    const placeholder = {}

    const id = item[ENTITY_TYPES[type].idKey]

    // If the entity was not defined in the state yet, then we simply add it and make reactive.
    if (!entities.has(id)) {
      entities.set(id, placeholder)
    }

    // Assume all the objects in the given list have the same properties.
    // Keys determining once during the mutation to save performance.
    if (!properties) {
      properties = Object.keys(item)
    }

    const entity = entities.get(id)
    // It's important to keep source object to have the reactivity work properly.
    // It must be exactly the same as source. Means that Object.is(prev, new) must be always true.
    // It means that it shouldn't be replaced.
    // We should add/update each property separately instead.
    if (properties.length) {
      for(const property of properties) {
        if (property === 'indicators' && Array.isArray(item[property])) {
          Vue.set(entity, property, toCamelCase(item[property]))
        }
        else if (property === 'ratings' && Array.isArray(item[property])) {
          Vue.set(entity, property, toCamelCase(item[property]))
        }
        else if (!reservedProperties.has(property) || property === 'securities') {
          Vue.set(entity, property, item[property])
        }
      }
      if (type === 'companies'
        && entity.securities?.length
        && entity.securities[0]?.indicators) {
        entity.securities[0].indicators.pe = securityIndicators[entity.securities[0].ticker]?.pe
      }
    }

    for (const parent of ENTITY_TYPES[type].parents) {
      if (!entity[parent.prop]) {
        entity[parent.prop] = null
      }
    }

    for (const child of ENTITY_TYPES[type].children) {
      if (!entity[child.prop]) {
        entity[child.prop] = {}
      }
    }
  }

  state[type].list = Array.from(state[type].entities.values())
}


/**
 * Maps lists of items to each other as if you would request it in hierarchical way from API.
 * All the iterations over the entire database to map everything to everything takes ~70ms!
 *
 * Important! Objects will be passed as a link rather that copying object.
 * It will make the structure consistent, so eventually you will be able to get access to all
 * nested items. It may introduce potential problems with infinite nesting, though.
 */

mutations.mapIndustries = state => {
  const industries = state.industries.entities
  const sectors = state.sectors.entities
  for (const [,industry] of industries) {
    const sector = sectors.get(industry.sectorId)
    if (!sector) {
      continue
    }
    industry.sector = sector
    sector.industries[industry.id] = industry
  }
}

mutations.mapSubIndustries = state => {
  const subIndustries = state.subIndustries.entities
  const industries = state.industries.entities
  for (const [,subIndustry] of subIndustries) {
    const industry = industries.get(subIndustry.industryId)
    if (!industry) {
      continue
    }
    subIndustry.industry = industry
    industry.subIndustries[subIndustry.id] = subIndustry
  }
}

mutations.mapCompanies = state => {
  const companies = state.companies.entities
  const subIndustries = state.subIndustries.entities
  const industries = state.industries.entities
  const sectors = state.sectors.entities
  for (const [,company] of companies) {
    const subIndustry = subIndustries.get(company.subIndustryId)
    if (!subIndustry) {
      continue
    }
    company.subIndustry = subIndustry
    subIndustry.companies[company.id] = company

    const industry = industries.get(subIndustry.industryId)
    if (!industry) {
      continue
    }
    company.industry = industry
    industry.companies[company.id] = company

    const sector = sectors.get(industry.sectorId)
    if (!sector) {
      continue
    }
    company.sector = sector
    sector.companies[company.id] = company
  }
}

mutations.mapSecurities = (state, items) => {
  const securities = state.securities.entities
  const companies = state.companies.entities
  const exchanges = state.exchanges.entities
  for (const item of items) {
    const security = securities.get(item.ticker)
    if (!security) {
      continue
    }
    const company = companies.get(security.companyId)
    if (!company) {
      continue
    }
    security.company = company
    company.securities[security.id] = security

    const exchange = exchanges.get(security.exchangeId)
    if (!exchange) {
      continue
    }
    security.exchange = exchange
    exchange.securities[security.id] = security
  }
}

mutations.runCollectionChange = () => {
  // state[type].changed++
}

export default mutations
