import axios from '@axios'
import { PORTFOLIO_URL } from '@/constants/app'

export const STATE = {
  portfolios: [],
  portfolioMap: {},
  portfoliosLoading: false,
  portfolioLoading: false
}

export const ACTIONS = {
  async getPortfolios({ commit }) {
    commit('setPortfoliosLoading', true)
    try {
      const { data } = await axios.get(PORTFOLIO_URL)
      commit('setPortfolios', data)
      return data
    }
    finally {
      commit('setPortfoliosLoading', false)
    }
  },
  async getPortfolio({ commit }, id) {
    commit('setPortfolioLoading', true)
    try {
      const { data } = await axios.get(`${ PORTFOLIO_URL }/${ id }`)
      commit('setPortfolio', { id, data })
      return data
    }
    finally {
      commit('setPortfolioLoading', false)
    }
  }
}

export const MUTATIONS = {
  setPortfolios(state, portfolios) {
    state.portfolios = portfolios
  },
  setPortfolio(state, { id, data }) {
    state.portfolioMap[id] = data
  },
  setPortfoliosLoading(state, bool) {
    state.portfoliosLoading = bool
  },
  setPortfolioLoading(state, bool) {
    state.portfolioLoading = bool
  }
}
