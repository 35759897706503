export const RIBBON_OPTIONS = [
  {
    title: 'Value',
    code: 'value'
  },
  {
    title: 'Performance',
    code: 'performance'
  },
  {
    title: 'Trading Sentiment',
    code: 'timings'
  },
  {
    title: 'Options',
    code: 'options'
  },
  {
    title: 'ESG',
    code: 'esg'
  },
  {
    title: 'Earnings',
    code: 'earnings'
  },
  {
    title: 'Revenue',
    code: 'revenue'
  },
  {
    title: 'Cash Flow',
    code: 'cashFlow'
  },
  {
    title: 'Margins',
    code: 'margin'
  },
  {
    title: 'Growth',
    code: 'growth'
  },
  {
    title: 'Bonds',
    code: 'bonds'
  },
  {
    title: 'Short Interest',
    code: 'short_interest'
  },
  {
    title: 'News Sentiment',
    code: 'news_sentiment'
  },
  {
    title: 'Forecasts',
    code: 'forecasts'
  },
  {
    title: 'Ratio Analysis',
    code: 'ration_analysis'
  },
  {
    title: 'Risk',
    code: 'risk'
  },
  {
    title: 'Safety',
    code: 'safety'
  }
]
