import { CONTEXTS } from '@/constants/search'

const mutations = {
  ...Object.keys(CONTEXTS).reduce((state, key) => {
    const { storeKey } = CONTEXTS[key]
    state[CONTEXTS[key].mutationName] = (state, val) => {
      state[storeKey] = val
    }
    return state
  }, {})
}

mutations.setRemoteLoading = (state, value) => {
  state.remoteLoading = value
}
mutations.setContext = (state, context) => {
  state.context = context
}
mutations.updateStaticSearchDataSource = ({ searchDataSource, context: { key } }, data) => {
  searchDataSource[key].setData(data)
}
mutations.updateRemoteSearchDataSource = ({ searchDataSource, context: { key } }, data) => {
  searchDataSource[key].setRemoteSearchData(data)
}

export default mutations
