import { gql } from '@apollo/client/core'

export const SECTORS = gql`
  {
    sectors {
      id
      name
      indicators {
        type
        value
      }
      ratings {
        type
        value
      }
    }
  }
`

export const INDUSTRIES = gql`
  {
    industries {
      id
      sectorId
      name
      indicators {
        type
        value
      }
      ratings {
        type
        value
      }
    }
  }
`

export const SUB_INDUSTRIES = gql`
  {
    subIndustries {
      id
      name
      industryId
      indicators {
        type
        value
      }
      ratings {
        type
        value
      }
    }
  }
`

export const COMPANIES = gql`
  query listCompanies($limit: Int, $offset: Int) {
    listCompanies(limit: $limit, offset: $offset) {
      items {
        id
        name
        sectorId
        industryId
        subIndustryId

        indicators {
          type
          value
        }
        ratings {
          type
          value
        }
        securities {
          id
          ISIN
          ticker
          companyId
          exchangeId
          currency

          indicators {
            warmClose
            warmHigh
            warmLow
            warmOpen
            warmVolume

            warmPriceChange
            warmPriceChangePercentage

            warmTradedVolume
            warmLastTradeTimestamp
          }
        }
      }
    }
  }
`

export const COMPANIES_TOTAL = gql`
  {
    listCompanies {
      total
    }
  }
`

export const EXCHANGES = gql`
  {
    exchanges {
      code
      id
      name
    }
  }
`

export const SECURITIES_TOTAL = gql`
  {
    listSecurities {
      total
    }
  }
`

export const SECURITIES = gql`
  query listSecurities($limit: Int, $offset: Int) {
    listSecurities(limit: $limit, offset: $offset) {
      items {
        id
        ISIN
        ticker
        companyId
        exchangeId
        indicators {
          warmClose
          warmHigh
          warmLow
          warmOpen
          warmVolume

          warmPriceChange
          warmPriceChangePercentage

          warmTradedVolume
          warmLastTradeTimestamp
        }
      }
    }
  }
`

export const QUOTE_SNAPSHOT = gql`
  subscription quoteSnapshot($tickers: [String!]!) {
    quoteSnapshot(tickers: $tickers) {
      ticker
      price
      volume
      change
      percentageChange
      open
      high
      low
      timestamp
    }
  }
`

export const ETFS = gql`
  query listEtfs($limit: Int, $offset: Int) {
    listEtfs(limit: $limit, offset: $offset) {
      items {
        id
        name
        ticker
        ratings {
          type
          value
        }
        indicators {
          type
          value
        }
      }
    }
  }
`

export const ETFS_TOTAL = gql`
  {
    listEtfs {
      total
    }
  }
`
