import PbiReports from '@/constants/pbi-reports'
import { ROUTE_NAMES } from '@/constants/routes'
import { DEFAULT_SEARCH_CONTEXT } from '@/constants/search'

export default [
  {
    path: `/portfolios`,
    name: ROUTE_NAMES.PORTFOLIO_ROOT,
    component: () => import('@/views/portfolio/Portfolio.vue'),
    children: [
      {
        path: `/portfolios/:${ DEFAULT_SEARCH_CONTEXT.paramKey }/cube`,
        name: ROUTE_NAMES.PORTFOLIO_CUBE,
        component: () => import('@/views/portfolio/PortfolioCube.vue'),
        meta: {
          contentRenderer: 'sidebar-left-detached'
        },
        requiredProps: [DEFAULT_SEARCH_CONTEXT.storeKey]
      },
      {
        path: `/portfolios/:${ DEFAULT_SEARCH_CONTEXT.paramKey }/check-up`,
        name: ROUTE_NAMES.PORTFOLIO_CHECK_UP,
        component: () => import('@core/components/power-bi/PowerBiView.vue'),
        props: route => ({ report: PbiReports.PORTFOLIOS_CHECK_UP.code, ...route.params }),
        requiredProps: [DEFAULT_SEARCH_CONTEXT.storeKey]
      },
      {
        path: `/portfolios/:${ DEFAULT_SEARCH_CONTEXT.paramKey }/hidden-holdings`,
        name: ROUTE_NAMES.PORTFOLIO_HIDDEN_HOLDINGS,
        component: () => import('@core/components/power-bi/PowerBiView.vue'),
        props: route => ({ report: PbiReports.PORTFOLIOS_HIDDEN_HOLDINGS.code, ...route.params }),
        requiredProps: [DEFAULT_SEARCH_CONTEXT.storeKey]
      }
    ]
  }
]
