import useApollo from '@core/hooks/apollo'
import gql from 'graphql-tag'
import { CREATING, DESTROYING, LOADING } from '@/constants/stages'
import { STATUS_EDITING } from '@/constants/portfolios'
import cloneDeep from 'lodash/cloneDeep'

const { ainstein: apollo } = useApollo()

const actions = {}

actions.loadSecurities = async ({ commit }, assets) => {
  if (assets?.length) {
    const { ainstein } = useApollo()
    let iter = 0
    const { data } = await ainstein.load(gql`query fetchSecurities {
      ${assets.map(asset => `security_${ iter++}: security(id: "${asset.id}") { id ticker company { name ratings { value } } }`).join(`\n`)}
    }`)
    commit('updateSecurities', data)
  }
}
actions.loadPortfolio = async ({ state, commit, dispatch }, userId) => {
  commit('setStage', LOADING)
  try {
    return await dispatch('loadUserPortfolio', userId).then(async portfolios => {
      const portfolio = portfolios?.length ? portfolios[0] : null
      if (portfolio?.id) {
        commit('setUserPortfolio', portfolio)
        await dispatch('loadAssets', { id: portfolio.id })
        return state.assets
      }
    })
  }
  finally {
    commit('resetStage')
  }
}
actions.convertPortfolioFile = async (_, fileText) => {
  const { data: { assets } } = await apollo.send('Ainstein/Portfolios/Convert', { file: fileText })
  return assets
}
actions.convertPortfolioAssets = async ({ commit, dispatch }, fileText) => {
  const { data: { assets } } = await apollo.send('Ainstein/Portfolios/Convert', { file: fileText })
  const recognizedSecurityAssets = assets?.recognized.filter(asset => asset.type === 'Security')
  const unrecognizedAssets = assets?.unrecognized

  await dispatch('loadSecurities', recognizedSecurityAssets)
  await recognizedSecurityAssets.forEach(asset => commit('addAsset', asset))
  await unrecognizedAssets.forEach(asset => commit('addAsset', asset))
}
actions.loadAssets = async ({ commit, dispatch }, id) => {
  const portfolio = await dispatch('getPortfolio', id)
  await dispatch('loadSecurities', portfolio.assets)
  portfolio.assets?.length && portfolio.assets.forEach(asset => {
    commit('addAsset', Object.assign(asset, { status: STATUS_EDITING.LOADED }))
  })
}
actions.getSecurity = async (state, id) => {
  const { data: { security } } = await apollo.reload('Ainstein/Portfolios/Securities/Get', id)
  return security
}
actions.getPortfolio = async (state, id) => {
  const { data: { portfolio } } = await apollo.reload('Ainstein/Portfolios/Get', id)
  return portfolio
}
actions.loadUserPortfolio = async (state, userId) => {
  const { data: { portfolios } } = await apollo.reload('Ainstein/Portfolios/UserPortfolios', userId)
  return portfolios
}
actions.reloadPortfolios = async ({ commit }) => {
  const { data: { portfolios } } = await apollo.reload('Ainstein/Portfolios/List')
  return commit('setPortfolios', portfolios)
}
actions.createPortfolio = async ({ state, dispatch, commit }, portfolio) => {
  commit('setStage', CREATING)
  try {
    const data = await apollo.send('Ainstein/Portfolios/Create', { data: portfolio })
    const portfolioId = data.data?.item?.id
    if (portfolioId) {
      commit('setUserPortfolio', { id: portfolioId })
      dispatch('createPortfolioAssets', {
        portfolioId,
        assets: state.assets.filter(_ => _.valid)
      })
    }
  }
  finally {
    commit('resetStage')
  }
}
actions.updatePortfolio = async ({ state, dispatch }) => {
  const portfolioId = state.userPortfolio?.id

  if (portfolioId) {
    const validAssets = state.assets.filter(asset => asset.valid)
    const assetsToAdd = validAssets.filter(asset => asset.status === STATUS_EDITING.ADDED)
    const assetsToUpdate = validAssets.filter(asset => asset.status === STATUS_EDITING.UPDATED)
    const assetsToRemove = validAssets.filter(asset => asset.status === STATUS_EDITING.REMOVED)

    const loaders = []

    if (assetsToAdd.length) {
      loaders.push(dispatch('createPortfolioAssets', { portfolioId, assets: assetsToAdd }))
    }

    if (assetsToRemove.length) {
      loaders.push(dispatch('removePortfolioAssets', { portfolioId, assets: assetsToRemove }))
    }

    if (assetsToUpdate.length) {
      const assets = cloneDeep(assetsToUpdate).map(asset => Object.assign(asset, { ticker: { security_id: asset.security_id } }))
      loaders.push(
        dispatch('removePortfolioAssets', { portfolioId, assets }).then(() => {
          dispatch('createPortfolioAssets', { portfolioId, assets: assetsToUpdate })
        })
      )
    }

    await Promise.all(loaders)
  }
}
actions.removePortfolio = async ({ commit }, id) => {
  commit('setStage', DESTROYING)
  try {
    await apollo.send('Ainstein/Portfolios/Remove', id)
  }
  finally {
    commit('resetStage')
  }
}
actions.createPortfolioAssets = async (state, { portfolioId, assets }) => {
  let iterator = 0
  await apollo.send(gql`mutation addAssets {
    ${assets.map(asset => `asset${ iterator++}: addAssetToPortfolio(data: {
          id: "${asset.ticker.security_id}"
          portfolioId: "${portfolioId}"
          quantity: ${Number(asset.shares)}
          price: ${Number(asset.price)}
          type: Security
          }) { id }`).join(`\n`)}
  }`)
}
actions.removePortfolioAssets = async (state, { portfolioId, assets }) => {
  let iterator = 0
  await apollo.send(gql`mutation removeAssets {
    ${assets.map(asset => `asset${ iterator++}: removeAssetFromPortfolio(
          assetId: "${asset.ticker.security_id}"
          portfolioId: "${portfolioId}"
          ) { id }`).join(`\n`)}
  }`)
}

export default actions
