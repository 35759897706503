import { stages } from '@/utils/store'

export const defaultState = () => ({
  ...stages.STATE(),
  reportLinks: []
})

/**
 * It can be a function that returns an object,
 * if you think that this module can be instantiated.
 */
export default () => ({
  ...stages.STATE(),
  reportLinks: []
})
