import {
  COMPANIES,
  CONTEXT_KEY_COMPANIES,
  CONTEXT_KEY_ETF,
  CONTEXT_KEY_PORTFOLIOS, PORTFOLIOS
} from '@/constants/search'
import useApollo from '@core/hooks/apollo'

const actions = {}

actions.setContext = ({ commit, dispatch }, context) => {
  commit('setContext', context)
  commit('updateRemoteSearchDataSource', [])
  return dispatch('loadStaticVariants')
}
actions.remoteSearch = async ({ state: { context: { key } }, commit }, searchText) => {
  let queryPath = 'Ainstein/Search/'
  switch (key) {
    case CONTEXT_KEY_ETF:
      queryPath += 'searchETF'
      break
    case CONTEXT_KEY_COMPANIES:
      queryPath += 'searchCompanies'
      break
  }
  try {
    commit('setRemoteLoading', true)
    const { ainstein } = useApollo()
    await new Promise(resolve => setTimeout(resolve, 1000))
    const { data: { items } } = await ainstein.load(queryPath, { searchText })
    commit('updateRemoteSearchDataSource', items)
  }
  finally {
    commit('setRemoteLoading', false)
  }
}
actions.loadStaticVariants = async ({ commit, state }) => {
  const { searchDataSource, context } = state
  const { key, storeKey } = context
  if (!searchDataSource[key].isEmpty) {
    return
  }
  let queryPath = 'Ainstein/Search/'
  switch (key) {
    case CONTEXT_KEY_COMPANIES:
      // queryPath += 'loadCompany'
      // break
      return commit('updateStaticSearchDataSource', COMPANIES)

    case CONTEXT_KEY_PORTFOLIOS:
      return commit('updateStaticSearchDataSource', PORTFOLIOS)

    case CONTEXT_KEY_ETF:
      queryPath += 'loadETFBySearch'
      break
  }
  // get extended info by context
  try {
    const { ainstein } = useApollo()
    const { data } = await ainstein.load(queryPath, { id: state[storeKey].toUpperCase() })
    const { items } = data
    const [item] = items
    commit('updateStaticSearchDataSource', [item])
  }
  catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

export default actions
