import axios from '@/libs/axios'
import { CUBE_API, PORTFOLIO_DATA_URL, RATINGS_URL, STOCKS_URL } from '@/constants/app'
import { PORTFOLIO_TYPES } from '@/constants/portfolios'
import { CUBE_URL } from '@/constants/cubes'

const actions = {}

actions.loadTickers = async (_, { id, cubeType }) => {
  const url = `${ CUBE_API }/API/v1/ETFFamilyCube/${ id }?cubeType=${ cubeType }`
  const { data: { data } } = await axios.get(url)
  data.sort((a, b) => Number(a.AUM) < Number(b.AUM) ? 1 : -1)
  return data
}
actions.loadCompanyData = async ({ state, commit }, ticker) => {
  const code = ticker.toLowerCase()
  if (code && !state.companiesData[code]) {
    try {
      commit('setCompanies', { data: { loading: true }, code })
      const { data } = await axios.get(`${ RATINGS_URL }/${ code }`)
      commit('setCompanies', { data, code })
    }
    catch (e) {
      commit('setCompanies', { data: {}, code })
    }
  }
}
actions.loadPortfolioData = async ({ state, commit }, id) => {
  if (id && !state.portfolios[id]) {
    commit('setLoading', true)
    try {
      const { data } = await axios.get(`${ PORTFOLIO_DATA_URL.replace(':id', id) }?portfolioType=${ PORTFOLIO_TYPES.ETF }`)
      commit('setPortfolio', { data, id })
    }
    catch (e) {
      commit('setPortfolio', { data: {}, id })
    }
    finally {
      commit('setLoading', false)
    }
  }
}
actions.loadSeries = (_, { ticker, series }) => {
  return new Promise(resolve => {
    axios.get(`${ STOCKS_URL }/${ ticker }/data/${ series }`)
      .then(({ data }) => {
        resolve({ ticker, series, data })
      })
      .catch(function (error) {
        resolve(error)
      })
  })
}
actions.getETFInfo = ({ commit }, ticker) => {
  return new Promise(resolve => {
    axios.get(`${ CUBE_URL }/api/v1/ETFInfo/${ ticker }`).then(({ data: value }) => {
      commit('setETFInfo', { ticker, value })
    }).catch(resolve)
  })
}

export default actions
